import { useId, useState } from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Anchor } from "../../common/components/atoms/anchor/Anchor";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import {
	useDeletePumpMutation,
	useGetAllCompaniesQuery,
	useGetCompanyQuery,
	useMovePumpMutation,
} from "../../common/redux/api/exopenApi";
import { invariant } from "../../common/utils/invariant";
import { Button } from "../../common/components/atoms/button/Button";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";
import { IconTrash } from "@tabler/icons-react";
import type { PumpConfig } from "../../common/service/pumps";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { CreatePumpModal } from "./components/CreatePumpModal.js";
import { Text } from "../../common/components/atoms/typography/Text.js";
import { Label } from "../../common/components/atoms/label/Label.js";
import { Select } from "../../common/components/atoms/select/Select.js";
import { DialogActions } from "../../common/components/atoms/dialog/DialogActions.js";
import { Dialog } from "../../common/components/atoms/dialog/Dialog.js";
import { DialogBody } from "../../common/components/atoms/dialog/DialogBody.js";
import { DialogContent } from "../../common/components/atoms/dialog/DialogContent.js";
import { DialogTitle } from "../../common/components/atoms/dialog/DialogTitle.js";
import { ValidateHint } from "../planning/components/ValidateHint.js";
import { ConfirmDialog } from "../../common/components/atoms/dialog/ConfirmDialog.js";

interface Inputs {
	company: string | null;
}

export const Pumps = () => {
	const { companyId } = useParams();
	invariant(companyId);
	const [movePump] = useMovePumpMutation();
	const { data: company, isLoading: isLoadingCompany } = useGetCompanyQuery({
		companyId,
	});
	const { data: companies, isLoading: isLoadingCompanies } =
		useGetAllCompaniesQuery();
	const [pump, setPump] = useState<number | null>(null);
	const { t } = useTranslation();

	const { formState, handleSubmit, control, reset } = useForm<Inputs>({
		defaultValues: { company: null },
	});

	const pumpById: Record<string, PumpConfig> = {};
	for (const pump of company?.pumps ?? []) {
		pumpById[`${pump.id}`] = pump;
	}

	const [pumpToDelete, setPumpToDelete] = useState<string | null>(null);
	const [deletePump, { isLoading: isDeleting }] = useDeletePumpMutation();

	const [isCreating, setIsCreating] = useState(false);
	const companyDomainLabelId = useId();

	if (isLoadingCompany || isLoadingCompanies) {
		return <LoadingState />;
	}

	if (!company || !companies) {
		return <MissingDataState />;
	}

	const closeModal = () => {
		setPump(null);
		reset({ company: null });
	};

	const submitHandler: SubmitHandler<Inputs> = async (data) => {
		const result = await movePump({
			pumpId: `${pump}`,
			tenantId: data.company!,
		});

		if ("error" in result) {
			showErrorNotification({ message: t("Something failed...") });
		} else {
			setPump(null);
		}
	};

	return (
		<>
			<ConfirmDialog
				destructive
				open={pumpToDelete !== null}
				onClose={() => {
					setPumpToDelete(null);
				}}
				onConfirm={async () => {
					const result = await deletePump({ companyId, pumpId: pumpToDelete! });
					if ("error" in result) {
						showErrorNotification({ message: t("Failed to delete pump") });
					} else {
						setPumpToDelete(null);
						showSuccessNotification({ message: t("Pump was deleted") });
					}
				}}
				isLoading={isDeleting}
				title={t('Delete pump "{{pump}}"', {
					pump: pumpById[pumpToDelete!]?.name,
				})}
				confirmButtonLabel={t("Remove")}
				description={t(
					`Are you sure you want to delete this pump? This action can not be undone.`,
				)}
			/>
			<Dialog open={pump !== null} onClose={closeModal}>
				<DialogContent>
					<DialogTitle>Flytta pump</DialogTitle>
					<DialogBody>
						<form id="move-pump-form" onSubmit={handleSubmit(submitHandler)}>
							<Label id={companyDomainLabelId}>{t("Company domain")}</Label>
							<Controller
								control={control}
								name="company"
								rules={{
									required: t("This field is required"),
								}}
								render={({ field, fieldState }) => (
									<>
										<Select
											placeholder="Välj företagsdomän"
											withFilter
											aria-labelledby={companyDomainLabelId}
											{...field}
											options={companies.map((company) => {
												return { value: `${company.id}`, label: company.name };
											})}
											aria-invalid={fieldState.error !== undefined}
										/>
										{fieldState.error && (
											<ValidateHint error>
												{fieldState.error.message}
											</ValidateHint>
										)}
									</>
								)}
							/>
						</form>
					</DialogBody>
					<DialogActions withCancelButton>
						<Button
							type="submit"
							form="move-pump-form"
							isLoading={formState.isSubmitting}
							disabled={!formState.isDirty}
						>
							Flytta pump
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<CreatePumpModal
				isOpen={isCreating}
				onClose={() => setIsCreating(false)}
			/>
			<PlanningTableContainer>
				<PlanningTable>
					<thead>
						<tr>
							<th className="text-right">#</th>
							<th>Namn</th>
							<th>Typ</th>
							<th className="w-0" />
						</tr>
					</thead>
					<tbody>
						{company.pumps.map((pump) => {
							return (
								<tr key={pump.id}>
									<td className="w-0 text-right">
										<Text size="sm">{pump.id}</Text>
									</td>
									<td>
										<Anchor
											onClick={() => {
												setPump(pump.id);
											}}
										>
											{pump.name}
										</Anchor>
									</td>
									<td>{pump.pumpType}</td>
									<td>
										<Button
											variant="ghost"
											onClick={() => {
												setPumpToDelete(`${pump.id}`);
											}}
											icon={<IconTrash />}
											ariaLabel="Delete pump"
										/>
									</td>
								</tr>
							);
						})}
						<tr>
							<td colSpan={3} className="text-center">
								<Button variant="primary" onClick={() => setIsCreating(true)}>
									{t("Create Pump")}
								</Button>
							</td>
						</tr>
					</tbody>
				</PlanningTable>
			</PlanningTableContainer>
		</>
	);
};
