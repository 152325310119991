import type { YearMonth, YearMonthPickerValue } from "./YearMonthPicker";
import { maxYearMonth, minYearMonth } from "./yearMonthUtils";

export const isMonthSelected = ({
	month,
	year,
	value,
	startMonth,
}: {
	month: number;
	year: number;
	value: YearMonthPickerValue;
	startMonth?: YearMonth | undefined;
}) => {
	if (startMonth) {
		if (startMonth.year === year) {
			return month === startMonth.month;
		} else {
			return false;
		}
	} else if (value) {
		if ("start" in value) {
			const isStartSelected =
				value.start.year === year ? value.start.month === month : false;
			const isEndSelected =
				value.end.year === year ? value.end.month === month : false;

			return isStartSelected || isEndSelected;
		} else {
			if (value.year === year) {
				return month === value.month;
			} else {
				return false;
			}
		}
	}
};

export const getMinMaxRange = ({
	usesRange,
	hoveredItem,
	value,
	startMonth,
}: {
	usesRange?: boolean | undefined;
	hoveredItem: YearMonth | undefined;
	value: YearMonthPickerValue;
	startMonth?: YearMonth | undefined;
}) => {
	let minRange: YearMonth | undefined;
	let maxRange: YearMonth | undefined;

	if (usesRange) {
		if (startMonth) {
			if (hoveredItem !== undefined) {
				minRange = minYearMonth(startMonth, hoveredItem);
				maxRange = maxYearMonth(startMonth, hoveredItem);
			}
		} else if (value && "start" in value) {
			minRange = value.start;
			maxRange = value.end;
		}
	}

	return {
		minRange,
		maxRange,
	};
};

export const isDateOutsideValidRange = ({
	min,
	max,
	year,
	month,
}: {
	min?: YearMonth | undefined;
	max?: YearMonth | undefined;
	year: number;
	month: number;
}): boolean => {
	if (min) {
		if (min.year === year) {
			if (min.month > month) {
				return true;
			}
		}
	}

	if (max) {
		if (max.year === year) {
			if (max.month < month) {
				return true;
			}
		}
	}
	return false;
};
