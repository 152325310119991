import {
	buildPlanRouteUrl,
	type DomainConversation,
} from "@exopengithub/planning-api-shared";
import { emptyPlanningApi } from "./emptyPlanningApi.js";

export const conversationApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		getConversations: builder.query<
			DomainConversation[],
			{ companyDomainId: string; legalEntityId: string; planId: string }
		>({
			query: ({ planId, companyDomainId, legalEntityId }) => {
				return {
					url: buildPlanRouteUrl("/conversations", {
						planId,
						legalEntityId,
						companyDomainId,
					}),
				};
			},
			providesTags(result) {
				return result
					? [
							"Conversation",
							...result.map((d) => {
								return { type: "Conversation" as const, id: d.id };
							}),
						]
					: ["Conversation"];
			},
		}),

		getConversation: builder.query<
			DomainConversation,
			{
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
				conversationId: number;
			}
		>({
			query({ planId, legalEntityId, companyDomainId, conversationId }) {
				return {
					url: buildPlanRouteUrl(`/conversations/${conversationId}`, {
						planId,
						legalEntityId,
						companyDomainId,
					}),
				};
			},
			providesTags(result, error, arg) {
				return [
					"Conversation",
					{ type: "Conversation", id: arg.conversationId },
					...(result?.conversationMessages.map((message) => {
						return { type: "ConversationMessage" as const, id: message.id };
					}) ?? []),
				];
			},
		}),

		deleteComment: builder.mutation<
			undefined,
			{
				companyDomainId: string;
				planId: string;
				legalEntityId: string;
				conversationId: number;
				commentId: number;
			}
		>({
			query({
				companyDomainId,
				legalEntityId,
				commentId,
				planId,
				conversationId,
			}) {
				return {
					method: "DELETE",
					url: buildPlanRouteUrl(
						`/conversations/${conversationId}/message/${commentId}`,
						{
							planId,
							legalEntityId,
							companyDomainId,
						},
					),
					responseHandler: "text",
				};
			},
			invalidatesTags(result, error, { commentId }) {
				return [{ type: "ConversationMessage", id: commentId }];
			},
		}),

		postComment: builder.mutation<
			{ conversationId: number },
			{
				companyDomainId: string;
				planId: string;
				legalEntityId: string;
				conversationId: number;
				comment: string;
				email: string;
			}
		>({
			query({
				companyDomainId,
				legalEntityId,
				planId,
				comment,
				conversationId,
			}) {
				return {
					method: "POST",
					url: buildPlanRouteUrl(`/conversations/${conversationId}/message`, {
						planId,
						legalEntityId,
						companyDomainId,
					}),
					body: {
						message: comment,
						replyId: null,
					},
				};
			},
			async onQueryStarted(
				{
					comment,
					legalEntityId,
					planId,
					companyDomainId,
					conversationId,
					email,
				},
				{ dispatch, queryFulfilled },
			) {
				const patchResult = dispatch(
					conversationApi.util.updateQueryData(
						"getConversation",
						{ conversationId, companyDomainId, planId, legalEntityId },
						(draft) => {
							draft.conversationMessages.push({
								companyDomainId,
								planId,
								conversationId,
								createdAt: new Date().toISOString(),
								createdBy: email,
								deleted: false,
								deletedAt: null,
								deletedBy: null,
								id: -1,
								lastEditedAt: null,
								lastEditedBy: null,
								message: comment,
							});
						},
					),
				);

				const patchReadMessageResult = dispatch(
					conversationApi.util.updateQueryData(
						"getConversationStatus",
						{ companyDomainId, planId, legalEntityId },
						(draft) => {
							return draft.filter((unreadConversationId) => {
								if (unreadConversationId === conversationId) {
									return false;
								}
								return true;
							});
						},
					),
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
					patchReadMessageResult.undo();
				}
			},
			invalidatesTags(result, error, arg) {
				return [
					"ConversationRead",
					...(result
						? [
								{
									type: "Conversation" as const,
									id: arg.conversationId,
								},
							]
						: []),
				];
			},
		}),

		initializeConversation: builder.mutation<
			{ conversationId: number },
			{
				companyDomainId: string;
				planId: string;
				legalEntityId: string;
				comment: string;
				referenceType: "account" | "driver";
				referenceId: string;
				email: string;
			}
		>({
			query({
				companyDomainId,
				legalEntityId,
				planId,
				comment,
				referenceId,
				referenceType,
			}) {
				return {
					method: "POST",
					url: buildPlanRouteUrl(`/conversations`, {
						planId,
						legalEntityId,
						companyDomainId,
					}),
					body: {
						message: comment,
						referenceId,
						referenceType,
						replyId: null,
					},
				};
			},
			invalidatesTags(result, error, arg) {
				return [
					"ConversationRead",
					...(result
						? [
								{
									type:
										arg.referenceType === "account"
											? ("AccountModuleEntry" as const)
											: ("DriverModuleEntry" as const),
									id: arg.referenceId,
								},
							]
						: []),
				];
			},
		}),

		updateComment: builder.mutation<
			void,
			{
				companyDomainId: string;
				planId: string;
				legalEntityId: string;
				conversationId: number;
				messageId: number;
				message: string;
			}
		>({
			query({
				planId,
				legalEntityId,
				conversationId,
				companyDomainId,
				message,
				messageId,
			}) {
				return {
					url: buildPlanRouteUrl(
						`/conversations/${conversationId}/message/${messageId}`,
						{
							planId,
							legalEntityId,
							companyDomainId,
						},
					),
					method: "PUT",
					body: {
						message,
					},
					responseHandler: "text",
				};
			},
			async onQueryStarted(
				{
					messageId,
					message: newMessage,
					legalEntityId,
					planId,
					companyDomainId,
					conversationId,
				},
				{ dispatch, queryFulfilled },
			) {
				const patchResult = dispatch(
					conversationApi.util.updateQueryData(
						"getConversation",
						{ conversationId, companyDomainId, planId, legalEntityId },
						(draft) => {
							for (const message of draft.conversationMessages) {
								if (message.id === messageId) {
									message.message = newMessage;
								}
							}
						},
					),
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
			invalidatesTags(result, error, args) {
				return [
					{ type: "ConversationMessage", id: args.messageId },
					{ type: "Conversation", id: args.conversationId },
				];
			},
		}),

		getConversationStatus: builder.query<
			number[],
			{ companyDomainId: string; planId: string; legalEntityId: string }
		>({
			query({ companyDomainId, planId, legalEntityId }) {
				return {
					url: buildPlanRouteUrl(`/conversations/read-conversations`, {
						planId,
						legalEntityId,
						companyDomainId,
					}),
				};
			},
			providesTags: ["ConversationRead"],
		}),

		markAsRead: builder.mutation<
			unknown,
			{
				companyDomainId: string;
				planId: string;
				legalEntityId: string;
				conversationId: number;
			}
		>({
			query({ companyDomainId, conversationId, planId, legalEntityId }) {
				return {
					url: buildPlanRouteUrl(`/conversations/${conversationId}/read`, {
						planId,
						legalEntityId,
						companyDomainId,
					}),
					method: "POST",
				};
			},
			invalidatesTags: () => {
				return ["ConversationRead"];
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useDeleteCommentMutation,
	useGetConversationStatusQuery,
	useGetConversationQuery,
	useGetConversationsQuery,
	useMarkAsReadMutation,
	useUpdateCommentMutation,
	usePostCommentMutation,
	useInitializeConversationMutation,
} = conversationApi;
