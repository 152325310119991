import { useLocale } from "../../../../locales/useLocale.ts";
import type { YearMonthPickerValue } from "./YearMonthPicker.tsx";
import { dateFromYearMonth } from "./yearMonthUtils.ts";

export const useFormattedYearMonthValue = (value: YearMonthPickerValue) => {
	const { formatRange, format } = useLocale();

	if (!value) {
		return undefined;
	}

	if ("start" in value) {
		return formatRange(
			dateFromYearMonth(value.start),
			dateFromYearMonth(value.end),
		);
	} else {
		return format(new Date(dateFromYearMonth(value)), {
			year: "numeric",
			month: "long",
		});
	}
};
