import { useState, useEffect, useRef } from "react";

export const useAsyncDefaultValues = <T>(callback: () => T | undefined) => {
	const resolve = useRef<(param: T) => void>();
	const [promise] = useState(
		() =>
			new Promise<T>((res) => {
				resolve.current = res;
			}),
	);

	useEffect(() => {
		const value = callback();
		if (value !== undefined) {
			if (resolve.current) {
				resolve.current(value);
				resolve.current = undefined;
			}
		}
	}, [callback]);

	return promise;
};
