import { FloatingPortal, type Placement } from "@floating-ui/react";
import { clsx } from "clsx";
import {
	cloneElement,
	type ComponentPropsWithoutRef,
	type CSSProperties,
	type ReactNode,
	useRef,
	type Ref,
	forwardRef,
	type MutableRefObject,
	type HTMLProps,
} from "react";
import { isElement } from "react-is";
import { mergeRefs } from "../../../utils/mergeRefs";
import { useTooltip } from "./useTooltip";
import { Text } from "../typography/Text";

const arrowSize = 8;

interface TooltipProps
	extends Omit<ComponentPropsWithoutRef<"div">, "children"> {
	children:
		| ReactNode
		| ((params: {
				getReferenceProps: <T>(userProps?: Omit<HTMLProps<Element>, "ref">) => {
					ref: MutableRefObject<T>;
					[key: string]: unknown;
				};
		  }) => JSX.Element);
	placement?: Placement;
	label: ReactNode | string;
	dark?: boolean;
	width?: number | "auto";
	zIndex?: CSSProperties["zIndex"];
	defaultOpen?: boolean;

	/**
	 * Should the tooltip have a default padding.
	 *
	 * Defaults to `true`
	 */
	defaultPadding?: boolean;

	/**
	 * Classname added to the floating container
	 */
	floatingClassName?: string;

	/**
	 * Close tooltip when clicking on the reference element
	 *
	 * Defaults to `false`
	 */
	closeWhenClickingOnReference?: boolean;
}

const TooltipInner = (
	{
		children,
		placement = "top",
		dark = false,
		width,
		label,
		zIndex = 2000,
		className,
		defaultOpen,
		floatingClassName,
		defaultPadding = true,
		closeWhenClickingOnReference = false,
		...others
	}: TooltipProps,
	ref: Ref<HTMLElement>,
) => {
	const arrowElementRef = useRef<HTMLDivElement | null>(null);

	const tooltip = useTooltip({
		placement,
		closeDelay: 0,
		openDelay: 0,
		offset: arrowSize / 2,
		arrowElement: arrowElementRef,
		defaultOpen,
		closeWhenClickingOnReference,
	});

	if (!isElement(children) && typeof children !== "function") {
		throw new Error(
			"Tooltip component children should be an element or a component that accepts ref, fragments, strings, numbers and other primitive values are not supported",
		);
	}

	const targetRef = mergeRefs([
		tooltip.reference,
		typeof children !== "function" ? (children as any).ref : null,
		ref,
	]);

	const arrowStyles = {};
	if (tooltip.middlewareData.arrow) {
		const { x: arrowX, y: arrowY } = tooltip.middlewareData.arrow;

		const staticSide = {
			top: "bottom",
			right: "left",
			bottom: "top",
			left: "right",
		}[tooltip.placement.split("-")[0]];

		Object.assign(arrowStyles, {
			left: arrowX != null ? `${arrowX}px` : "",
			top: arrowY != null ? `${arrowY}px` : "",
			right: "",
			bottom: "",
			[staticSide!]: "-4px",
		});
	}

	return (
		<>
			{tooltip.opened && (
				<FloatingPortal>
					{label === null || label === undefined ? null : (
						<div
							{...others}
							{...tooltip.getFloatingProps({
								ref: tooltip.floating,
								className: clsx(
									"absolute rounded-[8px] shadow-[0px_4px_6px_-2px_rgba(16,24,40,0.05),0px_12px_16px_-4px_rgba(16,24,40,0.1)]",
									defaultPadding ? "py-2 px-3" : undefined,
									dark ? "bg-grey-900" : "bg-white border border-grey-200",
									floatingClassName,
								),
								style: {
									width,
									zIndex,
									top: `${tooltip.y}px`,
									left: `${tooltip.x}px`,
								},
							})}
						>
							{typeof label === "string" ? (
								<Text
									size="xs"
									weight="medium"
									color={dark ? "text-white" : "text-grey-700"}
								>
									{label}
								</Text>
							) : (
								label
							)}
							<div
								className="absolute h-[8px] w-[8px] rotate-45 bg-inherit"
								style={arrowStyles}
								ref={arrowElementRef}
							/>
						</div>
					)}
				</FloatingPortal>
			)}
			{typeof children === "function"
				? children({
						getReferenceProps: (childProps) => {
							return tooltip.getReferenceProps({
								ref: targetRef,
								className: clsx(className, childProps?.className),
								...childProps,
							}) as {
								ref: MutableRefObject<any>;
								[key: string]: unknown;
							};
						},
					})
				: cloneElement(
						children,
						tooltip.getReferenceProps({
							ref: targetRef,
							className: clsx(className, children.props.className),
							...children.props,
						}),
					)}
		</>
	);
};

const TooltipWithRef = forwardRef(TooltipInner);

type TooltipWithRefProps = TooltipProps & {
	forwardedRef?: React.Ref<HTMLElement>;
};

/**
 * @deprecated Use `<Tooltip2>` instead
 */
export function Tooltip({ forwardedRef, ...props }: TooltipWithRefProps) {
	return <TooltipWithRef ref={forwardedRef} {...props} />;
}

Tooltip.displayName = "Tooltip";
