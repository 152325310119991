import { Button } from "components/button";
import {
	Menu,
	MenuProvider,
	MenuTarget,
	MenuItem,
	SubMenu,
} from "components/menu";
import { MenuSearchField } from "components/menu/MenuSearchField";
import { SelectContainer } from "components/select/SelectContainer";
import { useMemo, useState } from "react";
import { useDebounceState } from "src/common/utils/hooks/useDebounce";

const ALOT_OF_ITEMS = Array.from({ length: 10_000 }).map((_, i) => (
	<MenuItem
		label={`This is item number: ${i}`}
		key={i}
		onClick={() => alert(i > 9000 ? "Your power is over 9000" : "Nah")}
	/>
));

export const MenuPresentation = () => {
	const [selectValue, setSelectValue] = useState<string | null>(null);
	const [numberFilterText, setNumberFilterText, debouncedFilterText] =
		useDebounceState({
			initialValue: "",
			ms: 200,
		});

	const alotOfFilteredItems = useMemo(
		() =>
			ALOT_OF_ITEMS.filter((_, i) =>
				i.toString().includes(debouncedFilterText),
			),
		[debouncedFilterText],
	);

	return (
		<>
			<span className="text-xl">Menu with button component</span>
			<div className="py-4">
				<MenuProvider role="menu" menuOverflowStrategy="scroll">
					<MenuTarget>
						<Button variant="secondary">Toggle menu</Button>
					</MenuTarget>
					<Menu>
						<MenuItem label="Menu item 1" />
						<MenuItem label="Menu item 2" disabled />
						<SubMenu label="This should not be shown" />
						<SubMenu
							onClick={() => alert("this can be clikeli clocked")}
							label="Menu group 1 (Click me)"
						>
							<MenuItem label="Menu item 3" />
							<MenuItem label="Menu item 4" />
							<MenuItem label="Menu item 5" />
						</SubMenu>
						<SubMenu label="Menu group 2">
							<MenuItem label="Menu item 6" />
						</SubMenu>
						<SubMenu label="Menu group 3" disabled>
							<MenuItem label="Menu item 7" />
						</SubMenu>
					</Menu>
				</MenuProvider>
			</div>
			<span className="text-xl">Menu with select component</span>
			<div className="py-4">
				<MenuProvider role="menu">
					<MenuTarget>
						<SelectContainer placeholder="Best pizza?" value={selectValue}>
							{selectValue}
						</SelectContainer>
					</MenuTarget>
					<Menu>
						<MenuItem
							label="Clear"
							className="text-red-800"
							onClick={() => setSelectValue(null)}
							disabled={!selectValue}
						/>
						<MenuItem
							label="Pepperoni"
							onClick={() => setSelectValue("Pepperoni")}
						/>
						<MenuItem
							label="Margarita"
							onClick={() => setSelectValue("Margarita")}
						/>
						<MenuItem
							label="Hawaiian"
							onClick={() => setSelectValue("Hawaiian")}
						/>
						<SubMenu label="Kebab pizzas...">
							<MenuItem
								label="Viking special"
								onClick={() => setSelectValue("Viking special")}
							/>
							<MenuItem
								label="Classic kebab"
								onClick={() => setSelectValue("Classic kebab")}
							/>
							<SubMenu label="Secret menu">
								<MenuItem label="The Godfather" />
							</SubMenu>
						</SubMenu>
					</Menu>
				</MenuProvider>
			</div>
			<span className="text-xl">
				Searchable menu, using <i>preMenuItems</i> prop
			</span>
			<div className="py-4">
				<MenuProvider
					role="menu"
					globalMenuOptions={{
						minWidth: 300,
						maxWidth: 500,
						maxHeight: 300,
					}}
				>
					<MenuTarget>
						<SelectContainer placeholder="Best number?" value={selectValue}>
							{selectValue}
						</SelectContainer>
					</MenuTarget>
					<Menu
						preMenuItems={
							<MenuSearchField
								className="w-[200px]"
								filterValue={numberFilterText}
								setFilterValue={setNumberFilterText}
							/>
						}
					>
						{alotOfFilteredItems}
					</Menu>
				</MenuProvider>
			</div>
		</>
	);
};
