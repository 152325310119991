import { emptyFusionHubApi } from "./emptyFusionHubApi.ts";
import type {
	PaginatedParams,
	FusionhubIntegration,
	PaginatedResponse,
	ErLegalEntity,
	ErDocumentation,
	ErSyncStatus,
} from "./types.ts";

export const fusionHubApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		getErDocumentation: builder.query<ErDocumentation, void>({
			query: () => "/er/documentation",
		}),
		getAllIntegrations: builder.query<
			PaginatedResponse<FusionhubIntegration>,
			{ companyDomainId: string } & PaginatedParams
		>({
			query: (params) => ({
				url: "/integrations",
				params,
			}),
			providesTags: () => [
				{
					type: "Integrations",
				},
			],
		}),
		getErIntegrations: builder.query<
			PaginatedResponse<FusionhubIntegration>,
			{ companyDomainId: string } & PaginatedParams
		>({
			query: (params) => ({
				url: "/er/integrations",
				params,
			}),
			providesTags: () => [
				{
					type: "Integrations",
				},
			],
		}),
		getErLegalEntity: builder.query<ErLegalEntity, { integrationId: number }>({
			query: ({ integrationId }) =>
				`/er/integrations/${integrationId}/legal-entity`,
		}),
		getErSyncStatus: builder.query<
			{ sync: ErSyncStatus | null },
			{ integrationId: number }
		>({
			query: ({ integrationId }) => `/er/integrations/${integrationId}/sync`,
			providesTags: (result, error, { integrationId }) => [
				{ type: "SyncStatus", id: integrationId },
			],
		}),
		createDemoIntegration: builder.mutation<
			FusionhubIntegration,
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => ({
				url: "/integrations",
				params: { companyDomainId },
				method: "POST",
				body: { companyDomainId, type: "DEMO" },
			}),
			invalidatesTags: [{ type: "Integrations" }],
		}),
		triggerSync: builder.mutation<void, { integrationId: number }>({
			query: ({ integrationId }) => ({
				url: `/er/integrations/${integrationId}/sync`,
				method: "POST",
			}),
			invalidatesTags: (result, error, { integrationId }) => [
				{ type: "SyncStatus", id: integrationId },
				{ type: "Integrations", id: integrationId },
			],
		}),
		removeIntegration: builder.mutation<void, { integrationId: number }>({
			query: ({ integrationId }) => ({
				url: `/integrations/${integrationId}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, { integrationId }) => [
				{ type: "SyncStatus", id: integrationId },
				{ type: "Integrations" },
			],
		}),
	}),
});

export const {
	useGetAllIntegrationsQuery,
	useGetErIntegrationsQuery,
	useGetErLegalEntityQuery,
	useGetErDocumentationQuery,
	useGetErSyncStatusQuery,
	useTriggerSyncMutation,
	useLazyGetErLegalEntityQuery,
	useRemoveIntegrationMutation,
	useCreateDemoIntegrationMutation,
} = fusionHubApi;
