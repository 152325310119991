import { useTranslation } from "react-i18next";
import type { AccessGroup } from "../../common/service/Types/AccessGroup";
import { IconTrash } from "@tabler/icons-react";
import { Anchor } from "../../common/components/atoms/anchor/Anchor";
import { getGroupName } from "./getGroupName.js";
import { Button } from "../../common/components/atoms/button/Button.js";

interface AccessGroupTableRowProps {
	accessGroup: AccessGroup;
	onEditGroup: (groupId: number) => void;
	onDeleteGroup: (groupId: number) => void;
}

export const AccessGroupTableRow = ({
	accessGroup,
	onEditGroup,
	onDeleteGroup,
}: AccessGroupTableRowProps) => {
	const { t } = useTranslation();

	return (
		<tr>
			<td>
				<Anchor<"a">
					onClick={(event) => {
						event.preventDefault();
						onEditGroup(accessGroup.groupId);
					}}
				>
					{getGroupName({ t, groupName: accessGroup.name })}
				</Anchor>
			</td>
			<td>{accessGroup.description}</td>
			<td>{accessGroup.userEmails.length}</td>
			<td>
				<div
					className={`rounded-full ${
						accessGroup.colorCode ? undefined : "bg-purple-500"
					} h-[20px] w-[20px]`}
					style={{ backgroundColor: accessGroup.colorCode }}
				/>
			</td>
			<td>
				<Button
					ariaLabel={t("Remove group")}
					onClick={(e) => {
						e.stopPropagation();
						onDeleteGroup(accessGroup.groupId);
					}}
					variant="ghost"
					disabled={accessGroup.readOnly}
					icon={<IconTrash />}
				/>
			</td>
		</tr>
	);
};
