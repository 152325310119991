import type { ReactNode } from "react";
import { TooltipContext } from "./TooltipContext.js";
import { type UseTooltipOptions, useTooltip } from "./useTooltip.js";

export function Tooltip2({
	children,
	...restOptions
}: {
	children: ReactNode;
} & UseTooltipOptions) {
	return (
		<TooltipContext.Provider value={useTooltip(restOptions)}>
			{children}
		</TooltipContext.Provider>
	);
}
