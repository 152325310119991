import type { HTMLAttributes, ReactNode } from "react";
import { clsx } from "clsx";
import { Breadcrumbs } from "../atoms/breadcrumbs/Breadcrumbs";
import { PersistedLink } from "../../persistentRouterFunctions";
import { Chip, type ChipProps } from "../atoms/chip/Chip";
import { Text } from "../atoms/typography/Text";

export interface BreadCrumbLink {
	name?: string;
	path?: string;
	icon?: ReactNode;
	chipProps?: ChipProps;
	content?: ReactNode | undefined;
}

interface BreadcrumbsLayoutProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
	breadcrumbs: BreadCrumbLink[];
	headerContent?: ReactNode;
	mainProps?: HTMLAttributes<HTMLElement>;

	// if the center wrapper should only be applied to the header
	onlyCenterHeader?: boolean;
	maxWidthClassName?: string;
	description?: string;
}

export const BreadcrumbsLayout = ({
	breadcrumbs,
	headerContent,
	children,
	className,
	mainProps,
	onlyCenterHeader,
	maxWidthClassName = "max-w-screen-lg",
	description,
	...props
}: BreadcrumbsLayoutProps) => {
	return (
		<div className={clsx("p-4 md:p-6", className)} {...props}>
			<div
				className={
					!onlyCenterHeader ? `${maxWidthClassName} mx-auto` : undefined
				}
			>
				<header
					className={clsx(
						"mb-2 flex flex-wrap justify-end",
						onlyCenterHeader && `${maxWidthClassName} mx-auto`,
					)}
				>
					<Breadcrumbs
						maxItems={2}
						aria-label="breadcrumb"
						description={description}
						breadcrumbs={breadcrumbs}
					>
						{breadcrumbs.map(({ name, path, icon, chipProps }, index) => {
							let chip: JSX.Element | null = null;
							if (chipProps) {
								const { className: chipClassName, ...restChipProps } =
									chipProps;
								chip = (
									<Chip
										className={clsx("ml-2", chipClassName)}
										{...restChipProps}
									/>
								);
							}
							const isLastBreadcrumb = breadcrumbs.length === index + 1;
							const children = (
								<div className="flex items-center">
									{icon && (
										<div
											className={clsx(
												name && "mr-2",
												isLastBreadcrumb ? "text-primary-600" : "text-grey-500",
												!isLastBreadcrumb && path && "hover:text-purple-600",
											)}
										>
											{icon}
										</div>
									)}
									{name && (
										<div className="flex flex-col">
											<Text
												size="lg"
												weight="medium"
												color={clsx(
													isLastBreadcrumb
														? "text-primary-600"
														: "text-grey-500",
													!isLastBreadcrumb && path && "hover:text-purple-600",
												)}
												className="break-all"
											>
												{name}
											</Text>
										</div>
									)}

									{chip}
								</div>
							);

							if (!isLastBreadcrumb && path) {
								return (
									<PersistedLink to={path} key={index}>
										{children}
									</PersistedLink>
								);
							}
							return (
								<Text size="lg" weight="medium" component="h2" key={index}>
									{children}
								</Text>
							);
						})}
					</Breadcrumbs>
					{headerContent && (
						<div className="flex items-center self-start">{headerContent}</div>
					)}
				</header>
				<div {...mainProps}>{children}</div>
			</div>
		</div>
	);
};
