import {
	buildPlanRouteUrl,
	type DomainUser,
	type AddUserToPlanBody,
	type ChangeUserBody,
} from "@exopengithub/planning-api-shared";
import { emptyPlanningApi } from "./emptyPlanningApi.js";

const planUserApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		getCurrentUser: builder.query<
			DomainUser,
			{ companyDomainId: string; legalEntityId: string; planId: string }
		>({
			query({ legalEntityId, companyDomainId, planId }) {
				return buildPlanRouteUrl("/users/me", {
					planId,
					companyDomainId,
					legalEntityId,
				});
			},
			providesTags(result) {
				if (result && result.explicitUserId) {
					return [{ type: "PlanUser", id: result.explicitUserId }];
				}
				return ["PlanUser"];
			},
		}),

		getUsers: builder.query<
			DomainUser[],
			{ companyDomainId: string; legalEntityId: string; planId: string }
		>({
			query({ legalEntityId, companyDomainId, planId }) {
				return buildPlanRouteUrl("/users", {
					planId,
					companyDomainId,
					legalEntityId,
				});
			},
			providesTags(result) {
				if (result) {
					return [
						"PlanUser",
						...result
							.filter((user) => user.explicitUserId)
							.map((user) => {
								return { type: "PlanUser" as const, id: user.explicitUserId! };
							}),
					];
				}
				return ["PlanUser"];
			},
		}),

		addUser: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
				body: AddUserToPlanBody;
			}
		>({
			query({ legalEntityId, companyDomainId, planId, body }) {
				return {
					url: buildPlanRouteUrl("/users", {
						planId,
						companyDomainId,
						legalEntityId,
					}),
					method: "POST",
					body,
				};
			},
			invalidatesTags() {
				return ["PlanUser"];
			},
		}),

		removeUser: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
				userId: string;
			}
		>({
			query({ legalEntityId, companyDomainId, planId, userId }) {
				return {
					url: buildPlanRouteUrl(`/users/${userId}`, {
						planId,
						companyDomainId,
						legalEntityId,
					}),
					method: "DELETE",
				};
			},
			invalidatesTags(_, _1, args) {
				return [{ type: "PlanUser", id: args.userId }];
			},
		}),

		updateUser: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
				userId: string;
				body: ChangeUserBody;
			}
		>({
			query({ legalEntityId, companyDomainId, planId, userId, body }) {
				return {
					url: buildPlanRouteUrl(`/users/${userId}`, {
						planId,
						companyDomainId,
						legalEntityId,
					}),
					method: "PUT",
					body,
				};
			},
			invalidatesTags(_, _1, args) {
				return [{ type: "PlanUser", id: args.userId }];
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetUsersQuery,
	useAddUserMutation,
	useRemoveUserMutation,
	useUpdateUserMutation,
	useGetCurrentUserQuery,
} = planUserApi;
