import {
	FloatingFocusManager,
	FloatingPortal,
	useMergeRefs,
} from "@floating-ui/react";
import { forwardRef, useRef, type ReactNode } from "react";
import { Input } from "../input/Input.tsx";
import { Text } from "../typography/Text.tsx";
import { clsx } from "clsx";
import { IconSelector, IconX } from "@tabler/icons-react";
import { Popover } from "../popoverMenu/Popover.tsx";
import { zIndicies } from "../../../utils/zIndicies.ts";
import { maybe } from "../../../utils/maybe.ts";
import type { SelectOption } from "./selectTypes.ts";
import { useTranslation } from "react-i18next";
import type { RenderSelectOption } from "./Option.tsx";
import { Tooltip2 } from "../tooltip2/Tooltip2.tsx";
import { TooltipTrigger } from "../tooltip2/TooltipTrigger.tsx";
import { TooltipContent } from "../tooltip2/TooltipContent.tsx";
import { useMultiSelect } from "./useMultiSelect.ts";
import { Chip } from "../chip/Chip.tsx";
import { MultiSelectContext } from "./MultiSelectContext.ts";
import { MultiSelectOption } from "./MultiSelectOption.tsx";

type SelectVariant = "primary" | "ghost";

type MultiSelectProps = {
	options: SelectOption[] | ReadonlyArray<SelectOption>;
	value: string[];
	placeholder: string;
	onChange?: ((value: string[]) => void) | undefined;
	ariaInvalid?: boolean | undefined;
	className?: string | undefined;
	withFilter?: boolean | undefined;
	disabled?: boolean | undefined;
	variant?: SelectVariant | undefined;
	clearable?: boolean | undefined;
	beforeInput?: ReactNode;
	maxWidth?: number | undefined;
	onClose?: (() => void) | undefined;
	renderOption?: RenderSelectOption | undefined;
	hintAsPopup?: boolean | undefined;
	hint?: string | undefined;
	onDismissError?: (() => void) | undefined;
} & (
	| {
			"aria-labelledby": string;
			ariaLabel?: never;
	  }
	| { "aria-labelledby"?: never; ariaLabel: string }
);

export const MultiSelect = forwardRef<HTMLButtonElement, MultiSelectProps>(
	function MultiSelect(
		{
			options,
			placeholder,
			onChange,
			value,
			ariaInvalid,
			className,
			withFilter = false,
			"aria-labelledby": ariaLabelledby,
			disabled = false,
			variant = "primary",
			clearable = false,
			beforeInput,
			maxWidth = 300,
			onClose,
			ariaLabel,
			renderOption,
			hint,
			onDismissError,
			hintAsPopup = false,
		},
		ref,
	) {
		const {
			handleSelect,
			floatingContext,
			isMounted,
			selectContext,
			activeIndex,
			floatingStyles,
			getFloatingProps,
			getReferenceProps,
			hasFilter,
			inputRef,
			inputValue,
			items,
			refs,
			setActiveIndex,
			setInputValue,
			transitionStyles,
			elementsRef,
			parentRef,
			rowVirtualizer,
		} = useMultiSelect({
			options,
			value,
			withFilter,
			onClose,
			onChange,
			maxWidth,
			disabled,
		});
		const { t } = useTranslation();

		const persistedError = useRef<string | null>(null);
		if (hint) {
			persistedError.current = hint;
		}
		const tooltipContent = (
			<Text size="sm" color="text-white">
				{persistedError.current}
			</Text>
		);

		const reference = (
			<div
				{...getReferenceProps({
					ref: useMergeRefs([ref, refs.setReference]),
					"aria-invalid": ariaInvalid,
					className: clsx(
						"flex items-center py-2 bg-white hover:bg-gray-50 transition-colors select-none cursor-pointer group aria-disabled:cursor-not-allowed aria-disabled:bg-gray-50 pr-2 text-left focus:outline-none focus-visible:ring-4 w-full focus-visible:ring-purple-100 focus-visible:border-purple-400 border-gray-300 aria-[invalid=true]:border-red-300 focus-visible:aria-[invalid=true]:ring-red-100",
						variant === "primary"
							? "shadow-sm rounded-lg border"
							: "ring-inset",
						className,
						value.length === 0 ? "pl-4" : "pl-2",
					),
					"aria-labelledby": ariaLabelledby,
					"aria-label": ariaLabel,
					"aria-disabled": disabled,
					tabIndex: disabled ? -1 : 0,
				})}
			>
				<div className="flex w-full">
					{value.length === 0 ? (
						<Text
							size="sm"
							className="grow truncate"
							color={clsx(
								"text-gray-500 group-aria-invalid:text-red-500 group-aria-disabled:text-gray-500",
							)}
						>
							{placeholder}
						</Text>
					) : (
						<div className="inline-flex grow flex-wrap gap-2">
							{value.map((value) => {
								return (
									<Chip
										key={value}
										closeButton
										onClose={(event) => {
											event.stopPropagation();
											handleSelect(
												options.findIndex((option) => {
													return option.value === value;
												}),
											);
										}}
										size="medium"
										color="purple"
									>
										{options.find((option) => option.value === value)?.label}
									</Chip>
								);
							})}
						</div>
					)}
					<div className="flex items-center pl-2">
						{clearable && value.length > 0 ? (
							<button
								onClick={(event) => {
									handleSelect(null);
									event.stopPropagation();
								}}
								className="rounded-md p-1 hover:bg-purple-50"
							>
								<IconX
									size={18}
									className="shrink-0 text-purple-500 group-aria-invalid:text-red-500"
								/>
							</button>
						) : (
							<IconSelector
								size={18}
								className="mr-1 shrink-0 text-purple-500 group-aria-invalid:text-red-500"
							/>
						)}
					</div>
				</div>
			</div>
		);

		return (
			<>
				{hintAsPopup ? (
					<Tooltip2 open={ariaInvalid}>
						<TooltipTrigger asChild>{reference}</TooltipTrigger>
						<TooltipContent
							className="cursor bg-red-500"
							arrowClassName="fill-red-500"
						>
							{onDismissError ? (
								<button
									aria-label={t("Dismiss error")}
									onClick={() => {
										onDismissError();
									}}
								>
									{tooltipContent}
								</button>
							) : (
								tooltipContent
							)}
						</TooltipContent>
					</Tooltip2>
				) : (
					reference
				)}
				<MultiSelectContext.Provider value={selectContext}>
					{isMounted ? (
						<FloatingPortal>
							<FloatingFocusManager
								context={floatingContext}
								modal={false}
								{...maybe(hasFilter, {
									initialFocus: inputRef,
								})}
							>
								<div
									ref={refs.setFloating}
									style={floatingStyles}
									{...getFloatingProps({
										className: clsx("outline-none", zIndicies.SELECT),
										role: "dialog",
										"aria-labelledby": ariaLabelledby,
										"aria-label": ariaLabel,
										onKeyDown(event) {
											if (event.key === "Enter") {
												handleSelect(activeIndex);
											}
										},
									})}
								>
									<Popover.ContentContainer style={transitionStyles}>
										{beforeInput}
										{hasFilter && (
											<div className="p-4">
												<Input
													onChange={(event) => {
														setActiveIndex(null);
														setInputValue(event.target.value);
													}}
													value={inputValue}
													onKeyDown={(event) => {
														if (event.code === "Enter") {
															handleSelect(activeIndex);
														}
													}}
													placeholder={t("Search")}
													ref={inputRef}
												/>
											</div>
										)}
										{items.length === 0 ? (
											<Text
												size="sm"
												className={clsx(
													"pb-3 text-center",
													!hasFilter && "pt-3",
												)}
												color="text-gray-500"
											>
												{t("No results")}
											</Text>
										) : (
											<div
												ref={parentRef}
												className={clsx(
													"max-h-[300px] overflow-y-auto",
													items.length > 50 ? "h-[300px]" : null,
												)}
											>
												<div
													ref={parentRef}
													className={clsx("relative flex flex-col")}
													style={{
														height: `${rowVirtualizer.getTotalSize()}px`,
													}}
													role="listbox"
												>
													{rowVirtualizer
														.getVirtualItems()
														.map((virtualItem) => {
															const item = items[virtualItem.index];
															return (
																<MultiSelectOption
																	key={item.value}
																	label={item.label}
																	value={item.value}
																	ref={(node) => {
																		elementsRef.current[item.index] = node;
																	}}
																	index={item.index}
																	className="absolute left-0 top-0 w-full"
																	style={{
																		height: virtualItem.size,
																		transform: `translateY(${virtualItem.start}px)`,
																	}}
																	disabled={item.disabled}
																	renderOption={renderOption}
																/>
															);
														})}
												</div>
											</div>
										)}
									</Popover.ContentContainer>
								</div>
							</FloatingFocusManager>
						</FloatingPortal>
					) : null}
				</MultiSelectContext.Provider>
			</>
		);
	},
);
