import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import {
	useDeleteLegalEntityMutation as useDeleteLegalEntityBackendMutation,
	useGetLegalEntitiesQuery as useGetLegalEntitiesLegacyQuery,
} from "../../common/redux/api/exopenApi";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";
import { IconTrash } from "@tabler/icons-react";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { useLegalEntities } from "../../common/legal-entities/useLegalEntities.js";
import { useCurrentCompanyDomain } from "../../common/company-domain/useCurrentCompanyDomain.js";
import { useDeleteLegalEntityMutation } from "../../common/service/nexus/nexusApi.js";
import { Chip } from "../../common/components/atoms/chip/Chip.js";
import type { ApiLegalEntity } from "../../common/redux/api/exopenApiTypes.js";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.js";
import { Button } from "../../common/components/atoms/button/Button.js";
import { ConfirmDialog } from "../../common/components/atoms/dialog/ConfirmDialog.js";
import { Checkbox } from "components/checkbox/Checkbox.js";
import { Text } from "components/typography/Text.tsx";

type MergedLegalEntity = {
	name: string;
	nexusId: string | null;
	leMasterKey: string;
	inBackend: boolean;
	source: string | null;
};

const mergeLegalEntities = (
	legalEntities: LegalEntity[],
	legacyLegalEntities: ApiLegalEntity[],
) => {
	const result: MergedLegalEntity[] = [];

	for (const legalEntity of legalEntities) {
		result.push({
			name: legalEntity.name,
			nexusId: legalEntity.id,
			leMasterKey: legalEntity.legalEntityMasterKey,
			inBackend: false,
			source: legalEntity.source,
		});
	}

	for (const legalEntity of legacyLegalEntities) {
		const existing = result.find(
			(mergedLegalEntity) =>
				mergedLegalEntity.leMasterKey === legalEntity.leMasterKey,
		);

		if (!existing) {
			result.push({
				name: legalEntity.name,
				nexusId: null,
				leMasterKey: legalEntity.leMasterKey,
				inBackend: true,
				source: null,
			});
		} else {
			existing.inBackend = true;
		}
	}

	return result;
};

export const LegalEntities = () => {
	const { companyDomain } = useCurrentCompanyDomain();
	const { legalEntities, isLoading } = useLegalEntities();
	const { data: legacyLegalEntities, isLoading: isLoadingLegalEntities } =
		useGetLegalEntitiesLegacyQuery(companyDomain.id);

	const { t } = useTranslation();

	const [legalEntityToDelete, setLegalEntityToDelete] =
		useState<MergedLegalEntity | null>(null);

	const [deleteLegalEntityInBackend, { isLoading: isDeletingInBackend }] =
		useDeleteLegalEntityBackendMutation();

	const [deleteLegalEntityInNexus, { isLoading: isDeletingInNexus }] =
		useDeleteLegalEntityMutation();

	const [selectedLegalEntitiesRecord, setSelectedLegalEntitiesRecord] =
		useState<Record<string, boolean>>({});

	const [deleteSelected, setDeleteSelected] = useState(false);
	const [isDeletingMultiple, setIsDeletingMultiple] = useState(false);

	const mergedLegalEntities = useMemo(
		() => mergeLegalEntities(legalEntities ?? [], legacyLegalEntities ?? []),
		[legalEntities, legacyLegalEntities],
	);

	const selectedLegalEntities = useMemo(
		() =>
			mergedLegalEntities.filter(
				(legalEntity) => selectedLegalEntitiesRecord[legalEntity.leMasterKey],
			),
		[mergedLegalEntities, selectedLegalEntitiesRecord],
	);

	if (isLoading || isLoadingLegalEntities || isDeletingMultiple) {
		return <LoadingState />;
	}

	if (!legalEntities || !legacyLegalEntities) {
		return <MissingDataState />;
	}

	return (
		<>
			<ConfirmDialog
				destructive
				open={legalEntityToDelete !== null}
				onClose={() => {
					setLegalEntityToDelete(null);
				}}
				onConfirm={async () => {
					if (legalEntityToDelete) {
						if (legalEntityToDelete.nexusId) {
							const result = await deleteLegalEntityInNexus({
								companyDomainId: companyDomain.id,
								legalEntityId: legalEntityToDelete.nexusId,
							});

							if ("error" in result) {
								showErrorNotification({
									message: t("Failed to delete legal entity"),
								});

								return;
							}
						}

						if (legalEntityToDelete.inBackend) {
							const resultFromBackend = await deleteLegalEntityInBackend({
								companyId: companyDomain.id,
								legalEntityId: legalEntityToDelete.leMasterKey,
							});

							if ("error" in resultFromBackend) {
								showErrorNotification({
									message: t("Failed to delete legal entity"),
								});
								return;
							}
						}

						setLegalEntityToDelete(null);
						showSuccessNotification({
							message: t("Legal entity was deleted"),
						});
					}
				}}
				isLoading={isDeletingInBackend || isDeletingInNexus}
				title={t('Delete legal entity "{{legalEntity}}"', {
					legalEntity: legalEntityToDelete?.name ?? t("Unknown"),
				})}
				confirmButtonLabel={t("Remove")}
				description={t(
					"Are you sure you want to delete this legal entity? This action can not be undone.",
				)}
			/>

			<ConfirmDialog
				destructive
				open={deleteSelected}
				onClose={() => {
					setDeleteSelected(false);
				}}
				onConfirm={async () => {
					setIsDeletingMultiple(true);
					try {
						const legalEntitiesToDelete = [...selectedLegalEntities];
						for (const legalEntity of legalEntitiesToDelete) {
							if (legalEntity.nexusId) {
								await deleteLegalEntityInNexus({
									companyDomainId: companyDomain.id,
									legalEntityId: legalEntity.nexusId,
								});
							}

							if (legalEntity.inBackend) {
								await deleteLegalEntityInBackend({
									companyId: companyDomain.id,
									legalEntityId: legalEntity.leMasterKey,
								});
							}
						}

						showSuccessNotification({
							message: t("Deleted {{number}} legal entities", {
								number: legalEntitiesToDelete.length,
							}),
						});
					} finally {
						setDeleteSelected(false);
						setIsDeletingMultiple(false);
					}
				}}
				isLoading={isDeletingMultiple}
				title={t("Delete {{number}} legal entities", {
					number: selectedLegalEntities.length,
				})}
				confirmButtonLabel={t("Remove")}
				description={t("Delete {{number}} legal entities", {
					number: selectedLegalEntities.length,
				})}
			/>

			<PlanningTableContainer>
				<PlanningTable>
					<thead>
						<tr>
							<th />
							<th className="text-right">#</th>
							<th>Namn</th>
							<th>
								<Button onClick={() => setDeleteSelected(true)}>
									{t("Delete selected")}
								</Button>
							</th>
						</tr>
					</thead>
					<tbody>
						{mergedLegalEntities.map((legalEntity, index) => {
							return (
								<tr key={legalEntity.leMasterKey}>
									<td>
										<Checkbox
											checked={
												!!selectedLegalEntitiesRecord[legalEntity.leMasterKey]
											}
											onClick={(event) => {
												const isChecked =
													!!selectedLegalEntitiesRecord[
														legalEntity.leMasterKey
													];
												const newSelectedLegalEntities = {
													...selectedLegalEntitiesRecord,
													[legalEntity.leMasterKey]: !isChecked,
												};

												const shiftPressed = event.shiftKey;
												if (shiftPressed && !isChecked) {
													const legalEntitiesBefore = mergedLegalEntities.slice(
														0,
														index,
													);
													const lastCheckedLegalEntity = legalEntitiesBefore
														.slice()
														.find(
															(le) =>
																!!selectedLegalEntitiesRecord[le.leMasterKey],
														);
													if (lastCheckedLegalEntity) {
														const lastCheckedIndex =
															legalEntitiesBefore.findIndex(
																(le) =>
																	le.leMasterKey ===
																	lastCheckedLegalEntity.leMasterKey,
															);
														const legalEntitiesToCheck =
															mergedLegalEntities.slice(
																lastCheckedIndex + 1,
																index,
															);
														for (const legalEntity of legalEntitiesToCheck) {
															newSelectedLegalEntities[
																legalEntity.leMasterKey
															] = true;
														}
													}
												}
												setSelectedLegalEntitiesRecord(
													newSelectedLegalEntities,
												);
											}}
											onChange={() => {}}
										/>
									</td>
									<td className="w-0 text-right">
										<Text size="sm">{legalEntity.nexusId}</Text>
									</td>
									<td>
										{legalEntity.name} ({legalEntity.leMasterKey})
										<span className="ml-2 inline-flex gap-2">
											{legalEntity.source && (
												<Chip size="small" color="blue-light">
													{legalEntity.source}
												</Chip>
											)}
											<Chip
												size="small"
												color={legalEntity.inBackend ? "purple" : "error"}
											>
												Backend
											</Chip>
											<Chip
												size="small"
												color={legalEntity.nexusId ? "purple" : "error"}
											>
												Nexus
											</Chip>
										</span>
									</td>
									<td>
										<Button
											variant="ghost"
											onClick={() => {
												setLegalEntityToDelete(legalEntity);
											}}
											icon={<IconTrash />}
											ariaLabel={t("Delete")}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</PlanningTable>
			</PlanningTableContainer>
		</>
	);
};
