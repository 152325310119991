import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PersistedLink } from "../../common/persistentRouterFunctions";
import { Input } from "../../common/components/atoms/input/Input";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import { Tabs } from "../../common/components/atoms/tabs/Tabs";
import { Tab } from "../../common/components/atoms/tabs/Tab";
import { useGetAllCompaniesQuery } from "../../common/redux/api/exopenApi";
import { AddCompany } from "./AddCompany";
import { IconPlus } from "@tabler/icons-react";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";
import { Anchor } from "../../common/components/atoms/anchor/Anchor";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import {
	makeStringSort,
	stringSort,
	useSort,
} from "../../common/utils/hooks/useSort";
import { SortingArrowButton } from "../planning/components/SortingArrowButton";
import { Text } from "../../common/components/atoms/typography/Text";
import { useLocale } from "src/locales/useLocale";
import { Button } from "../../common/components/atoms/button/Button.tsx";

const ALL_TYPE = "All";

export const Companies = () => {
	const { t } = useTranslation();

	const { isLoading: isLoadingCompanies, data: companies } =
		useGetAllCompaniesQuery();

	const [tab, setTab] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const { formatRelative } = useLocale();

	const companyDomainTypes = useMemo(() => {
		const typeBuckets: Record<string, number> = {};
		for (const company of companies ?? []) {
			if (!typeBuckets[company.type]) {
				typeBuckets[company.type] = 0;
			}
			typeBuckets[company.type]++;
		}

		const entries = Object.entries(typeBuckets).sort((a, b) => {
			return stringSort(a[0], b[0]);
		});

		return [[ALL_TYPE, companies?.length ?? 0] as const, ...entries];
	}, [companies]);

	const filtratedCompanies = useMemo(() => {
		return (companies ?? []).filter((company) => {
			if (companyDomainTypes[tab][0] === ALL_TYPE) {
				return true;
			}

			return company.type === companyDomainTypes[tab][0];
		});
	}, [companies, companyDomainTypes, tab]);

	const pageName = t("Business");
	useDocumentTitle(pageName);

	const { items: sortedCompanies, sortColumnProps } = useSort({
		keys: ["byName", "byCreated"],
		defaultKey: "byName",
		defaultOrder: "asc",
		rows: filtratedCompanies,
		columns: {
			byName: {
				sort: makeStringSort("name"),
			},
			byCreated: {
				key: "dateCreated",
			},
		},
	});

	if (isLoadingCompanies) {
		return <LoadingState />;
	}

	if (!companies) {
		return <MissingDataState />;
	}

	return (
		<>
			<Text className="mb-2" size="md">
				{t("Tenants")}
			</Text>
			<div className="mb-4 flex gap-4">
				<Tabs
					selectedIndex={tab}
					onSelectTab={(index) => {
						setTab(index);
					}}
					className="flex-grow"
				>
					{companyDomainTypes.map(([type, count]) => {
						return (
							<Tab key={type} count={count}>
								{type}
							</Tab>
						);
					})}
				</Tabs>

				<AddCompany>
					<Button icon={<IconPlus />}>{t("New Company Domain")}</Button>
				</AddCompany>
			</div>

			<PlanningTableContainer>
				<PlanningTable ariaLabel={t("Companies")}>
					<thead>
						<tr>
							<th>
								<SortingArrowButton {...sortColumnProps.byName}>
									{t("Name")}
								</SortingArrowButton>
								<Input
									placeholder={t("Filter by name")}
									type="search"
									value={searchTerm}
									onChange={(event) => {
										setSearchTerm(event.target.value);
									}}
								/>
							</th>
							<th className="w-0 whitespace-nowrap">
								<SortingArrowButton {...sortColumnProps.byCreated}>
									{t("Created")}
								</SortingArrowButton>
							</th>
						</tr>
					</thead>
					<tbody>
						{sortedCompanies
							.filter((item) =>
								item.name.toLowerCase().includes(searchTerm.toLowerCase()),
							)
							.map((company) => {
								return (
									<Fragment key={company.id}>
										<tr>
											<td>
												<Anchor component={PersistedLink} to={`/${company.id}`}>
													{company.name}
												</Anchor>
											</td>
											<td className="whitespace-nowrap">
												{formatRelative(new Date(company.dateCreated!))}
											</td>
										</tr>
									</Fragment>
								);
							})}
					</tbody>
				</PlanningTable>
			</PlanningTableContainer>
		</>
	);
};
