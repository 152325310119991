import { useLegalEntitiesOrThrow } from "src/common/legal-entities/useLegalEntities.ts";
import { useGetIntegrationsQuery } from "src/common/service/nexus/nexusApi.ts";
import { useCurrentCompanyDomain } from "src/common/company-domain/useCurrentCompanyDomain.ts";
import { useSearchParams } from "react-router-dom";
import { LegalEntityConnector } from "./LegalEntityConnector.tsx";
import { QueriesResult } from "components/queryResult/QueriesResult.tsx";
import { MissingDataState } from "components/missingDataState/MissingDataState.tsx";
import { useGetErLegalEntityQuery } from "src/common/service/fusionHub/fusionHubApi.ts";
import { skipToken } from "@reduxjs/toolkit/query";

export const ConnectConnectorPage = () => {
	const [searchParams] = useSearchParams();

	const integrationId = searchParams.get("integrationId");
	const autoResolve = searchParams.get("autoResolve");
	const customLegalEntityName = searchParams.get("customLegalEntityName");

	const { companyDomain } = useCurrentCompanyDomain();
	const { legalEntities } = useLegalEntitiesOrThrow();

	const queries = {
		integrations: useGetIntegrationsQuery({
			companyDomainId: companyDomain.id,
		}),
		erLegalEntity: useGetErLegalEntityQuery(
			integrationId ? { integrationId: Number(integrationId) } : skipToken,
		),
	};

	if (!integrationId) {
		return <MissingDataState />;
	}

	return (
		<QueriesResult
			queries={queries}
			render={({ integrations, erLegalEntity }) => {
				return (
					<LegalEntityConnector
						integrationId={integrationId}
						companyDomain={companyDomain}
						legalEntity={erLegalEntity}
						existingIntegrations={integrations}
						legalEntities={legalEntities}
						autoResolve={autoResolve === "true"}
						customLegalEntityName={customLegalEntityName}
					/>
				);
			}}
		/>
	);
};
