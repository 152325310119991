import {
	useFloating,
	useFloatingNodeId,
	type ExtendedRefs,
	type FloatingContext,
	flip,
	shift,
	autoUpdate,
	type ReferenceType,
	type Strategy,
	useInteractions,
	useClick,
	type UseRoleProps,
} from "@floating-ui/react";
import {
	useState,
	createContext,
	type Dispatch,
	type SetStateAction,
	type HTMLProps,
} from "react";

type GlobalMenuOptions = Partial<{
	className: string;
	maxHeight: number;
	maxWidth: number;
	minWidth: number;
	minHeight: number;
	closeMenuOnClick: boolean;
}>;

export type MenuOverflowStrategy =
	/* You must handle overflow in your own way */
	| "none"
	/* The menu will scroll if it overflows with div*/
	| "scroll"
	/* The menu will use react-virtuoso to virtualize the menu items */
	| "virtualize";

type MenuContext = {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	refs: ExtendedRefs<ReferenceType>;
	context: FloatingContext;
	nodeId: string;
	x: number;
	y: number;
	strategy: Strategy;
	role: UseRoleProps["role"];
	globalMenuOptions: GlobalMenuOptions;
	menuOverflowStrategy: MenuOverflowStrategy;
	getReferenceProps: (
		userProps?: HTMLProps<Element> | undefined,
	) => Record<string, unknown>;
};

export const Context = createContext<MenuContext>({} as MenuContext);

type MenuProviderProps = {
	children: React.ReactNode;
	role: UseRoleProps["role"];
	/**
	 * Applied to all menu and submenu components
	 */
	globalMenuOptions?: GlobalMenuOptions;
	menuOverflowStrategy?: MenuOverflowStrategy;
};

export const MenuProvider = ({
	children,
	globalMenuOptions,
	role,
	menuOverflowStrategy = "virtualize",
}: MenuProviderProps) => {
	const [open, setOpen] = useState(false);

	const nodeId = useFloatingNodeId();

	const { refs, context, x, y, strategy } = useFloating({
		open,
		nodeId,
		placement: "bottom-start",
		onOpenChange: setOpen,
		middleware: [flip(), shift()],
		whileElementsMounted: autoUpdate,
	});

	const { getReferenceProps } = useInteractions([useClick(context)]);

	return (
		<Context.Provider
			value={{
				x,
				y,
				open,
				setOpen,
				role,
				refs,
				context,
				nodeId,
				strategy,
				getReferenceProps,
				menuOverflowStrategy,
				globalMenuOptions: {
					closeMenuOnClick: true,
					...globalMenuOptions,
				},
			}}
		>
			{children}
		</Context.Provider>
	);
};
