import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { useTranslation } from "react-i18next";
import { Button } from "../../common/components/atoms/button/Button";
import {
	useActivateDataLayerMutation,
	useChangeCompanyTypeMutation,
	useDeactivateDataLayerMutation,
	useGetCompanyQuery,
	useGetDataLayerStatusQuery,
	useRecreatePipelinesMutation,
} from "../../common/redux/api/exopenApi";
import { useParams } from "react-router-dom";
import { invariant } from "../../common/utils/invariant";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { IconCopy, IconHammer } from "@tabler/icons-react";
import { useCurrentCompanyDomain } from "../../common/company-domain/useCurrentCompanyDomain";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import { Select } from "../../common/components/atoms/select/Select.tsx";
import { companyTypes } from "../companyAdministration/companyTypes.ts";
import { useId } from "react";
import { Label } from "../../common/components/atoms/label/Label.tsx";

export const SupportTools = () => {
	const { t } = useTranslation();
	const { companyDomain } = useCurrentCompanyDomain();

	const { data: company, isLoading: isLoadingCompany } = useGetCompanyQuery({
		companyId: companyDomain.id,
	});
	const [changeCompanyType, { isLoading: isChangingCompanyType }] =
		useChangeCompanyTypeMutation();

	const createConnectionString = () => {
		if (
			!company ||
			!company.company.name ||
			!company.company.pumpDatabaseInstance ||
			!company.company.databaseName
		)
			return "";
		const template =
			"Data Source={HOST};Initial Catalog={DB};User ID={USR};Password={PSW}";
		return template
			.replace("{HOST}", company.company.pumpDatabaseInstance)
			.replace("{DB}", company.company.databaseName);
	};

	const copyToClipboard = (text: string) => {
		const input = document.createElement("input");
		input.setAttribute("value", text);
		document.body.appendChild(input);
		input.select();
		const result = document.execCommand("copy");
		document.body.removeChild(input);
		return result;
	};

	const { companyId } = useParams();
	invariant(companyId);
	const [activateConsolidationMart, { isLoading: isActivatingDataLayer }] =
		useActivateDataLayerMutation();
	const [deactivateConsolidationMart, { isLoading: isDeactivatingDataLayer }] =
		useDeactivateDataLayerMutation();
	const [recreatePipelines, { isLoading: isRecreatingPipelines }] =
		useRecreatePipelinesMutation();
	const { data: dataLayerStatus, isLoading: isLoadingDataLayerStatus } =
		useGetDataLayerStatusQuery(companyId);
	const companyTypeLabelId = useId();

	if (isLoadingDataLayerStatus || isLoadingCompany) {
		return <LoadingState />;
	}

	if (!company || dataLayerStatus === undefined) {
		return <MissingDataState />;
	}

	return (
		<>
			<h4 className="mb-4">{t("Support Tools")}</h4>
			<div className="flex flex-wrap gap-4">
				<Button
					onClick={() => {
						return copyToClipboard(createConnectionString());
					}}
					icon={<IconCopy />}
				>
					{t("Copy the database connection string")}
				</Button>
				<Button
					icon={<IconHammer />}
					isLoading={isRecreatingPipelines}
					onClick={() => {
						recreatePipelines({ companyId: companyDomain.id });
					}}
				>
					{t("Recreate Pipelines")}
				</Button>
				{dataLayerStatus ? (
					<Button
						onClick={() => {
							deactivateConsolidationMart(companyId);
						}}
						isLoading={isDeactivatingDataLayer}
						disabled={isDeactivatingDataLayer}
					>
						Deactivate DataLayer
					</Button>
				) : (
					<Button
						onClick={() => {
							activateConsolidationMart(companyId);
						}}
						isLoading={isActivatingDataLayer}
						disabled={isActivatingDataLayer}
					>
						Activate DataLayer
					</Button>
				)}
				<div style={{ display: "flex", alignItems: "flex-start" }}>
					<div>
						<Label id={companyTypeLabelId}>{t("Type")}</Label>
						<Select
							placeholder={t("Select type")}
							aria-labelledby={companyTypeLabelId}
							onChange={async (value) => {
								if (value) {
									const result = await changeCompanyType({
										companyDomainId: companyDomain.id,
										type: value,
									});
									if ("error" in result) {
										showErrorNotification({
											message: t("The changes couldn't be saved"),
										});
									} else {
										showSuccessNotification({
											message: t("The changes are saved"),
										});
									}
								}
							}}
							value={company.company.type}
							disabled={isChangingCompanyType}
							options={companyTypes}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
