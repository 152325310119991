import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { Navigate } from "react-router-dom";
import { Card } from "../planning/components/Card";
import { useTranslation } from "react-i18next";
import { Text } from "../../common/components/atoms/typography/Text";
import { IconExternalLink } from "@tabler/icons-react";
import { Companies } from "../companyAdministration/Companies";
import { useUser } from "../../common/context/userContextUtils";
import { ButtonLink } from "../../common/components/atoms/button/ButtonLink.js";
import { useGetAllCompaniesQuery } from "src/common/redux/api/exopenApi.js";
import { QueryResult } from "components/queryResult/QueryResult.js";

export const Home = () => {
	const { t } = useTranslation();
	const { user } = useUser();

	return (
		<QueryResult
			query={useGetAllCompaniesQuery()}
			render={(companies) => {
				if (!user) {
					return <LoadingState />;
				}

				if (user.superAdmin) {
					return (
						<div className="mx-auto max-w-[1920px] p-4">
							<Companies />
						</div>
					);
				}

				if (companies.length === 0) {
					return (
						<Card className="mx-auto mt-10 max-w-[400px] space-y-4 p-4">
							<Text size="md">
								{t(
									"Your account is not connected to any company, please contact support to get started",
								)}
							</Text>
							<ButtonLink
								reloadDocument
								to="https://www.exopen.se/kontakta-oss"
								target="_blank"
								icon={<IconExternalLink />}
								iconPosition="right"
							>
								{t("Contact support")}
							</ButtonLink>
						</Card>
					);
				}

				return <Navigate to={`/${companies[0].id}`} />;
			}}
		/>
	);
};
