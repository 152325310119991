import { IconDatabase } from "@tabler/icons-react";
import {
	type FeaturedIconSize,
	type FeaturedIconTheme,
	type FeaturedIconColor,
	FeaturedIcon,
} from "../../../common/components/atoms/featuredIcon/FeaturedIcon";

const sizes: FeaturedIconSize[] = ["xs", "sm", "md", "lg", "xl"];
const themes: FeaturedIconTheme[] = [
	"light_circle",
	"light_circle_outline",
	"dark_circle",
	"light_square",
	"mid_square",
	"dark_square",
];
const colors: FeaturedIconColor[] = ["primary", "gray", "error", "warning"];

export const FeaturedIconPresentation = () => {
	const components: JSX.Element[] = [];
	for (const theme of themes) {
		for (const color of colors) {
			if (
				(theme === "dark_square" ||
					theme === "mid_square" ||
					theme === "light_square") &&
				color !== "primary"
			) {
				continue;
			}
			for (const size of sizes) {
				components.push(
					<FeaturedIcon theme={theme} color={color} size={size}>
						<IconDatabase />
					</FeaturedIcon>,
				);
			}
		}
	}

	return (
		<div className="grid grid-cols-[repeat(5,min-content)] gap-2">
			{components}
		</div>
	);
};
