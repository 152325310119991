import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes.js";
import type { PaginatedParams, PaginatedResponse } from "../types.js";
import type {
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery.js";

export const loadAllPages = async <T>(
	loadPage: ({
		take,
		skip,
	}: PaginatedParams) => Promise<
		QueryReturnValue<
			PaginatedResponse<T>,
			FetchBaseQueryError,
			FetchBaseQueryMeta
		>
	>,
) => {
	const take = 10 * 1000;
	let hasMore = true;
	let skip = 0;
	const allItems: T[] = [];

	while (hasMore) {
		const result = await loadPage({ take, skip });

		if (result.error) {
			return result;
		}

		const page = result.data;

		allItems.push(...page.items);
		hasMore = take <= page.items.length;
		skip += page.items.length;
	}

	return {
		data: allItems,
	};
};
