import { useTranslation } from "react-i18next";
import { DlmMarts } from "./dlmMarts";
import { Button } from "components/button";
import { IconFileExport } from "@tabler/icons-react";
import { useState } from "react";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import {
	useForceWriteParquetDlmFinanceMutation,
	useForceWriteParquetDlmRawMutation,
} from "src/common/redux/api/exopenApi";
import { ConfirmDialog } from "components/dialog/ConfirmDialog";

interface Props {
	companyDomainId: string;
	integrationOrLegalEntityId: string;
	mart: DlmMarts;
	disabled?: boolean;
}

export const ForceWriteParquetDlmButton = ({
	companyDomainId,
	integrationOrLegalEntityId,
	mart,
	disabled,
}: Props) => {
	const { t } = useTranslation();

	const [confirmModalOpen, setConfirmModalOpen] = useState(false);

	const [forceWriteParquetDlmRaw, { isLoading: isWritingParquetRaw }] =
		useForceWriteParquetDlmRawMutation();

	const [forceWriteParquetDlmFinance, { isLoading: isWritingParquetFinance }] =
		useForceWriteParquetDlmFinanceMutation();

	const forceWriteParquet = async () => {
		const result =
			mart === DlmMarts.Finance
				? forceWriteParquetDlmFinance({
						companyDomainId,
						legalEntityId: integrationOrLegalEntityId,
					})
				: forceWriteParquetDlmRaw({
						companyDomainId,
						integrationId: integrationOrLegalEntityId,
					});

		await result;

		if ("error" in result) {
			showErrorNotification({
				message: t("Something failed..."),
			});
		} else {
			showSuccessNotification({
				message: t("Parquet file successfully written"),
			});
		}
	};

	return (
		<>
			<Button
				ariaLabel={t("Force write parquet")}
				icon={<IconFileExport />}
				disabled={isWritingParquetRaw || isWritingParquetFinance || disabled}
				variant="ghost"
				onClick={() => setConfirmModalOpen(true)}
			/>
			<ConfirmDialog
				open={confirmModalOpen}
				onClose={() => setConfirmModalOpen(false)}
				onConfirm={forceWriteParquet}
				isLoading={isWritingParquetRaw || isWritingParquetFinance}
				title={t("Force write parquet")}
				confirmButtonLabel={t("Force write parquet")}
				description={
					mart === DlmMarts.Finance
						? t(
								"Are you sure you want to force write parquet for this legal entity?",
							)
						: t(
								"Are you sure you want to force write parquet for this integration?",
							)
				}
			/>
		</>
	);
};
