import { useState, type ReactNode } from "react";
import {
	autoUpdate,
	flip,
	offset,
	shift,
	useFloating,
	useFloatingNodeId,
} from "@floating-ui/react";
import { usePageWidth } from "src/common/utils/usePageWidth";
import { MenuComponent } from "./MenuComponent";

type SubMenuProps = {
	label: string;
	displayLabel?: ReactNode;
	disabled?: boolean;
	children?: ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	subMenuClassName?: string;
	className?: string;
	preMenuItems?: ReactNode;
};

export const SubMenu = ({ onClick, ...props }: SubMenuProps) => {
	const nodeId = useFloatingNodeId();
	const [open, setOpen] = useState(false);
	const pageWidth = usePageWidth();

	const { refs, ...rest } = useFloating({
		open,
		nodeId,
		placement: "right-start",
		onOpenChange: setOpen,
		middleware: [
			offset({
				mainAxis: pageWidth < 400 ? -100 : -10,
			}),
			flip(),
			shift(),
		],
		whileElementsMounted: autoUpdate,
	});

	if (
		!props.children ||
		(Array.isArray(props.children) && props.children.length === 0)
	) {
		return null;
	}

	return (
		<MenuComponent
			isNested={true}
			ref={refs.setFloating}
			nodeId={nodeId}
			open={open}
			setOpen={setOpen}
			setReference={refs.setReference}
			onMenuClick={onClick}
			{...rest}
			{...props}
		/>
	);
};
