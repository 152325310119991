import {
	forwardRef,
	useState,
	useRef,
	useEffect,
	type Dispatch,
	type SetStateAction,
	type ReactNode,
	type Ref,
} from "react";
import {
	useFloatingParentNodeId,
	useFloatingTree,
	useHover,
	safePolygon,
	useRole,
	useClick,
	useDismiss,
	FloatingNode,
	useInteractions,
	useListNavigation,
	FloatingFocusManager,
	FloatingList,
	FloatingPortal,
	FloatingOverlay,
	type FloatingContext,
	type Strategy,
} from "@floating-ui/react";
import { SubMenuButton } from "./MenuButton";
import { clsx } from "clsx";
import { VirtualChildren } from "src/features/planning/components/VirtualChildren";
import type { MenuOverflowStrategy } from "./MenuProvider";
import { ROW_HEIGHT } from "src/features/planning/scenario/common/constants";
import { zIndicies } from "src/common/utils/zIndicies.js";
import { useMenuContext } from "./use-menu-context.js";
import {
	MenuComponentContext,
	useMenuComponentContext,
} from "./useMenuComponentContext.js";

const OverflowComponent = ({
	strategy,
	children,
}: {
	strategy: MenuOverflowStrategy;
	children: ReactNode;
}) => {
	switch (strategy) {
		case "scroll":
			return <div className="overflow-y-auto">{children}</div>;
		case "virtualize":
			return (
				<VirtualChildren nodeHeight={ROW_HEIGHT}>{children}</VirtualChildren>
			);
		case "none":
		default:
			return children;
	}
};

type _MenuComponentProps = {
	nodeId: string;
	context: FloatingContext;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	children?: ReactNode;
	strategy: Strategy;
	x: number | null;
	y: number | null;
	isNested: boolean;
	className?: string | undefined;
	preMenuItems?: ReactNode;
};

type MenuComponentProps = {
	isNested: false;
	label?: never;
	displayLabel?: never;
	disabled?: never;
	setReference?: never;
	onMenuClick?: never;
	subMenuClassName?: never;
} & _MenuComponentProps;

type SubMenuComponentProps = {
	isNested: true;
	label: string;
	displayLabel?: ReactNode;
	disabled?: boolean | undefined;
	setReference: Ref<HTMLElement>;
	onMenuClick?:
		| ((event: React.MouseEvent<HTMLButtonElement>) => void)
		| undefined;
	subMenuClassName?: string | undefined;
} & _MenuComponentProps;

export const MenuComponent = forwardRef<
	HTMLDivElement,
	MenuComponentProps | SubMenuComponentProps
>(
	(
		{
			nodeId,
			isNested,
			context,
			open,
			setOpen,
			children,
			strategy,
			x,
			y,
			label,
			displayLabel,
			disabled,
			setReference,
			onMenuClick,
			subMenuClassName,
			className,
			preMenuItems,
		},
		ref,
	) => {
		const elementsRef = useRef<Array<HTMLButtonElement | null>>([]);
		const labelsRef = useRef<Array<string | null>>([]);

		const [hasFocusInside, setHasFocusInside] = useState(false);
		const [activeIndex, setActiveIndex] = useState<number | null>(null);

		const tree = useFloatingTree();
		const parentId = useFloatingParentNodeId();
		const parent = useMenuComponentContext();
		const {
			globalMenuOptions,
			role: menuRole,
			menuOverflowStrategy,
		} = useMenuContext();

		const hover = useHover(context, {
			delay: {
				open: 0,
				close: 50,
			},
			enabled: !hasFocusInside && isNested,
			handleClose: safePolygon({
				blockPointerEvents: true,
				requireIntent: false,
				buffer: 1,
			}),
		});

		const click = useClick(context, {
			event: "click",
			ignoreMouse: isNested,
		});

		const role = useRole(context, {
			...(menuRole ? { role: menuRole } : {}),
		});

		const dismiss = useDismiss(context, { bubbles: true });

		const listNavigation = useListNavigation(context, {
			listRef: elementsRef,
			activeIndex,
			nested: isNested,
			onNavigate: setActiveIndex,
		});

		const { getFloatingProps, getItemProps, getReferenceProps } =
			useInteractions([hover, click, role, dismiss, listNavigation]);

		useEffect(() => {
			if (!tree) return;

			function handleTreeClick() {
				if (!globalMenuOptions.closeMenuOnClick) return;
				setOpen(false);
			}

			function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
				if (event.nodeId !== nodeId && event.parentId === parentId) {
					setOpen(false);
				}
			}

			tree.events.on("click", handleTreeClick);
			tree.events.on("menuopen", onSubMenuOpen);

			return () => {
				tree.events.off("click", handleTreeClick);
				tree.events.off("menuopen", onSubMenuOpen);
			};
		}, [tree, nodeId, parentId, globalMenuOptions.closeMenuOnClick, setOpen]);

		useEffect(() => {
			if (open && tree) {
				tree.events.emit("menuopen", { parentId, nodeId });
			}
		}, [tree, open, nodeId, parentId]);

		return (
			<MenuComponentContext.Provider
				value={{
					hasFocusInside,
					setHasFocusInside,
					activeIndex,
					setActiveIndex,
					isOpen: open,
					getItemProps,
					elementsRef: elementsRef.current,
					getReferenceProps: useInteractions([listNavigation])
						.getReferenceProps,
				}}
			>
				<FloatingNode id={nodeId}>
					{isNested && (
						<SubMenuButton
							label={label}
							displayLabel={displayLabel}
							disabled={disabled}
							ref={setReference}
							className={clsx("block w-full p-1 text-left", subMenuClassName)}
							data-open={open ? "" : undefined}
							data-nested=""
							data-focus-inside={hasFocusInside ? "" : undefined}
							onMenuClick={onMenuClick}
							{...getReferenceProps(
								parent.getItemProps({
									onFocus(_event: React.FocusEvent<HTMLButtonElement>) {
										setHasFocusInside(false);
										parent.setHasFocusInside(true);
									},
								}),
							)}
						/>
					)}
					<FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
						{open && (
							<FloatingPortal>
								{!isNested && <FloatingOverlay lockScroll />}
								<FloatingFocusManager
									context={context}
									modal={false}
									initialFocus={isNested ? -1 : 0}
									returnFocus={!isNested}
								>
									<div
										role="menu"
										ref={ref}
										{...getFloatingProps({
											style: {
												position: strategy,
												top: y ?? 0,
												left: x ?? 0,
												maxHeight: globalMenuOptions.maxHeight ?? 500,
												maxWidth: globalMenuOptions.maxWidth,
												minWidth: globalMenuOptions.minWidth ?? 300,
												minHeight: globalMenuOptions.minHeight,
											},
											className: clsx(
												"border border-grey-300 overflow-hidden flex gap-4 flex-col bg-white rounded-[8px] shadow-[0px_4px_6px_-2px_rgba(16,24,40,0.05),0px_12px_16px_-4px_rgba(16,24,40,0.1)]",
												zIndicies.TOOLTIP,
												className,
												globalMenuOptions.className,
											),
										})}
									>
										{preMenuItems && <div>{preMenuItems}</div>}
										<OverflowComponent strategy={menuOverflowStrategy}>
											{children}
										</OverflowComponent>
									</div>
								</FloatingFocusManager>
							</FloatingPortal>
						)}
					</FloatingList>
				</FloatingNode>
			</MenuComponentContext.Provider>
		);
	},
);

MenuComponent.displayName = "MenuComponent";
