import { showErrorNotification } from "components/notifications/events";
import { useEffect, useState } from "react";
import {
	useCreateCompanyMutation,
	useGetAllCompaniesQuery,
} from "src/common/redux/api/exopenApi";
import { useTranslation } from "react-i18next";
import fortnoxLogo from "./fortnox-logo.png";
import { AddDataSourcePage } from "../../confirmConnector/AddDataSourcePage";
import { type SubmitHandler, useForm } from "react-hook-form";
import {
	getDefaultValuesForAddDataSource,
	type AddDataSourceInputs,
} from "../../confirmConnector/schema";
import { AddDataSourceNavigation } from "../../confirmConnector/AddDataSourceNavigation";
import { AddDataSourceCompanyDomainSection } from "../../confirmConnector/AddDataSourceCompanyDomainSection";
import { Dialog } from "components/dialog/Dialog";
import { DialogContent } from "components/dialog/DialogContent";
import { FortnoxConnectorDialogContent } from "./AddFortnoxConnectorModal";
import { FormSection } from "src/features/planning/components/formSection/FormSection";
import { QueryResult } from "../../../../common/components/atoms/queryResult/QueryResult.tsx";

export const ActivateFortnoxPage = () => {
	const { t } = useTranslation();

	const companyDomainsQuery = useGetAllCompaniesQuery();
	const form = useForm({
		defaultValues: getDefaultValuesForAddDataSource("add"),
	});

	useEffect(() => {
		if (companyDomainsQuery.data) {
			form.reset(
				getDefaultValuesForAddDataSource(
					companyDomainsQuery.data.length === 0 ? "new" : "add",
				),
			);
		}
	}, [companyDomainsQuery.data, form.reset]);

	const operation = form.watch("operation");

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [companyDomainId, setCompanyDomainId] = useState<string | null>(null);

	const [createCompany] = useCreateCompanyMutation();

	const submitHandler: SubmitHandler<AddDataSourceInputs> = async (data) => {
		if (data.operation === "add" && data.companyDomainId) {
			setCompanyDomainId(data.companyDomainId);
			setModalIsOpen(true);
			return;
		}

		if (data.operation === "new" && data.companyDomainName) {
			const result = await createCompany({
				name: data.companyDomainName,
				type: "Kund",
			});

			if ("error" in result) {
				showErrorNotification({
					message: t("Something failed..."),
				});
				return;
			}

			setCompanyDomainId(result.data.id.toString());
			setModalIsOpen(true);
		}
	};

	return (
		<QueryResult
			query={companyDomainsQuery}
			render={(companyDomains) => {
				return (
					<form onSubmit={form.handleSubmit(submitHandler)}>
						<AddDataSourcePage
							title={t("Add {{name}} as a data source", { name: "Fortnox" })}
							logo={fortnoxLogo}
						>
							<AddDataSourceNavigation
								form={form}
								onSubmit
								disableOnSubmit={
									!form.watch("approve") ||
									(operation === "new" && !form.watch("companyDomainName")) ||
									(operation === "add" && !form.watch("companyDomainId"))
								}
							>
								<AddDataSourceCompanyDomainSection
									form={form}
									showWhatHappensNext={false}
									companyDomains={companyDomains}
								/>

								<FormSection title={t("What happens next?")}>
									<ol className="list-decimal">
										{operation === "add" ? (
											<li>
												{t(
													"The data source will be added to the {{name}} company domain",
													{
														name: t("selected"),
													},
												)}
											</li>
										) : (
											<>
												<li>
													{t(
														"A new company domain will be created, this will take a minute or two",
													)}
												</li>
												<li>
													{t(
														"The data source will be added to the newly created company domain",
													)}
												</li>
											</>
										)}
										<li>
											{t(
												"A datarun will be started, it will take between thirty minutes to a couple of hours to complete depending on the source system and data size",
											)}
										</li>
									</ol>
								</FormSection>
							</AddDataSourceNavigation>

							<Dialog
								open={modalIsOpen && !!companyDomainId}
								onClose={() => setModalIsOpen(false)}
							>
								<DialogContent size="md">
									{companyDomainId && (
										<FortnoxConnectorDialogContent
											companyDomainId={companyDomainId}
										/>
									)}
								</DialogContent>
							</Dialog>
						</AddDataSourcePage>
					</form>
				);
			}}
		/>
	);
};
