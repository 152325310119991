import type { useInteractions } from "@floating-ui/react";
import {
	type Dispatch,
	type HTMLProps,
	type SetStateAction,
	useContext,
	createContext,
} from "react";

type MenuComponentContext = {
	hasFocusInside: boolean;
	setHasFocusInside: Dispatch<SetStateAction<boolean>>;
	activeIndex: number | null;
	setActiveIndex: Dispatch<SetStateAction<number | null>>;
	getReferenceProps: (
		userProps?: HTMLProps<Element> | undefined,
	) => Record<string, unknown>;
	isOpen: boolean;
	elementsRef: (HTMLButtonElement | null)[];
	getItemProps: ReturnType<typeof useInteractions>["getItemProps"];
};

export const MenuComponentContext = createContext<MenuComponentContext>(
	{} as MenuComponentContext,
);

export const useMenuComponentContext = () => {
	const context = useContext(MenuComponentContext);
	return context;
};
