import {
	arrow,
	autoUpdate,
	flip,
	offset,
	type Placement,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useRole,
	safePolygon,
} from "@floating-ui/react";
import { type MutableRefObject, useState } from "react";
import { maybe } from "../../../utils/maybe.ts";

interface UseTooltip {
	placement: Placement;
	closeDelay: number;
	openDelay: number;
	offset: number;
	arrowElement: MutableRefObject<HTMLElement | null>;
	defaultOpen?: boolean | undefined;
	closeWhenClickingOnReference?: boolean | undefined;
}

export function useTooltip(settings: UseTooltip) {
	const [uncontrolledOpened, setUncontrolledOpened] = useState(
		settings.defaultOpen ?? false,
	);

	const { x, y, refs, context, placement, middlewareData } = useFloating({
		placement: settings.placement,
		open: uncontrolledOpened,
		onOpenChange: setUncontrolledOpened,
		middleware: [
			offset(8),
			shift({ padding: 8 }),
			flip(),
			arrow({ element: settings.arrowElement }),
		],
		whileElementsMounted: autoUpdate,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([
		useHover(context, {
			delay: { open: settings.openDelay, close: settings.closeDelay },
			handleClose: safePolygon(),
			...maybe(settings.closeWhenClickingOnReference !== undefined, {
				move: false,
			}),
		}),
		useFocus(context, { visibleOnly: true }),
		useRole(context, { role: "tooltip" }),
		useDismiss(context, {
			escapeKey: false,
			outsidePress: true,
			ancestorScroll: true,
			referencePress: settings.closeWhenClickingOnReference ? true : false,
		}),
	]);

	return {
		x,
		y,
		reference: refs.setReference,
		floating: refs.setFloating,
		getFloatingProps,
		getReferenceProps,
		opened: uncontrolledOpened,
		placement,
		middlewareData,
	};
}
