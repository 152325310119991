import { clsx } from "clsx";
import type { FeaturedIconProps } from "components/featuredIcon/FeaturedIcon.js";
import { Text } from "components/typography/Text.js";
import { type ReactElement, type ReactNode, cloneElement } from "react";
import { useDialogContext } from "./useDialogContext";

interface DialogTitleProps {
	children?: ReactNode | undefined;
	icon?: ReactElement<FeaturedIconProps>;
	sideContent?: ReactNode;
}

export const DialogTitle = ({
	children,
	icon,
	sideContent,
}: DialogTitleProps) => {
	const { labelId } = useDialogContext();
	return (
		<div className="flex p-6 pb-3">
			<div>
				{icon &&
					cloneElement(icon, { className: clsx(icon.props.className, "mb-4") })}
				<Text size="lg" weight="bold" component="h2" id={labelId}>
					{children}
				</Text>
			</div>
			<div className="grow" />
			<div>{sideContent}</div>
		</div>
	);
};
