import {
	YearMonthPicker,
	type YearMonthPickerValue,
} from "components/yearMonthPicker/YearMonthPicker";
import { useState } from "react";
import { Text } from "../../../common/components/atoms/typography/Text";

export const YearMonthPickerPresentation = () => {
	const [value, setValue] = useState<YearMonthPickerValue>(null);
	const [rangeValue, setRangeValue] = useState<YearMonthPickerValue>(null);

	return (
		<>
			<section>
				<Text size="sm" weight="bold" className="mb-4">
					YearMonthPicker
				</Text>
				<Text size="sm" className="mb-2">
					Button
				</Text>
				<YearMonthPicker
					placeholder="Select a date"
					onChange={setValue}
					value={value}
					ariaLabel="Date"
				/>
			</section>
			<section>
				<Text size="sm" weight="bold" className="mb-4">
					Range picker
				</Text>
				<div className="pb-4">
					<YearMonthPicker
						placeholder="Select a date"
						onChange={setRangeValue}
						value={rangeValue}
						range
						ariaLabel="Period"
					/>
				</div>
			</section>
			<section>
				<Text size="sm" weight="bold" className="mb-4">
					Invalid state
				</Text>
				<YearMonthPicker
					placeholder="Select a date"
					onChange={setValue}
					value={value}
					ariaInvalid
					ariaLabel="Date"
				/>
			</section>
		</>
	);
};
