import { useEffect } from "react";

const siteName = "Exopen";
const suffix = ` · ${siteName}`;

export const useDocumentTitle = (title?: string) => {
	useEffect(() => {
		if (title === undefined) {
			return;
		}

		if (title === "") {
			document.title = siteName;
		} else {
			document.title = title + suffix;
		}
	}, [title]);
};
