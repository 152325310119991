import { addMonths, format, isAfter, startOfMonth, subYears } from "date-fns";
import {
	dateFromYearMonth,
	dateToYearMonth,
} from "components/yearMonthPicker/yearMonthUtils.js";
import type { FormatAmountVariant } from "./utils/amountFormat/AmountFormatContext.js";
import type { YearMonth } from "components/yearMonthPicker/YearMonthPicker.js";

export const STABLE_EMPTY_ARRAY = [] satisfies unknown;

export const formatYYYYMMDD = (date: Date | YearMonth) => {
	if ("year" in date) {
		return `${date.year}-${`${date.month + 1}`.padStart(2, "0")}-01`;
	}
	return format(date, "yyyy-MM-dd");
};

export function roundToNearestNthDecimal(value: number, precision: number) {
	const multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
}

export const formatVariantToDecimals: Record<FormatAmountVariant, number> = {
	exact: 2,
	thousands: 5,
};

export const numberFormatter = new Intl.NumberFormat("sv-SE");

export const numberFormatterNoFractions = new Intl.NumberFormat("sv-SE", {
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
});

const numberFormatterTkrFractions = new Intl.NumberFormat("sv-SE", {
	maximumFractionDigits: 5,
	minimumFractionDigits: 0,
});

const numberFormatterKrFractions = new Intl.NumberFormat("sv-SE", {
	maximumFractionDigits: 2,
	minimumFractionDigits: 0,
});

interface NumberFormatOptions {
	format?: FormatAmountVariant;
	noConversion?: boolean;
	noFractions?: boolean;
}

export const makeNumberFormatter =
	(format: FormatAmountVariant) =>
	(value: number, options?: NumberFormatOptions) => {
		const formatVariant = options?.format ?? format;

		let convertedValue = value;
		if (!options?.noConversion) {
			if (formatVariant === "thousands") {
				convertedValue = value / 1000;
			}
		}

		if (options?.noFractions) {
			return numberFormatterNoFractions.format(convertedValue);
		} else if (formatVariant === "thousands") {
			return numberFormatterTkrFractions.format(convertedValue);
		} else {
			return numberFormatterKrFractions.format(convertedValue);
		}
	};

export const getMonthsBetween = (from: Date, to: Date) => {
	const months = [startOfMonth(from)];

	while (true) {
		const prevMonth = months[months.length - 1];
		const nextMonth = addMonths(prevMonth, 1);
		if (isAfter(nextMonth, to)) {
			break;
		}
		months.push(new Date(nextMonth));
	}
	return months;
};

export const getMonthsBetweenAsYearMonth = (from: Date, to: Date) => {
	const months = [dateToYearMonth(from)];

	while (true) {
		const prevMonth = dateFromYearMonth(months[months.length - 1]);
		const nextMonth = addMonths(prevMonth, 1);
		if (isAfter(nextMonth, to)) {
			break;
		}
		months.push(dateToYearMonth(nextMonth));
	}
	return months;
};

export function getPreviousYearFromDate(planFrom: string, actualsFrom: string) {
	const planFromDate = new Date(planFrom);
	const actualsFromDate = new Date(actualsFrom);

	const minDate =
		planFromDate < actualsFromDate ? planFromDate : actualsFromDate;

	const oneYearBeforeMinDate = subYears(minDate, 1);

	return oneYearBeforeMinDate;
}
