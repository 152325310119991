import { type ReactNode, forwardRef } from "react";
import { useFloatingTree, useListItem, useMergeRefs } from "@floating-ui/react";
import { MenuButton, type MenuButtonProps } from "./MenuButton";
import { useMenuComponentContext } from "./useMenuComponentContext.js";

type MenuItemProps = {
	label: string;
	displayLabel?: ReactNode;
} & MenuButtonProps;

export const MenuItem = forwardRef<HTMLElement, MenuItemProps>(
	({ label, disabled, displayLabel, ...props }, forwardedRef) => {
		const menu = useMenuComponentContext();
		const item = useListItem();
		const tree = useFloatingTree();

		return (
			<MenuButton
				{...props}
				ref={useMergeRefs([item.ref, forwardedRef])}
				tabIndex={
					item.index === menu.activeIndex || menu.activeIndex === null ? 0 : -1
				}
				disabled={disabled}
				{...menu.getItemProps({
					onClick(event: React.MouseEvent<HTMLButtonElement>) {
						props.onClick?.(event);
						tree?.events.emit("click");
					},
					onFocus(event: React.FocusEvent<HTMLButtonElement>) {
						props.onFocus?.(event);
						menu.setHasFocusInside(true);
					},
				})}
			>
				{displayLabel ?? label}
			</MenuButton>
		);
	},
);

MenuItem.displayName = "MenuItem";
