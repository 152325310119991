import { useState } from "react";
import { IconHelp, IconMenu, IconSettings } from "@tabler/icons-react";
import { Button } from "components/button";
import { PopoverMenuItem } from "components/popoverMenu/menu/PopoverMenuItem.tsx";
import { Popover } from "components/popoverMenu/Popover";
import { PopoverMenu } from "../../../common/components/atoms/popoverMenu/menu/PopoverMenu.tsx";

export const FloatingMenuPresentation = () => {
	const [text, setText] = useState<string | null>(null);

	return (
		<div>
			<p className="mb-2">{text ?? "Select something from the menu"}</p>
			<Popover role="menu" placement="bottom-start">
				<Popover.Trigger>
					<Button icon={<IconMenu />} ariaLabel="Menu" />
				</Popover.Trigger>
				<Popover.Content>
					<PopoverMenu>
						<PopoverMenuItem
							key="help"
							label="Help"
							icon={<IconHelp />}
							onClick={() => setText("You need help? Call Poolia...")}
						/>
						<PopoverMenuItem
							key="help"
							label="Settings"
							icon={<IconSettings />}
							onClick={() => setText("Settings? What settings?")}
						/>
					</PopoverMenu>
				</Popover.Content>
			</Popover>
		</div>
	);
};
