import { IconCirclePlus } from "@tabler/icons-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import {
	useGetCompanyQuery,
	useGetPumpTypesQuery,
} from "../../common/redux/api/exopenApi";
import { invariant } from "../../common/utils/invariant";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";
import { FakePumpModal } from "./FakePumpModal";
import { Button } from "../../common/components/atoms/button/Button.tsx";

export const FakePumps = () => {
	const { companyId } = useParams();
	invariant(companyId);
	const { data: pumpTypes, isLoading: isLoadingPumpTypes } =
		useGetPumpTypesQuery();
	const { data: company, isLoading: isLoadingCompany } = useGetCompanyQuery({
		companyId,
	});

	const [pump, setPump] = useState<string | null>(null);

	if (isLoadingPumpTypes || isLoadingCompany) {
		return <LoadingState />;
	}

	if (!pumpTypes || !company) {
		return <MissingDataState />;
	}

	const closeModal = () => {
		setPump(null);
	};

	return (
		<>
			<FakePumpModal pump={pump} onClose={closeModal} companyId={companyId} />
			<PlanningTableContainer>
				<PlanningTable>
					<thead>
						<tr>
							<th>Pump</th>
							<th className="w-0" />
						</tr>
					</thead>
					<tbody>
						{pumpTypes.map((pumpType) => {
							return (
								<tr key={pumpType}>
									<td>{pumpType}</td>
									<td>
										<Button
											variant="ghost"
											onClick={() => {
												setPump(pumpType);
											}}
											icon={<IconCirclePlus />}
											ariaLabel="Create pump"
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</PlanningTable>
			</PlanningTableContainer>
		</>
	);
};
