import { IconChevronRight, type IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	type ReactNode,
	useState,
	type ReactElement,
	cloneElement,
	useContext,
	useEffect,
} from "react";
import { Collapse } from "../../common/components/atoms/collapse/Collapse";
import { Text } from "../../common/components/atoms/typography/Text";
import { SidebarContext } from "./SidebarContext";
import { TooltipTrigger } from "../../common/components/atoms/tooltip2/TooltipTrigger.tsx";
import { TooltipContent } from "../../common/components/atoms/tooltip2/TooltipContent.tsx";
import { Tooltip2 } from "../../common/components/atoms/tooltip2/Tooltip2.tsx";

interface NavItemGroupProps {
	icon: ReactElement<IconProps>;
	label: string;
	children?: ReactNode | undefined;
}

export const NavItemGroup = ({ icon, label, children }: NavItemGroupProps) => {
	const [open, setOpen] = useState(false);
	const { open: sidebarOpen, toggleSidebar } = useContext(SidebarContext);

	useEffect(() => {
		if (open && !sidebarOpen) {
			setOpen(false);
		}
	}, [open, sidebarOpen]);

	const content = (
		<div>
			<button
				type="button"
				className="block w-full text-left"
				onClick={() => {
					if (!sidebarOpen) {
						toggleSidebar(true);
					}

					setOpen((prev) => {
						return !prev;
					});
				}}
				aria-label={!sidebarOpen ? label : undefined}
			>
				{sidebarOpen ? (
					<div className="flex items-center gap-4 rounded-[6px] px-[12px] py-[10px] hover:bg-grey-50">
						<div className="shrink-0">
							{cloneElement<IconProps>(icon, {
								size: 20,
								className: "text-grey-500",
							})}
						</div>
						<div className="grow">
							<Text
								size="sm"
								weight="medium"
								color={open ? "text-grey-900" : "text-grey-700"}
							>
								{label}
							</Text>
						</div>
						<div className="shrink-0">
							<IconChevronRight
								className={clsx(
									"text-purple-500 transition-transform duration-200",
									open && "rotate-90",
								)}
								size={20}
							/>
						</div>
					</div>
				) : (
					<div className="rounded-[6px] p-[10px] hover:bg-grey-50">
						{cloneElement<IconProps>(icon, {
							size: 20,
							className: "text-grey-500",
						})}
					</div>
				)}
			</button>
			<Collapse in={open} transitionDuration={!sidebarOpen ? 0 : undefined}>
				<div className="space-y-1 pt-1">{children}</div>
			</Collapse>
		</div>
	);

	if (!sidebarOpen) {
		return (
			<Tooltip2 placement="right">
				<TooltipTrigger asChild>{content}</TooltipTrigger>
				<TooltipContent>{label}</TooltipContent>
			</Tooltip2>
		);
	}
	return content;
};
