import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentCompanyDomain } from "../../../common/company-domain/useCurrentCompanyDomain.js";
import { Button } from "../../../common/components/atoms/button/Button.js";
import {
	Modal,
	ModalContent,
	ModalTitle,
	ModalActions,
} from "../../../common/components/atoms/modal/Modal.js";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../common/components/atoms/notifications/events.js";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";
import { QueryResult } from "../../../common/components/atoms/queryResult/QueryResult.js";
import {
	extractGroupsFromHierarchy,
	type GroupWithParent,
} from "../utils/extractGroupsFromHierarchy.js";
import { HierarchyGroupTable } from "./HierarchyGroupTable.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";

type Props = {
	items: LegalEntityEntity[];
	onClose: () => void;
};

export const MoveItemsModal = ({ items, onClose }: Props) => {
	const { companyDomain } = useCurrentCompanyDomain();

	const { t } = useTranslation();

	const {
		labels: { entityNamePlural },
		updateGroupMutation: [updateGroup, { isLoading }],
		useHierarchyQuery,
		parentLegalEntityId: legalEntityId,
		canHaveItems,
		type,
	} = useEntityHierarchy();

	const hierarchyQuery = useHierarchyQuery();
	const isOpen = items.length > 0;

	const [selected, setSelected] = useState<GroupWithParent | null>(null);

	useEffect(() => {
		if (!isOpen) {
			setSelected(null);
		}
	}, [isOpen]);

	return (
		<Modal open={isOpen} onClose={onClose} size="lg">
			<ModalTitle
				title={t("Move {{entityNamePlural}}", {
					entityNamePlural,
				})}
				description={t("Choose a group to move the {{entityNamePlural}} to", {
					entityNamePlural,
				})}
			/>
			<ModalContent>
				<QueryResult
					query={hierarchyQuery}
					render={(data) => {
						const groups = extractGroupsFromHierarchy(data.hierarchy).filter(
							canHaveItems,
						);

						return (
							<HierarchyGroupTable
								groups={groups}
								isSearchable
								isSelectable
								selected={selected}
								onSelect={setSelected}
							/>
						);
					}}
				/>
			</ModalContent>
			<ModalActions>
				<Button variant="secondaryGray" onClick={onClose}>
					{t("Cancel")}
				</Button>
				<Button
					variant="primary"
					isLoading={isLoading}
					disabled={selected === null}
					onClick={async () => {
						if (selected) {
							const result = await updateGroup({
								companyDomainId: companyDomain.id,
								legalEntityId,
								groupId: selected.id,
								type,
								group: {
									itemIds: [
										...selected.items.map((item) => item.id),
										...items.map((item) => item.id),
									],
								},
							});

							if ("error" in result) {
								showErrorNotification({
									message: t("Failed to move items to the group"),
								});
							} else {
								showSuccessNotification({
									message: t("Items moved to the group"),
								});
								onClose();
							}
						}
					}}
				>
					{t("Move")}
				</Button>
			</ModalActions>
		</Modal>
	);
};
