import { useTranslation } from "react-i18next";
import { Button } from "../../common/components/atoms/button/Button";
import {
	useGetAccessGroupsQuery,
	useInviteUserMutation,
	useUpdateUserMutation,
} from "../../common/redux/api/exopenApi";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import {
	Modal,
	ModalActions,
	ModalContent,
	ModalTitle,
} from "../../common/components/atoms/modal/Modal";
import { SwitchField } from "../../common/components/atoms/checkbox/SwitchField";
import { Label } from "../../common/components/atoms/label/Label";
import { Input } from "../../common/components/atoms/input/Input";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import type { User } from "../../common/service/Types/UserTypes";
import { useEffect } from "react";
import { getGroupName } from "./getGroupName.js";

interface Inputs {
	email: string;
	groups: number[];
}

interface InviteUserModalProps {
	modalOpen: boolean;
	handleClose: () => void;
	companyId: string;
	user?: User | undefined;
}

export const InviteUserModal = ({
	modalOpen,
	handleClose,
	companyId,
	user,
}: InviteUserModalProps) => {
	const { t } = useTranslation();
	const { data: accessGroups } = useGetAccessGroupsQuery(companyId);
	const [inviteUser] = useInviteUserMutation();
	const [editUser] = useUpdateUserMutation();

	const {
		handleSubmit,
		register,
		formState: { isSubmitting, isDirty },
		control,
		reset,
	} = useForm<Inputs>({
		defaultValues: { email: user?.email ?? "", groups: [] },
	});

	const onClose = () => {
		handleClose();
		reset({ email: "", groups: [] });
	};

	useEffect(() => {
		if (user) {
			reset({
				email: user.email,
				groups: user.accessGroups.map((group) => group.groupId),
			});
		}
	}, [reset, user]);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		const result = await (user
			? editUser({ companyId, accessGroups: data.groups, email: data.email })
			: inviteUser({
					email: data.email,
					accessGroups: data.groups,
					companyId,
				}));
		if ("error" in result) {
			showErrorNotification({
				message: user
					? t("The changes couldn't be saved")
					: t(`Error occurred when user was invited`),
			});
		} else {
			showSuccessNotification({
				message: user
					? t("The changes are saved")
					: t("An invite was sent to {{email}}", { email: data.email }),
			});
			onClose();
		}
	};

	return (
		<Modal open={modalOpen} onClose={onClose}>
			<ModalTitle title={user ? t("Edit user") : t("Invite users")} />
			<ModalContent>
				<form
					id="user-form"
					onSubmit={handleSubmit(onSubmit)}
					className="space-y-4"
				>
					<div>
						<Label htmlFor="invite-user-email-input">{t("Email")}</Label>
						<Input
							{...register("email")}
							required
							id="invite-user-email-input"
							type="email"
							disabled={user !== undefined}
						/>
					</div>
					<div>
						<Label>{t("Groups")}</Label>
						<div className="space-y-2">
							<Controller
								control={control}
								name="groups"
								render={({ field: { onChange, value, ...field } }) => {
									return (
										<>
											{(accessGroups ?? []).map((group) => (
												<div key={group.groupId}>
													<SwitchField
														{...field}
														checked={value.includes(group.groupId)}
														onChange={(event) => {
															if (event.target.checked) {
																onChange([...value, group.groupId]);
															} else {
																onChange(
																	value.filter((martId) => {
																		return martId !== group.groupId;
																	}),
																);
															}
														}}
														label={getGroupName({ groupName: group.name, t })}
													/>
												</div>
											))}
										</>
									);
								}}
							/>
						</div>
					</div>
				</form>
			</ModalContent>
			<ModalActions>
				<Button variant="secondaryGray" onClick={onClose}>
					{t("Cancel")}
				</Button>
				<Button
					form="user-form"
					type="submit"
					isLoading={isSubmitting}
					disabled={isSubmitting || !isDirty}
				>
					{user ? t("Save changes") : t("Send invite")}
				</Button>
			</ModalActions>
		</Modal>
	);
};
