import { useState } from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState.js";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState.js";
import {
	useGetTemplateAppsQuery,
	usePutTemplateAppsMutation,
} from "../../common/redux/api/exopenApi.js";
import { Button } from "../../common/components/atoms/button/Button.js";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../planning/components/PlanningTable.js";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events.js";
import { Label } from "../../common/components/atoms/label/Label.js";
import { DialogActions } from "../../common/components/atoms/dialog/DialogActions.js";
import { Dialog } from "../../common/components/atoms/dialog/Dialog.js";
import { DialogBody } from "../../common/components/atoms/dialog/DialogBody.js";
import { DialogContent } from "../../common/components/atoms/dialog/DialogContent.js";
import { DialogTitle } from "../../common/components/atoms/dialog/DialogTitle.js";
import type { TemplateApp } from "src/common/redux/api/exopenApiTypes.js";
import { Input } from "../../common/components/atoms/input/Input";
import { Anchor } from "components/anchor/Anchor.js";
import { ValidateHint } from "../planning/components/ValidateHint.js";

interface Inputs {
	url: string;
}

const extractUrlParams = (url: string) => {
	const urlSearchParams = new URLSearchParams(url);
	const appId = urlSearchParams.get("appId");
	const packageKey = urlSearchParams.get("packageKey");
	const tenantOwnerId = urlSearchParams.get("ownerId");

	return { appId, packageKey, tenantOwnerId };
};
export const TemplateApps = () => {
	const { data: templateApps, isLoading } = useGetTemplateAppsQuery();
	const [putTemplateApp] = usePutTemplateAppsMutation();

	const [templateApp, setTemplateApp] = useState<TemplateApp | null>(null);
	const { t } = useTranslation();
	const { control, handleSubmit, formState, reset } = useForm<Inputs>({
		defaultValues: { url: "" },
	});

	const closeModal = () => {
		setTemplateApp(null);
		reset({ url: "" });
	};

	if (isLoading) {
		return <LoadingState />;
	}

	if (!templateApps) {
		return <MissingDataState />;
	}

	const submitHandler: SubmitHandler<Inputs> = async (data) => {
		if (!templateApp) {
			return;
		}

		const { appId, packageKey, tenantOwnerId } = extractUrlParams(data.url);

		if (!appId || !packageKey || !tenantOwnerId) {
			showErrorNotification({
				message: "The url is missing required parameters",
			});
			return;
		}

		const result = await putTemplateApp({
			templateApp: {
				name: templateApp.name,
				appId,
				packageKey,
				ownerTenantId: tenantOwnerId,
			},
		});
		if ("error" in result) {
			showErrorNotification({ message: "Något gick fel" });
		} else {
			showSuccessNotification({ message: "Template appen uppdaterades" });
			closeModal();
		}
	};

	return (
		<>
			<Dialog open={templateApp !== null} onClose={closeModal}>
				<DialogContent>
					<DialogTitle>Update template app</DialogTitle>
					<DialogBody>
						<form
							id="update-template-app-form"
							onSubmit={handleSubmit(submitHandler)}
						>
							<Controller
								control={control}
								name="url"
								rules={{
									required: t("This field is required"),
								}}
								render={({ field, fieldState }) => (
									<>
										<Label htmlFor="url">Install url</Label>
										<Input
											id="url"
											{...field}
											hint="Example: https://app.powerbi.com/tenantId=123&appId=456&packageKey=789"
										/>
										{fieldState.error && (
											<ValidateHint error>
												{fieldState.error.message}
											</ValidateHint>
										)}
									</>
								)}
							/>
						</form>
					</DialogBody>
					<DialogActions withCancelButton>
						<Button
							type="submit"
							form="update-template-app-form"
							isLoading={formState.isSubmitting}
							disabled={!formState.isDirty}
						>
							{t("Update")}
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<PlanningTableContainer>
				<PlanningTable>
					<thead>
						<tr>
							<th>Namn</th>
							<th />
							<th className="w-0" />
						</tr>
					</thead>
					<tbody>
						{templateApps.map((ta) => {
							return (
								<tr key={ta.appId} className="text-nowrap text-sm">
									<td>
										<Anchor
											onClick={() => {
												setTemplateApp(ta);
											}}
										>
											{ta.name}
										</Anchor>
									</td>

									<td>
										<li>packageKey: {ta.packageKey}</li>
										<li>appId: {ta.appId}</li>
										<li>ownerTenantId: {ta.ownerTenantId}</li>
									</td>
								</tr>
							);
						})}
					</tbody>
				</PlanningTable>
			</PlanningTableContainer>
		</>
	);
};
