import FortnoxLogo from "./logos/fortnox.png";
import VismaLogo from "./logos/visma.png";
import TfsOfficeLogo from "./logos/tfsoffice.png";
import XledgerLogo from "./logos/xledger.png";
import BusinessCentralLogo from "./logos/business-central.png";
import MergeCrm from "./logos/mergecrm.jpeg";
import { Text } from "../../../../common/components/atoms/typography/Text";

type Props = {
	sourceSystemName: string;
};

export const SourceSystemImage = ({ sourceSystemName }: Props) => {
	switch (sourceSystemName.toLowerCase()) {
		case "businesscentral":
			return (
				<img
					src={BusinessCentralLogo}
					alt="Dynamics 365 Business Central logo"
					width="90"
				/>
			);
		case "fortnox":
			return <img src={FortnoxLogo} alt="Fortnox logo" width="90" />;
		case "vismanet":
			return <img src={VismaLogo} alt="Visma logo" width="90" />;
		case "tfsoffice":
			return <img src={TfsOfficeLogo} alt="24SevenOffice logo" width="90" />;
		case "xledger":
			return <img src={XledgerLogo} alt="Xledger logo" width="90" />;
		case "mergecrm":
			return <img src={MergeCrm} alt="Merge CRM logo" width="90" />;
		default:
			return (
				<Text size="sm" weight="regular" color="text-grey-500">
					{sourceSystemName}
				</Text>
			);
	}
};
