import { emptyFusionHubApi } from "src/common/service/fusionHub/emptyFusionHubApi";
import type {
	ErSyncStatus,
	FusionhubIntegration,
	MergeSystem,
	PaginatedResponse,
} from "src/common/service/fusionHub/types";

export type MergeCrmIntegration = {
	integration: FusionhubIntegration;
	system: MergeSystem;
};

const mergeCrmApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		getMergecrmSystems: builder.query<MergeSystem[], void>({
			query: () => "/integrations/mergecrm/systems",
		}),
		getMergecrmUrl: builder.mutation<
			{
				linkToken: string;
				integrationName?: string;
				magicLinkUrl?: string;
			},
			{ companyDomainId: string; system: string; createMagicLinkUrl: boolean }
		>({
			query: (body) => ({
				url: "/integrations/mergecrm/link-account/url",
				method: "POST",
				body,
			}),
		}),
		createMergecrmIntegration: builder.mutation<
			{ id: number },
			{ companyDomainId: string; linkToken: string; system: string }
		>({
			query: ({ companyDomainId, linkToken, system }) => ({
				url: "/integrations/mergecrm/link-account/create",
				method: "POST",
				body: { companyDomainId, linkToken, system },
			}),
		}),
		getMergecrmIntegrations: builder.query<
			PaginatedResponse<MergeCrmIntegration>,
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => ({
				url: "/integrations/mergecrm/integrations",
				params: { companyDomainId },
			}),
		}),
		getMergecrmLoadStatus: builder.query<
			{ status: ErSyncStatus | null },
			{ integrationId: number }
		>({
			query: ({ integrationId }) =>
				`/integrations/${integrationId}/mergecrm-raw/load-status`,
			providesTags: (result, error, { integrationId }) => [
				{ type: "SyncStatus", id: integrationId },
			],
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetMergecrmSystemsQuery,
	useGetMergecrmUrlMutation,
	useCreateMergecrmIntegrationMutation,
	useGetMergecrmIntegrationsQuery,
	useGetMergecrmLoadStatusQuery,
} = mergeCrmApi;
