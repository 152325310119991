import {
	cloneElement,
	type ComponentPropsWithoutRef,
	type ReactElement,
	type ReactNode,
} from "react";
import styles from "./Modal.module.css";
import { clsx } from "clsx";
import { getSize } from "../../../../theme/getSize";
import type { FeaturedIconProps } from "../featuredIcon/FeaturedIcon";
import { GroupedTransition } from "../transition/GroupedTransition";
import { Text } from "../typography/Text";
import {
	FloatingFocusManager,
	FloatingNode,
	FloatingOverlay,
	FloatingPortal,
	useDismiss,
	useFloating,
	useFloatingNodeId,
	useInteractions,
} from "@floating-ui/react";
import { zIndicies } from "src/common/utils/zIndicies.js";

const sizes = {
	xs: 320,
	sm: 380,
	md: 440,
	lg: 620,
	xl: 780,
};

export interface ModalProps {
	children?: ReactNode | undefined;
	open: boolean;
	onClose: () => void;
	size?: string | number;
}

/**
 * @deprecated Use `<Dialog>` instead
 */
export const Modal = ({ onClose, open, children, size = "md" }: ModalProps) => {
	const nodeId = useFloatingNodeId();
	const { refs, context } = useFloating({
		open,
		onOpenChange: (open) => {
			if (!open) {
				onClose();
			}
		},
		nodeId,
	});

	const { getFloatingProps } = useInteractions([
		useDismiss(context, { bubbles: false, outsidePress: false }),
	]);

	return (
		<FloatingNode id={nodeId}>
			<FloatingPortal>
				<GroupedTransition
					mounted={open}
					transitions={{
						modal: {
							duration: 250,
							transition: "pop",
						},
						overlay: {
							duration: 250 / 2,
							transition: "fade",
							timingFunction: "ease",
						},
					}}
				>
					{(transitionStyles) => (
						<div className={clsx("fixed inset-0", zIndicies.DIALOG)}>
							<FloatingOverlay lockScroll style={transitionStyles.overlay}>
								<div className="fixed inset-0 bg-black opacity-75" />
							</FloatingOverlay>
							<FloatingFocusManager context={context}>
								<div
									role="dialog"
									className={styles.inner}
									aria-labelledby="modal-title"
								>
									<div
										className={styles.modal}
										{...getFloatingProps({
											ref: refs.setFloating,
											style: {
												width: getSize({ size, sizes }),
												...transitionStyles.modal,
											},
										})}
									>
										{children}
									</div>
								</div>
								{/* </FocusTrap> */}
							</FloatingFocusManager>
						</div>
					)}
				</GroupedTransition>
			</FloatingPortal>
		</FloatingNode>
	);
};

interface ModalTitleProps extends ComponentPropsWithoutRef<"div"> {
	title: string;
	description?: string | undefined;
	icon?: ReactElement<FeaturedIconProps> | undefined;
}

/**
 * @deprecated Use `<DialogTitle>` (in conjunction with <Dialog>) instead
 */
export const ModalTitle = ({
	title,
	description,
	icon,
	className,
	...props
}: ModalTitleProps) => {
	return (
		<div className={clsx(styles.modalTitle, className)} {...props}>
			{icon &&
				cloneElement(icon, { className: clsx(icon.props.className, "mb-4") })}
			<Text size="lg" weight="medium" id="modal-title" component="h2">
				{title}
			</Text>
			{description && (
				<Text size="sm" color="text-grey-500" className="mt-2">
					{description}
				</Text>
			)}
		</div>
	);
};

/**
 * @deprecated Use `<DialogContent>` (in conjunction with <Dialog>) instead
 */
export const ModalContent = ({
	children,
	className,
}: ComponentPropsWithoutRef<"div">) => {
	return <div className={clsx(styles.modalContent, className)}>{children}</div>;
};

/**
 * @deprecated Use `<DialogActions>` (in conjunction with <Dialog>) instead
 */
export const ModalActions = ({
	children,
	className,
}: ComponentPropsWithoutRef<"div">) => {
	return (
		<div className={clsx(styles.modalActions, "space-x-2", className)}>
			{children}
		</div>
	);
};
