import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCurrentCompanyDomain } from "../../common/company-domain/useCurrentCompanyDomain";
import { Button } from "../../common/components/atoms/button/Button";
import { CheckboxField } from "../../common/components/atoms/checkbox/CheckboxField";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { useAsyncDefaultValues } from "../../common/utils/hooks/useAsyncDefaultValues";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import { getModuleName } from "./modules";
import {
	useGetCompanyModulesQuery,
	useGetModulesQuery,
	useUpdateCompanyModulesMutation,
} from "./api/planningApi";
import { modulesSelectors } from "./utils/modulesAdapter";
import { useCallback } from "react";

interface Inputs {
	modules: string[];
}

export const PlanningAdminCP = () => {
	useDocumentTitle("Planning Admin Control Panel");
	const { companyDomain } = useCurrentCompanyDomain();

	const { data: planModules, isLoading: isLoadingPlanModules } =
		useGetCompanyModulesQuery({ companyId: companyDomain.id });
	const { data: modules, isLoading: isLoadingModules } = useGetModulesQuery();

	const { t } = useTranslation();

	const defaultValues = useAsyncDefaultValues<Inputs>(
		useCallback(() => {
			if (planModules && modules) {
				return { modules: planModules.map((module) => module.id) };
			}
		}, [modules, planModules]),
	);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitting, isLoading },
	} = useForm<Inputs>({
		defaultValues: () => defaultValues,
	});

	const [updateCompanyModules] = useUpdateCompanyModulesMutation();

	const submitHandler: SubmitHandler<Inputs> = async (data) => {
		const result = await updateCompanyModules({
			companyDomainId: companyDomain.id,
			modules: data.modules,
		});

		if ("error" in result) {
			showErrorNotification({ message: t("The changes couldn't be saved") });
		} else {
			showSuccessNotification({ message: t("The changes are saved") });
		}
	};

	if (isLoadingModules || isLoadingPlanModules || isLoading) {
		return <LoadingState />;
	}

	if (!planModules || !modules) {
		return <MissingDataState />;
	}

	const modulesList = modulesSelectors.selectAll(modules);

	return (
		<form onSubmit={handleSubmit(submitHandler)}>
			<ul>
				<Controller
					name="modules"
					control={control}
					render={({ field }) => {
						return (
							<>
								<li>
									<CheckboxField
										labelProps={{ className: "mb-4" }}
										label={t("Select all")}
										indeterminate={
											field.value.length !== 0 &&
											field.value.length !== modulesList.length
										}
										onChange={(event) => {
											if (event.target.checked) {
												field.onChange(modulesList.map((module) => module.id));
											} else {
												field.onChange([]);
											}
										}}
										checked={field.value.length === modulesList.length}
									/>
								</li>
								{modulesList.map((module) => {
									return (
										<li key={module.id}>
											<CheckboxField
												label={getModuleName(t, module)}
												value={module.id}
												checked={field.value.includes(module.id)}
												onChange={(event) => {
													if (event.target.checked) {
														field.onChange([
															...field.value,
															event.target.value,
														]);
													} else {
														field.onChange(
															field.value.filter(
																(value) => value !== event.target.value,
															),
														);
													}
												}}
											/>
										</li>
									);
								})}
							</>
						);
					}}
				/>
			</ul>
			<Button
				type="submit"
				variant="primary"
				disabled={!isDirty || isSubmitting}
				isLoading={isSubmitting}
			>
				{t("Save")}
			</Button>
		</form>
	);
};
