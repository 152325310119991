import { useTranslation } from "react-i18next";
import { useCurrentCompanyDomain } from "../../../common/company-domain/useCurrentCompanyDomain.js";
import { Button } from "../../../common/components/atoms/button/Button.js";
import {
	Modal,
	ModalActions,
	ModalTitle,
} from "../../../common/components/atoms/modal/Modal.js";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events.js";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";
import type { EntityHierarchy } from "src/common/service/nexus/utils/hierarchy.js";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	group: EntityHierarchy<LegalEntityEntity, Record<string, null>> | null;
};

export const RemoveGroupModal = ({ group, isOpen, onClose }: Props) => {
	const { t } = useTranslation();

	const { companyDomain } = useCurrentCompanyDomain();

	const {
		removeGroupMutation: [deleteGroup, { isLoading }],
		parentLegalEntityId: legalEntityId,
		type,
	} = useEntityHierarchy();

	return (
		<Modal open={isOpen} onClose={onClose}>
			<ModalTitle
				title={t("Remove group")}
				description={t("Are you sure you want to remove {{groupName}}?", {
					groupName: group?.name,
				})}
			/>
			<ModalActions>
				<Button type="button" variant="secondaryGray" onClick={onClose}>
					{t("Cancel")}
				</Button>
				<Button
					type="button"
					onClick={async () => {
						if (group) {
							const result = await deleteGroup({
								companyDomainId: companyDomain.id,
								legalEntityId,
								groupId: group.id,
								type,
							});

							if ("error" in result) {
								showErrorNotification({
									message: t("Error"),
								});
							} else {
								onClose();
							}
						}
					}}
					destructive
					disabled={isLoading}
					isLoading={isLoading}
				>
					{t("Remove")}
				</Button>
			</ModalActions>
		</Modal>
	);
};
