import { useTranslation } from "react-i18next";
import type { User } from "../../common/service/Types/UserTypes";
import { IconTrash } from "@tabler/icons-react";
import { Avatar } from "../../common/components/atoms/avatar/Avatar";
import { Chip } from "../../common/components/atoms/chip/Chip";
import { Anchor } from "../../common/components/atoms/anchor/Anchor";
import { Tooltip } from "../../common/components/atoms/tooltip/Tooltip";
import { isCompanyAdmin } from "src/common/context/isCompanyAdmin.js";
import { getGravatarUrl } from "../planning/utils/getGravatarUrl.js";
import { getGroupName } from "./getGroupName.js";
import { Button } from "../../common/components/atoms/button/Button.js";

interface UserTableRowProps {
	user: User;
	onDeleteUser: (user: User) => void;
	onEditUser: (user: User) => void;
	companyId: string;
}

export const UserTableRow = ({
	user,
	companyId,
	onDeleteUser,
	onEditUser,
}: UserTableRowProps) => {
	const { t } = useTranslation();

	const accessGroups = user.accessGroups.filter((group) => {
		return group.companyId === Number(companyId);
	});
	accessGroups.sort((a, b) => {
		const aName = getGroupName({ t, groupName: a.name });
		const bName = getGroupName({ t, groupName: b.name });
		return aName.localeCompare(bName);
	});

	return (
		<tr>
			<td>
				<div className="flex items-center gap-2">
					<Avatar src={getGravatarUrl(user.email)} size="md" alt="" />
					<Anchor<"a">
						onClick={(event) => {
							event.preventDefault();
							onEditUser(user);
						}}
					>
						{user.name}
					</Anchor>
					{user.superAdmin && (
						<Chip color="purple" size="small">
							Superadmin
						</Chip>
					)}
				</div>
			</td>
			<td>{isCompanyAdmin(user, companyId) ? t("CompanyAdmin") : t("User")}</td>
			<td>{user.email}</td>
			<td>
				<div className="flex gap-2">
					{accessGroups.map((group) => {
						const colorClass = group.colorCode ? undefined : "bg-purple-500";
						return (
							<Tooltip
								dark
								key={group.groupId}
								label={getGroupName({ t, groupName: group.name })}
							>
								<div
									className={`rounded-full ${colorClass} h-[20px] w-[20px]`}
									style={{ backgroundColor: group.colorCode }}
								/>
							</Tooltip>
						);
					})}
				</div>
			</td>
			<td>
				<Button
					ariaLabel={t("Remove user")}
					onClick={(e) => {
						e.stopPropagation();
						onDeleteUser(user);
					}}
					variant="ghost"
					icon={<IconTrash />}
				/>
			</td>
		</tr>
	);
};
