import { useState, useEffect } from "react";

function useDebounce<T>(value: T, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

export function useDebounceState<S>(options: {
	initialValue: S | (() => S);
	ms: number;
}) {
	const [state, setState] = useState(options.initialValue);
	const debouncedState = useDebounce(state, options.ms);

	return [state, setState, debouncedState] as const;
}
