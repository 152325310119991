import type { Builder } from "../../types.js";
import type {
	PurchasePriceAllocation,
	EditablePurchasePriceAllocation,
	AcquisitionCalculationOutput,
	AcquisitionCalculationInput,
	PurchasePriceAllocationExcessAccount,
	CreatePurchasePriceAllocationExcessAccount,
	UpdatePurchasePriceAllocationExcessAccount,
	DraftVoucherSummary,
} from "./types.js";

export const buildPurchasePriceAllocationEndpoints = (builder: Builder) => ({
	getPurchasePriceAllocations: builder.query<PurchasePriceAllocation[], string>(
		{
			query: (companyDomainId) =>
				`/v1/company-domain/${companyDomainId}/purchase-price-allocation`,
			providesTags: () => ["PurchasePriceAllocation"],
		},
	),

	getPurchasePriceAllocation: builder.query<
		PurchasePriceAllocation,
		{ companyDomainId: string; purchasePriceAllocationId: string }
	>({
		query: ({ companyDomainId, purchasePriceAllocationId }) =>
			`/v1/company-domain/${companyDomainId}/purchase-price-allocation/${purchasePriceAllocationId}`,
		providesTags: (result, error, arg) => {
			if (error) {
				return ["PurchasePriceAllocation"];
			} else {
				return [
					"PurchasePriceAllocation",
					{
						type: "PurchasePriceAllocation",
						id: arg.purchasePriceAllocationId,
					},
				];
			}
		},
	}),

	createPurchasePriceAllocation: builder.mutation<
		PurchasePriceAllocation,
		{
			companyDomainId: string;
			acquiredLegalEntityId: string;
			acquirerLegalEntityId: string;
			data: EditablePurchasePriceAllocation;
		}
	>({
		query: ({
			companyDomainId,
			acquiredLegalEntityId,
			acquirerLegalEntityId,
			data,
		}) => ({
			url: `/v1/company-domain/${companyDomainId}/purchase-price-allocation`,
			method: "POST",
			body: {
				...data,
				acquiredLegalEntityId,
				acquirerLegalEntityId,
			},
		}),
		invalidatesTags: () => ["PurchasePriceAllocation"],
	}),

	updatePurchasePriceAllocation: builder.mutation<
		PurchasePriceAllocation,
		{
			companyDomainId: string;
			purchasePriceAllocationId: string;
			data: EditablePurchasePriceAllocation;
		}
	>({
		query: ({ companyDomainId, purchasePriceAllocationId, data }) => ({
			url: `/v1/company-domain/${companyDomainId}/purchase-price-allocation/${purchasePriceAllocationId}`,
			method: "PUT",
			body: data,
		}),
		invalidatesTags: () => ["PurchasePriceAllocation"],
	}),

	calculatePurchasePriceAllocationAcquisition: builder.query<
		AcquisitionCalculationOutput,
		{ companyDomainId: string; amounts: AcquisitionCalculationInput }
	>({
		query: ({ companyDomainId, amounts }) => ({
			url: `/v1/company-domain/${companyDomainId}/purchase-price-allocation/calculate`,
			method: "POST",
			body: amounts,
		}),
	}),

	createPurchasePriceAllocationExcessAccount: builder.mutation<
		PurchasePriceAllocationExcessAccount,
		{
			companyDomainId: string;
			purchasePriceAllocationId: string;
			data: CreatePurchasePriceAllocationExcessAccount;
		}
	>({
		query: ({ companyDomainId, purchasePriceAllocationId, data }) => ({
			url: `/v1/company-domain/${companyDomainId}/purchase-price-allocation/${purchasePriceAllocationId}/excess-account`,
			method: "POST",
			body: data,
		}),
		invalidatesTags: () => ["PurchasePriceAllocation"],
	}),

	updatePurchasePriceAllocationExcessAccount: builder.mutation<
		PurchasePriceAllocationExcessAccount,
		{
			companyDomainId: string;
			purchasePriceAllocationId: string;
			purchasePriceAllocationExcessAccountId: string;
			data: UpdatePurchasePriceAllocationExcessAccount;
		}
	>({
		query: ({
			companyDomainId,
			purchasePriceAllocationId,
			purchasePriceAllocationExcessAccountId,
			data,
		}) => ({
			url: `/v1/company-domain/${companyDomainId}/purchase-price-allocation/${purchasePriceAllocationId}/excess-account/${purchasePriceAllocationExcessAccountId}`,
			method: "PUT",
			body: data,
		}),
		invalidatesTags: () => ["PurchasePriceAllocation"],
	}),

	deletePurchasePriceAllocationExcessAccount: builder.mutation<
		PurchasePriceAllocationExcessAccount,
		{
			companyDomainId: string;
			purchasePriceAllocationId: string;
			purchasePriceAllocationExcessAccountId: string;
		}
	>({
		query: ({
			companyDomainId,
			purchasePriceAllocationId,
			purchasePriceAllocationExcessAccountId,
		}) => ({
			url: `/v1/company-domain/${companyDomainId}/purchase-price-allocation/${purchasePriceAllocationId}/excess-account/${purchasePriceAllocationExcessAccountId}`,
			method: "DELETE",
		}),
		invalidatesTags: () => ["PurchasePriceAllocation"],
	}),

	getPurchasePriceAllocationDraftVouchers: builder.query<
		DraftVoucherSummary[],
		{
			companyDomainId: string;
			purchasePriceAllocationId: string;
		}
	>({
		query: ({ companyDomainId, purchasePriceAllocationId }) => ({
			url: `/v1/company-domain/${companyDomainId}/purchase-price-allocation/${purchasePriceAllocationId}/vouchers`,
		}),
		providesTags: () => ["PurchasePriceAllocation"],
	}),
});
