import { Button } from "../../../common/components/atoms/button/Button";
import type {
	ButtonSize,
	ButtonVariant,
} from "../../../common/components/atoms/button/buttonUtils.js";
import { TooltipTrigger } from "../../../common/components/atoms/tooltip2/TooltipTrigger.js";
import { TooltipContent } from "../../../common/components/atoms/tooltip2/TooltipContent.js";
import { Tooltip2 } from "../../../common/components/atoms/tooltip2/Tooltip2.js";

function generateCombinations<const T extends any[][]>(...arrays: T) {
	const combinations: [...{ [index in keyof T]: T[index][number] }][] = [];

	function helper(arrays: any[], current: any[]) {
		if (arrays.length === 0) {
			combinations.push(current.slice() as T);
		} else {
			const newArray = arrays.slice();
			const currentArray = newArray.shift();

			if (currentArray) {
				for (const value of currentArray) {
					helper(newArray, [...current, value]);
				}
			}
		}
	}

	helper(arrays, []);

	return combinations;
}

export const ButtonPresentation = () => {
	const variants: ButtonVariant[] = [
		"primary",
		"secondary",
		"secondaryGray",
		"ghost",
	];
	const sizes: ButtonSize[] = ["sm", "md", "lg"];
	const disabled = [false, true];
	const destructive = [false, true];

	const allCombinations = generateCombinations(
		variants,
		sizes,
		disabled,
		destructive,
		["kalle", "anka"],
	);

	return (
		<div className="flex flex-wrap items-center gap-x-2 gap-y-4">
			{allCombinations.map(
				([variant, size, disabled, destructive], index, list) => {
					const prevButton = list[index - 1];
					const button = (
						<Tooltip2 key={index}>
							<TooltipTrigger asChild>
								<Button
									size={size}
									variant={variant}
									disabled={disabled}
									destructive={destructive}
								>
									Button
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								{`size=${size}
            variant=${variant}
            disabled=${disabled}
            destructive=${destructive}`}
							</TooltipContent>
						</Tooltip2>
					);

					const newVariant = Boolean(prevButton) && prevButton[0] !== variant;
					const newSize = Boolean(prevButton) && prevButton[1] !== size;
					if (newVariant || newSize) {
						return (
							<>
								<div className="w-full grow" />
								{button}
							</>
						);
					}
					return button;
				},
			)}
		</div>
	);
};
