import type { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes.js";
import type { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions.js";
import type {
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery.js";

export const TAGS = [
	"CorporateGroupCurrencyExchangeStrategies",
	"LegalEntity",
	"LegalEntityStructure",
	"Account",
	"PurchasePriceAllocation",
	"CostCenterHierarchy",
	"ProjectHierarchy",
	"CustomerHierarchy",
	"SupplierHierarchy",
	"ProductHierarchy",
	"AccountHierarchy",
	"Integration",
	"FiscalYear",
	"CostCenter",
	"Supplier",
	"SupplierInvoice",
	"Project",
	"Product",
	"Customer",
	"CustomerInvoice",
	"AccountArrangement",
] as const;

type Tag = (typeof TAGS)[number];

export type Builder = EndpointBuilder<
	BaseQueryFn<
		string | FetchArgs,
		unknown,
		FetchBaseQueryError,
		{},
		FetchBaseQueryMeta
	>,
	Tag,
	"nexusApi"
>;

type Dimensions = {
	[T in Tag]: T extends `${infer U}Hierarchy` ? U : never;
};

export type Dimension = Dimensions[keyof Dimensions];

export type PaginatedResponse<T> = {
	items: T[];
	skipped: number;
};

export type PaginatedParams = {
	take?: number;
	skip?: number;
};

export type Entity = {
	id: string;
	createdAt: string;
	updatedAt: string;
};

export type LegalEntityEntity = Entity & {
	legalEntityId: string;
};

export interface BaseInvoice {
	number: string | null;
	id: string;
	status: string | null;
	files: {
		id: string;
		name: string;
		type: string;
	}[];
	legalEntityId: string;
	key: string;
	source: string;
	reference: string | null;
	currencyInvoice: string;
	invoiceDate: string;
	dueDate: string;
	finalPayDate: string | null;
	balanceAmount: string;
	totalAmount: string;
	vatAmount: string;
	totalAmountInInvoiceCurrency: string | null;
	vatAmountInInvoiceCurrency: string | null;
	isCredit: boolean | null;
	createdAt: string;
	updatedAt: string;
	document: string | null;
}
