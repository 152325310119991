import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../common/components/atoms/button/Button";
import { Label } from "../../../common/components/atoms/label/Label";
import {
	Modal,
	ModalActions,
	ModalContent,
	ModalTitle,
} from "../../../common/components/atoms/modal/Modal";
import { NativeSelect } from "../../../common/components/atoms/native-select/NativeSelect";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.js";

interface Props {
	isOpen: boolean;
	availableLegalEntities: LegalEntity[];
	onAdd: (legalEntity: LegalEntity) => Promise<void>;
	onCancel: () => void;
}

export const AddCompanyToHierarchyModal = ({
	isOpen,
	availableLegalEntities,
	onAdd,
	onCancel,
}: Props) => {
	const { t } = useTranslation();

	const [selected, setSelected] = useState("");

	const [isAdding, setIsAdding] = useState(false);

	const formId = "add-legal-entity-form";

	return (
		<Modal onClose={onCancel} open={isOpen}>
			<ModalTitle title={t("Add subsidiary")} />
			<ModalContent>
				<form
					onSubmit={async (e) => {
						e.preventDefault();

						const legalEntity = availableLegalEntities.find(
							(node) => node.id === selected,
						);

						if (legalEntity) {
							setIsAdding(true);
							try {
								await onAdd(legalEntity);
								setIsAdding(false);
							} catch (e) {
								showErrorNotification({
									message: t("Error"),
								});
							} finally {
								setIsAdding(false);
							}
						}
					}}
					id={formId}
					className="space-y-4"
				>
					<div>
						<Label htmlFor="add-legal-entity-select">
							{t("Add subsidiary")}
						</Label>
						<NativeSelect
							onChange={(e) => setSelected(e.target.value)}
							value={selected}
							id="add-legal-entity-select"
						>
							<option value="">{t("Select subsidiary company")}</option>
							{availableLegalEntities.map((node) => (
								<option key={node.id} value={node.id}>
									{node.name}
								</option>
							))}
						</NativeSelect>
					</div>
				</form>
			</ModalContent>
			<ModalActions>
				<Button variant="secondaryGray" onClick={onCancel}>
					{t("Cancel")}
				</Button>
				<Button
					disabled={!selected}
					type="submit"
					form={formId}
					isLoading={isAdding}
				>
					{t("Add to corporate structure")}
				</Button>
			</ModalActions>
		</Modal>
	);
};
