import { IconCirclePlus } from "@tabler/icons-react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { FeaturedIcon } from "../../common/components/atoms/featuredIcon/FeaturedIcon";
import { Label } from "../../common/components/atoms/label/Label";
import {
	ModalTitle,
	ModalContent,
	ModalActions,
	Modal,
} from "../../common/components/atoms/modal/Modal";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { useCreateFakePumpMutation } from "../../common/redux/api/exopenApi";
import { Button } from "../../common/components/atoms/button/Button";
import { Input } from "../../common/components/atoms/input/Input";
import { useId } from "react";

interface Inputs {
	legalEntityId: string;
	name: string;
}

interface FakePumpProps {
	pump: string | null;
	onClose: () => void;
	companyId: string;
}

export const FakePumpModal = ({ pump, onClose, companyId }: FakePumpProps) => {
	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
		reset,
	} = useForm<Inputs>({ defaultValues: { legalEntityId: "", name: "" } });

	const [createFakePump] = useCreateFakePumpMutation();

	const submitHandler: SubmitHandler<Inputs> = async (data) => {
		const result = await createFakePump({
			companyId,
			pumpType: pump!,
			legalEntityId: data.legalEntityId,
			name: data.name,
		});
		if ("error" in result) {
			showErrorNotification({ message: "Något gick fel" });
		} else {
			showSuccessNotification({ message: "Fejkpumpen skapades" });
			reset({ name: "", legalEntityId: "" });
			onClose();
		}
	};

	const formId = useId();

	return (
		<Modal open={pump !== null} onClose={onClose}>
			<ModalTitle
				title="Ny fejkpump"
				icon={
					<FeaturedIcon color="primary" size="lg" theme="light_circle">
						<IconCirclePlus />
					</FeaturedIcon>
				}
			/>
			<ModalContent>
				<form id={formId} onSubmit={handleSubmit(submitHandler)}>
					<Label htmlFor="fake-pump-legal-entity-id">Legal enhets-ID</Label>
					<Input
						id="fake-pump-legal-entity-id"
						{...register("legalEntityId")}
						containerProps={{ className: "mb-4" }}
					/>

					<Label htmlFor="fake-pump-name">Namn</Label>
					<Input id="fake-pump-name" {...register("name")} />
				</form>
			</ModalContent>
			<ModalActions>
				<Button variant="secondaryGray" onClick={onClose}>
					Avbryt
				</Button>
				<Button
					isLoading={isSubmitting}
					disabled={isSubmitting}
					variant="primary"
					form={formId}
					type="submit"
				>
					Skapa fejkpump
				</Button>
			</ModalActions>
		</Modal>
	);
};
