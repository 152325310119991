import { Input, type InputProps } from "components/input/Input";
import { type ChangeEvent, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useMenuComponentContext } from "./useMenuComponentContext.js";

type MenuSearchFieldProps = Omit<
	InputProps,
	"value" | "onChange" | "onClick"
> & {
	filterValue: string;
	setFilterValue: (value: string) => unknown;
};

export const MenuSearchField = forwardRef<
	HTMLInputElement,
	MenuSearchFieldProps
>(({ filterValue, setFilterValue, ...rest }, ref) => {
	const { t } = useTranslation();
	const { getReferenceProps, setActiveIndex } = useMenuComponentContext();

	return (
		<Input
			{...rest}
			autoComplete="off"
			placeholder={t("Filter")}
			value={filterValue}
			tabIndex={0}
			containerProps={{
				className: "p-2",
			}}
			aria-autocomplete="list"
			{...getReferenceProps({
				onClick(event: React.MouseEvent<HTMLInputElement>) {
					event.preventDefault();
					event.stopPropagation();
				},
				onChange(event: ChangeEvent<HTMLInputElement>) {
					setActiveIndex(null);
					setFilterValue(event.target.value);
				},
			})}
			ref={ref}
		/>
	);
});

MenuSearchField.displayName = "MenuSearchField";
