import { clsx } from "clsx";
import {
	forwardRef,
	type DetailedHTMLProps,
	type ButtonHTMLAttributes,
	type MouseEventHandler,
} from "react";
import { Text } from "../typography/Text";
import { IconChevronDown, IconX } from "@tabler/icons-react";

type SelectContainerProps = {
	placeholder?: string | null | undefined;
	onClear?: MouseEventHandler<HTMLButtonElement> | undefined;
	borderless?: boolean | undefined;
	value: string | null;
} & Omit<
	DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
	"value"
>;

export const SelectContainer = forwardRef<
	HTMLButtonElement,
	SelectContainerProps
>(function SelectContainer(
	{
		className,
		onClear,
		disabled,
		placeholder,
		borderless,
		value,
		children,
		["aria-invalid"]: ariaInvalid,
		["aria-expanded"]: ariaExpanded,
		...rest
	},
	ref,
) {
	const clearable = typeof onClear === "function";

	return (
		<div className={clsx("relative w-full", borderless && "h-full")}>
			<button
				ref={ref}
				disabled={disabled}
				className={clsx(
					"flex w-full cursor-pointer items-center truncate bg-white px-[14px] text-left",
					borderless
						? "h-full outline-none ring-inset ring-grey-100 focus-visible:ring-4"
						: "h-[40px] rounded-[8px] border shadow-[0px_1px_2px_0px_#1018280D] outline-4 focus-visible:outline",
					ariaExpanded
						? borderless
							? "ring-4"
							: "outline outline-4"
						: "focus:outline",
					disabled ? "bg-grey-50" : "",
					ariaInvalid
						? borderless
							? "ring-error-100"
							: "border-error-300 outline-error-100"
						: ariaExpanded
							? "border-purple-400"
							: "border-grey-300 focus:border-purple-400",
					ariaInvalid && borderless && ariaExpanded && "ring-4",
					!ariaInvalid && !borderless && "outline-purple-50",
					value !== null && clearable && "pr-8",
					className,
				)}
				value={value ?? undefined}
				{...rest}
			>
				<div className="flex grow gap-2 overflow-hidden">
					<Text
						size="sm"
						color={
							ariaInvalid
								? "text-error-500"
								: typeof disabled === "boolean" && disabled
									? "text-grey-500"
									: "text-grey-900"
						}
						className="truncate whitespace-nowrap"
					>
						{children ?? placeholder}
					</Text>
				</div>
				{(value === null || !clearable) && (
					<IconChevronDown
						size={20}
						className={clsx(
							ariaInvalid ? "text-error-500" : "text-purple-500",
							"ml-2 shrink-0",
						)}
					/>
				)}
			</button>
			{clearable && value !== null && (
				<button
					className="absolute bottom-0 right-0 top-0 px-4"
					onClick={onClear}
					type="button"
				>
					<IconX size={16} className="text-purple-500" />
				</button>
			)}
		</div>
	);
});
