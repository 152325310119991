import { useTranslation } from "react-i18next";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../../../planning/components/PlanningTable.js";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { Button } from "../../../../common/components/atoms/button/Button.js";
import { TriggerDlmRawDataRunModalButton } from "./TriggerDlmRawDataRunModalButton.js";
import { SwitchField } from "components/checkbox/SwitchField.js";
import type { DlmRawMartConfig } from "src/common/redux/api/exopenApiTypes.js";
import isEqual from "lodash.isequal";
import { useState } from "react";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events.js";
import { Chip } from "components/chip/Chip.js";
import { SourceSystemImage } from "src/features/connector/connectors/ConnectorsTable/SourceSystemImage.js";
import { useSetIntegrationRawConfigurationDlmMutation } from "src/common/redux/api/exopenApi.js";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.js";
import type { FusionhubIntegration as FusionHubIntegration } from "src/common/service/fusionHub/types.ts";
import type { Integration as NexusIntegration } from "src/common/service/nexus/endpoints/integration/types.ts";
import { DlmMarts } from "./dlmMarts.js";
import { ForceWriteParquetDlmButton } from "./ForceWriteParquetDlmButton.js";
import type { MergeCrmIntegration } from "src/common/service/fusionHub/integrations/mergeCrmApi.js";

interface Props {
	companyDomainId: string;
	rawMartsConfigs: DlmRawMartConfig[];
	defaultRawMart: boolean;
	dataObjects: { label: string; value: string; system: string }[];
	onSetMartConfigSuccess?: () => void;

	fusionHubIntegrations: FusionHubIntegration[];
	fusionHubMergecrmIntegrations: MergeCrmIntegration[];
	nexusIntegrations: NexusIntegration[];
	legalEntities: LegalEntity[];
}

type DataSource = {
	id: number;
	name: string;
	type: string;
};

const mergeDataSources = ({
	nexusIntegrations,
	fusionHubIntegrations,
	fusionHubMergecrmIntegrations,
	legalEntities,
}: {
	fusionHubIntegrations: FusionHubIntegration[];
	fusionHubMergecrmIntegrations: MergeCrmIntegration[];
	nexusIntegrations: NexusIntegration[];
	legalEntities: LegalEntity[];
}): DataSource[] => {
	return [
		...fusionHubMergecrmIntegrations.map((int) => {
			return {
				id: int.integration.id,
				type: int.integration.type,
				name: int.system.name,
			};
		}),
		...fusionHubIntegrations
			.filter(
				(integration) =>
					!fusionHubMergecrmIntegrations
						.map((mergeInt) => mergeInt.integration.id)
						.includes(integration.id),
			)
			.map((int) => {
				const nexusInt = nexusIntegrations.find(
					(nexusInt) =>
						nexusInt.source === "FusionHub" &&
						int.id.toString() === nexusInt.sourceId,
				);

				return {
					id: int.id,
					type: int.type,
					name:
						legalEntities.find(
							(legalEntity) => legalEntity.id === nexusInt?.legalEntityId,
						)?.name ?? "Unknown",
				};
			}),
	];
};

export const DlmIntegrationsTable = ({
	companyDomainId,
	rawMartsConfigs,
	onSetMartConfigSuccess,
	defaultRawMart,
	dataObjects,

	legalEntities,
	fusionHubIntegrations,
	fusionHubMergecrmIntegrations,
	nexusIntegrations,
}: Props) => {
	const { t } = useTranslation();

	const [setIntegrationRawConfigurationDlm, { isLoading: isSettingRawConfig }] =
		useSetIntegrationRawConfigurationDlmMutation();

	const integrationData = mergeDataSources({
		legalEntities,
		fusionHubIntegrations,
		fusionHubMergecrmIntegrations,
		nexusIntegrations,
	});

	const currentMartConfigs = integrationData.map((integration) => {
		const config = rawMartsConfigs.find(
			(intConf) => intConf.integrationId === integration.id,
		);

		return {
			integrationId: integration.id,
			rawMartEnabled: config?.rawMartEnabled ?? defaultRawMart,
		};
	});

	const [martConfigs, setMartConfigs] = useState([...currentMartConfigs]);

	const handleMartSwitch = (mart: DlmMarts, integrationId: number) => {
		setMartConfigs([
			...martConfigs.map((conf) =>
				conf.integrationId !== integrationId
					? conf
					: {
							integrationId: conf.integrationId,
							rawMartEnabled:
								mart === DlmMarts.Raw
									? !conf.rawMartEnabled
									: conf.rawMartEnabled,
						},
			),
		]);
	};

	const getNonDefaultMartConfigs = () =>
		martConfigs.filter((conf) => conf.rawMartEnabled !== defaultRawMart);

	const martConfigHasChanged = () => {
		return !isEqual(rawMartsConfigs, getNonDefaultMartConfigs());
	};

	return (
		<>
			<PlanningTableContainer>
				<PlanningTable>
					<thead>
						<tr>
							<th>{t("Integrations")} </th>
							<th />
							<th />
							<th />
							<th />
							<th />
							<th className="w-0" />
						</tr>
					</thead>
					<tbody>
						{integrationData.map((integration) => {
							const currentConfig = currentMartConfigs.find(
								(intConfig) => intConfig.integrationId === integration.id,
							);
							const stateConfig = martConfigs.find(
								(intConfig) => intConfig.integrationId === integration.id,
							);

							return (
								<tr key={integration.id}>
									<td className="w-60">{integration.name}</td>
									<td className="w-32">
										<SourceSystemImage sourceSystemName={integration.type} />
									</td>
									<td>
										{currentConfig?.rawMartEnabled === defaultRawMart && (
											<Chip size="small" color="primary">
												{t("DEFAULT")}
											</Chip>
										)}
										{stateConfig?.rawMartEnabled !==
											currentConfig?.rawMartEnabled && (
											<Chip size="small" color="warning" className="ml-1">
												{t("UNSAVED")}
											</Chip>
										)}
									</td>
									<td className="w-1/5">
										<SwitchField
											label={DlmMarts.Raw}
											checked={stateConfig?.rawMartEnabled ?? defaultRawMart}
											onChange={() => {
												handleMartSwitch(DlmMarts.Raw, integration.id);
											}}
										/>
									</td>
									<td className="w-1/12">
										<TriggerDlmRawDataRunModalButton
											companyDomainId={companyDomainId}
											integrationId={integration.id}
											dataObjects={dataObjects}
											system={integration.type}
										/>
									</td>
									<td className="w-1/12">
										<ForceWriteParquetDlmButton
											companyDomainId={companyDomainId}
											integrationOrLegalEntityId={`${integration.id}`}
											mart={DlmMarts.Raw}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</PlanningTable>
			</PlanningTableContainer>
			{integrationData.length > 0 && (
				<div className="mt-4">
					<Button
						isLoading={isSettingRawConfig}
						disabled={!martConfigHasChanged()}
						onClick={async () => {
							const result = await setIntegrationRawConfigurationDlm({
								companyDomainId,
								integrationRawMartSettings: getNonDefaultMartConfigs(),
							});
							if ("error" in result) {
								showErrorNotification({
									message: t("Something failed..."),
								});
							} else {
								showSuccessNotification({
									message: t("Changes successfully implemented"),
								});

								if (onSetMartConfigSuccess) onSetMartConfigSuccess();
							}
						}}
						icon={<IconDeviceFloppy />}
					>
						{t("Save new integration configurations")}
					</Button>
				</div>
			)}
		</>
	);
};
