import { useState } from "react";
import { Select } from "../../../common/components/atoms/select/Select.tsx";

export const SelectPresentation = () => {
	const [value, setValue] = useState<string | null>(null);
	return (
		<div>
			<Select
				ariaLabel="Items"
				placeholder="Select items"
				value={value}
				onChange={setValue}
				options={[
					{ label: "A", value: "a" },
					{ label: "B", value: "b" },
					{ label: "C", value: "c" },
					{ label: "D", value: "d" },
					{ label: "E", value: "e" },
					{ label: "F", value: "f" },
					{ label: "G", value: "g" },
					{ label: "H", value: "h" },
					{ label: "I", value: "i" },
					{ label: "J", value: "j" },
					{ label: "K", value: "k" },
				]}
			/>
		</div>
	);
};
