import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { SortableTableCell } from "../../../common/components/atoms/table/SortableTableCell.js";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";
import { sortArrayByAttribute } from "../../../common/utils/sortArrayByAttribute.js";
import { Checkbox } from "../../../common/components/atoms/checkbox/Checkbox.js";
import { Pagination } from "../../../common/components/atoms/pagination/Pagination.js";
import { Button } from "../../../common/components/atoms/button/Button.js";
import { IconArrowLeftRight } from "@tabler/icons-react";
import { useLegalEntitiesOrThrow } from "../../../common/legal-entities/useLegalEntities.js";
import { Input } from "../../../common/components/atoms/input/Input.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";
import {
	PlanningTable2,
	PlanningTableContainer,
} from "../../planning/components/PlanningTable";
import { Label } from "../../../common/components/atoms/label/Label.js";
import { LoadingState } from "../../../common/components/atoms/loadingState/LoadingState.js";
import { clsx } from "clsx";
import { useLocalStorageState } from "../../../common/utils/hooks/useLocalStorageState.js";

type Column<T> = {
	id: string;
	label: string;
	render: (item: T) => React.ReactNode;
};

type Props<T extends LegalEntityEntity> = {
	items: T[];
	extraColumns?: Column<T>[];
	onMoveItems: (items: T[]) => void;
	isEditable: boolean;
	isSearchable?: boolean;
	hasFilterUsed?: boolean;
};

const ITEMS_PER_PAGE = 20;

export const EntityItemsList = <T extends LegalEntityEntity>({
	items,
	extraColumns = [],
	onMoveItems,
	isEditable,
	isSearchable = true,
	hasFilterUsed = false,
}: Props<T>) => {
	const { t } = useTranslation();
	const {
		entityColumns: columns,
		labels: { entityNamePlural },
		type,
		usedHierarchyItemsMap,
		hierarchyItemsMap,
		isLoading,
	} = useEntityHierarchy();

	const { getById } = useLegalEntitiesOrThrow();

	const [filter, setFilter] = useState("");
	const [filterUsed, setFilterUsed] = useLocalStorageState(
		"available-items-filter-used",
		false,
		{
			serializer: JSON.stringify,
			deserializer: JSON.parse,
		},
	);

	const [page, setPage] = useState(1);
	const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
	const [order, setOrder] = useState<"asc" | "desc">("asc");
	const [orderBy, setOrderBy] = useState(columns[0].key);

	const createSortHandler = (property: keyof LegalEntityEntity) => () => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const filteredItems = useMemo(
		() =>
			items.filter((item) => {
				const itemsMap =
					hasFilterUsed && filterUsed
						? usedHierarchyItemsMap
						: hierarchyItemsMap;

				if (itemsMap && !itemsMap[item.id]) {
					return false;
				}

				if (filter === "") {
					return true;
				}

				const legalEntity = getById(item.legalEntityId);
				if (
					legalEntity &&
					legalEntity.name.toLowerCase().includes(filter.toLowerCase())
				) {
					return true;
				}

				return columns.some((column) => {
					const value = item[column.key];

					if (typeof value === "string") {
						return value.toLowerCase().startsWith(filter.toLowerCase());
					}

					return false;
				});
			}),
		[
			items,
			hasFilterUsed,
			filterUsed,
			usedHierarchyItemsMap,
			hierarchyItemsMap,
			filter,
			getById,
			columns,
		],
	);

	const sortedItems = useMemo(
		() => sortArrayByAttribute(filteredItems, (item) => item[orderBy], order),
		[filteredItems, orderBy, order],
	);

	const handleSelectAll = () => {
		if (selectedItemIds.length === items.length) {
			setSelectedItemIds([]);
		} else {
			setSelectedItemIds(filteredItems.map((item) => item.id));
		}
	};

	const hasPagination = filteredItems.length > ITEMS_PER_PAGE;

	return (
		<div className="bg-grey-50 p-4">
			<div className="mb-4 flex items-center justify-between">
				<div className="flex flex-col items-start gap-y-2">
					{isSearchable && (
						<Input
							placeholder={t("Filter")}
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
					)}

					{hasFilterUsed && (
						<span className="flex items-center gap-x-2">
							<Checkbox
								className="p-2"
								checked={filterUsed}
								onChange={() => setFilterUsed(!filterUsed)}
							/>
							<Label className="pt-1.5">
								{t("Show only used {{entityNamePlural}}", { entityNamePlural })}
							</Label>
						</span>
					)}
				</div>

				{isEditable && (
					<Button
						onClick={() => {
							onMoveItems(
								items.filter((item) => selectedItemIds.includes(item.id)),
							);
							setSelectedItemIds([]);
						}}
						icon={<IconArrowLeftRight />}
						iconPosition="right"
						disabled={selectedItemIds.length === 0}
					>
						{t("Move {{entityNamePlural}}", {
							entityNamePlural,
						})}
					</Button>
				)}
			</div>
			<PlanningTableContainer className="relative">
				{isLoading && (
					<div className="absolute left-0 right-0 top-8 flex justify-center">
						<LoadingState />
					</div>
				)}

				<PlanningTable2>
					<thead>
						<tr>
							{isEditable && (
								<PlanningTable2.Th className="w-0">
									<Checkbox
										checked={selectedItemIds.length === filteredItems.length}
										onChange={handleSelectAll}
										className="block"
									/>
								</PlanningTable2.Th>
							)}
							{columns.map((column) => (
								<SortableTableCell
									key={column.key}
									order={order}
									active={orderBy === column.key}
									onClick={createSortHandler(column.key)}
								>
									{column.label}
								</SortableTableCell>
							))}
							{extraColumns.map((column) => (
								<PlanningTable2.Th key={column.id}>
									{column.label}
								</PlanningTable2.Th>
							))}
							{type === "group" && <td>{t("Legal entity")}</td>}
						</tr>
					</thead>
					<tbody
						className={clsx(isLoading && "pointer-events-none opacity-10")}
					>
						{sortedItems
							.slice(
								(page - 1) * ITEMS_PER_PAGE,
								(page - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
							)
							.map((item) => {
								return (
									<tr key={item.id}>
										{isEditable && (
											<PlanningTable2.Td className="w-0 py-1">
												<Checkbox
													checked={selectedItemIds.includes(item.id)}
													onChange={(event) => {
														if (
															event.target.checked &&
															!selectedItemIds.includes(item.id)
														) {
															setSelectedItemIds((items) => [
																...items,
																item.id,
															]);
														} else {
															setSelectedItemIds((items) =>
																items.filter((id) => id !== item.id),
															);
														}
													}}
													className="block"
												/>
											</PlanningTable2.Td>
										)}
										{columns.map((column) => (
											<PlanningTable2.Td key={column.key}>
												{item[column.key]}
											</PlanningTable2.Td>
										))}
										{extraColumns.map((column) => (
											<PlanningTable2.Td key={column.id}>
												{column.render(item)}
											</PlanningTable2.Td>
										))}
										{type === "group" && (
											<PlanningTable2.Td>
												{getById(item.legalEntityId)?.name ?? t("Unknown")}
											</PlanningTable2.Td>
										)}
									</tr>
								);
							})}
					</tbody>
				</PlanningTable2>
			</PlanningTableContainer>
			{hasPagination && (
				<div className="mt-4 flex justify-center">
					<Pagination
						page={page}
						totalPages={Math.ceil(filteredItems.length / ITEMS_PER_PAGE)}
						onChange={setPage}
					/>
				</div>
			)}
		</div>
	);
};
