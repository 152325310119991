import { useTranslation } from "react-i18next";
import {
	Modal,
	ModalActions,
	ModalContent,
	ModalTitle,
} from "../../../common/components/atoms/modal/Modal.js";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/components/atoms/input/Input.js";
import { Button } from "../../../common/components/atoms/button/Button.js";
import { useCurrentCompanyDomain } from "../../../common/company-domain/useCurrentCompanyDomain.js";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../common/components/atoms/notifications/events.js";
import { useEffect, useMemo, useState } from "react";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";
import { Label } from "../../../common/components/atoms/label/Label.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";
import type {
	EntityHierarchy,
	EntityHierarchyGroup,
} from "src/common/service/nexus/utils/hierarchy.js";

type Props = {
	parent?: EntityHierarchy<LegalEntityEntity, Record<string, never>> | null;
	group?: EntityHierarchyGroup;
	position?: number;
	isOpen: boolean;
	onClose: () => void;
};

export const EditGroupModal = ({
	parent,
	group,
	position,
	isOpen,
	onClose,
}: Props) => {
	const { companyDomain } = useCurrentCompanyDomain();

	const {
		createGroupMutation: [createGroup],
		updateGroupMutation: [updateGroup],
		parentLegalEntityId: legalEntityId,
		type,
		getDefaultGroupAttributes,
		renderGroupAttributesCreate,
		renderGroupAttributesUpdate,
		validateAttributes,
	} = useEntityHierarchy();

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
		reset,
	} = useForm({
		defaultValues: {
			name: group?.name ?? "",
		},
	});

	useEffect(() => {
		if (isOpen) {
			reset({
				name: group?.name ?? "",
			});
		}
	}, [group?.name, isOpen, reset]);

	const [attributes, setAttributes] = useState(
		group
			? group.attributes
			: getDefaultGroupAttributes?.({
					parentNode: parent ?? null,
				}),
	);

	useEffect(() => {
		if (group) {
			setAttributes(group.attributes);
		} else {
			setAttributes(
				getDefaultGroupAttributes?.({
					parentNode: parent ?? null,
				}),
			);
		}
	}, [getDefaultGroupAttributes, group, parent]);

	const attributeErrors = useMemo(() => {
		if (!validateAttributes || !attributes) {
			return null;
		}
		return validateAttributes(attributes);
	}, [attributes, validateAttributes]);

	return (
		<Modal open={isOpen} onClose={onClose}>
			<ModalTitle title={group?.id ? t("Edit group") : t("Create group")} />
			<ModalContent>
				<form
					id="edit-group-form"
					onSubmit={handleSubmit(async (data) => {
						if (attributeErrors) return;

						if (group?.id) {
							const result = await updateGroup({
								companyDomainId: companyDomain.id,
								legalEntityId,
								groupId: group.id,
								type,
								group: {
									parentId: parent?.id ?? null,
									name: data.name,
									itemIds: group.items.map((item) => item.id),
									attributes: attributes ?? {},
								},
							});

							if ("error" in result) {
								showErrorNotification({
									message: t("Failed to update group"),
								});
							} else {
								showSuccessNotification({
									message: t("Group updated"),
								});
								onClose();
							}
						} else {
							const result = await createGroup({
								companyDomainId: companyDomain.id,
								legalEntityId,
								type,
								group: {
									name: data.name,
									parentId: parent?.id ?? null,
									sortOrder:
										(position ?? parent?.children.length ?? 0) * 1000 + 1000,
									itemIds: [],
									attributes: attributes ?? {},
								},
							});

							if ("error" in result) {
								showErrorNotification({
									message: t("Failed to create group"),
								});
							} else {
								showSuccessNotification({
									message: t("Group created"),
								});
								onClose();
							}
						}
					})}
				>
					<Label htmlFor="name">{t("Group name")}</Label>
					<Input
						id="name"
						{...register("name", {
							required: t("This field is required"),
						})}
						hint={errors.name?.message}
						aria-invalid={errors.name !== undefined}
					/>
					{attributes && (
						<div className="mt-4">
							{group
								? renderGroupAttributesUpdate?.({
										parentNode: parent ?? null,
										attributes,
										onChange: setAttributes,
										attributeErrors,
									})
								: renderGroupAttributesCreate?.({
										parentNode: parent ?? null,
										attributes,
										onChange: setAttributes,
										attributeErrors,
									})}
						</div>
					)}
				</form>
			</ModalContent>
			<ModalActions>
				<Button variant="secondaryGray" onClick={onClose}>
					{t("Cancel")}
				</Button>
				<Button
					type="submit"
					form="edit-group-form"
					variant="primary"
					isLoading={isSubmitting}
					disabled={!isValid}
				>
					{t("Save")}
				</Button>
			</ModalActions>
		</Modal>
	);
};
