import { useTranslation } from "react-i18next";
import type { AccessGroup } from "../../common/service/Types/AccessGroup";
import {
	makeArrayLengthSort,
	makeStringSort,
	useSort,
} from "../../common/utils/hooks/useSort";
import { AccessGroupTableRow } from "./AccessGroupTableRow";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";
import { SortingArrowButton } from "../planning/components/SortingArrowButton";
import { useState } from "react";
import {
	useDeleteAccessGroupMutation,
	useGetAccessGroupsQuery,
} from "../../common/redux/api/exopenApi";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { AddAccessGroupModal } from "./AddAccessGroupModal";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import { useParams } from "react-router-dom";
import { invariant } from "../../common/utils/invariant";
import { ConfirmDialog } from "../../common/components/atoms/dialog/ConfirmDialog.tsx";

export const AccessGroupTable = () => {
	const { t } = useTranslation();
	const { companyId } = useParams();
	invariant(companyId);
	const { data: accessGroups, isLoading: isLoadingAccessGroups } =
		useGetAccessGroupsQuery(companyId);
	const groupById: Record<number, AccessGroup> = {};
	for (const group of accessGroups ?? []) {
		groupById[group.groupId] = group;
	}
	const { items: sortedGroups, sortColumnProps } = useSort({
		keys: ["byName", "byUsers"],
		defaultKey: "byName",
		defaultOrder: "asc",
		rows: accessGroups ?? [],
		columns: {
			byName: {
				sort: makeStringSort("name"),
			},
			byUsers: {
				sort: makeArrayLengthSort("userEmails"),
			},
		},
	});
	const [groupToEdit, setGroupToEdit] = useState<number>();
	const [groupToDelete, setGroupToDelete] = useState<number>();
	const [deleteAccessGroup, { isLoading: isDeletingAccessGroup }] =
		useDeleteAccessGroupMutation();

	if (isLoadingAccessGroups) {
		return <LoadingState />;
	}

	if (!accessGroups) {
		return <MissingDataState />;
	}

	return (
		<>
			<PlanningTableContainer>
				<PlanningTable aria-label={t("Groups")}>
					<thead>
						<tr>
							<th className="whitespace-nowrap">
								<SortingArrowButton {...sortColumnProps.byName}>
									{t("Group name")}
								</SortingArrowButton>
							</th>
							<th>{t("Description")}</th>
							<th className="whitespace-nowrap">
								<SortingArrowButton {...sortColumnProps.byUsers}>
									{t("Number of users")}
								</SortingArrowButton>
							</th>
							<th>{t("Colour")}</th>
							<th className="w-0" />
						</tr>
					</thead>
					<tbody>
						{sortedGroups.map((accessGroup) => {
							return (
								<AccessGroupTableRow
									accessGroup={accessGroup}
									key={accessGroup.groupId}
									onDeleteGroup={(groupId) => {
										setGroupToDelete(groupId);
									}}
									onEditGroup={(groupId) => {
										setGroupToEdit(groupId);
									}}
								/>
							);
						})}
					</tbody>
				</PlanningTable>
			</PlanningTableContainer>
			<AddAccessGroupModal
				accessGroup={groupToEdit ? groupById[groupToEdit] : undefined}
				modalOpen={groupToEdit !== undefined}
				handleClose={() => {
					setGroupToEdit(undefined);
				}}
				companyId={companyId}
			/>
			<ConfirmDialog
				open={groupToDelete !== undefined}
				onClose={() => {
					setGroupToDelete(undefined);
				}}
				title={t("Remove group")}
				description={t("Do you want to delete the group?")}
				onConfirm={async () => {
					const result = await deleteAccessGroup({
						companyId,
						groupId: groupToDelete!,
					});
					if ("error" in result) {
						showErrorNotification({ message: t("Failed to delete the group") });
					} else {
						showSuccessNotification({ message: t("The group was deleted") });
						setGroupToDelete(undefined);
					}
				}}
				confirmButtonLabel={t("Remove group")}
				isLoading={isDeletingAccessGroup}
				destructive
			/>
		</>
	);
};
