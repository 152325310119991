import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../common/components/atoms/button/Button";
import {
	Modal,
	ModalActions,
	ModalTitle,
} from "../../../common/components/atoms/modal/Modal";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.js";

interface Props {
	isOpen: boolean;
	legalEntity?: LegalEntity | undefined;
	onConfirm: () => Promise<void>;
	onCancel: () => void;
}

export const RemoveCompanyFromHierarchyModal = ({
	isOpen,
	legalEntity,
	onCancel,
	onConfirm,
}: Props) => {
	const { t } = useTranslation();

	const [isRemoving, setIsRemoving] = useState(false);

	return (
		<Modal onClose={onCancel} open={isOpen}>
			<ModalTitle
				title={t("Delete legal entity")}
				description={`${t("Do you want to delete")} ${
					legalEntity?.name ?? t("Unknown")
				}`}
			/>
			<ModalActions>
				<Button variant="secondaryGray" onClick={onCancel}>
					{t("Cancel")}
				</Button>
				<Button
					className="ml-2"
					destructive={true}
					isLoading={isRemoving}
					onClick={async () => {
						setIsRemoving(true);
						try {
							await onConfirm();
						} catch (e) {
							showErrorNotification({
								message: t("Error"),
							});
						} finally {
							setIsRemoving(false);
						}
					}}
				>
					{t("Remove")}
				</Button>
			</ModalActions>
		</Modal>
	);
};
