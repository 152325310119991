import { type ComponentProps, forwardRef, type ReactNode } from "react";
import { createPolymorphicComponent } from "../../../utils/createPolymorphicComponent";
import { Text, type TextProps } from "../typography/Text";
import { clsx } from "clsx";
import { IconExternalLink } from "@tabler/icons-react";
import { PersistedLink } from "../../../persistentRouterFunctions";
import type { PartialBy } from "src/common/utils/PartialBy.js";

interface AnchorProps extends PartialBy<TextProps, "size"> {
	external?: boolean;
	noTruncate?: boolean;
	children?: ReactNode | undefined;
}

const _Anchor = forwardRef<HTMLAnchorElement, AnchorProps & { component: any }>(
	(
		{
			component,
			className,
			external = false,
			children,
			color = "text-purple-500",
			size = "sm",
			weight = "medium",
			noTruncate = false,
			...others
		},
		ref,
	) => {
		const childrenWithExternal = external ? (
			<span
				className="self-start overflow-hidden text-ellipsis whitespace-nowrap"
				title={typeof children === "string" ? children : undefined}
			>
				{children}
			</span>
		) : (
			children
		);

		return (
			<Text
				ref={ref}
				component={component ?? "a"}
				color={color}
				size={size}
				weight={weight}
				className={clsx(
					"inline-flex max-w-full cursor-pointer items-center gap-1 hover:underline",
					className,
				)}
				{...others}
			>
				<span className={clsx(!noTruncate && "truncate")}>
					{childrenWithExternal}
				</span>
				{external && <IconExternalLink className="shrink-0" size={16} />}
			</Text>
		);
	},
);

_Anchor.displayName = "Anchor";

export const Anchor = createPolymorphicComponent<"a", AnchorProps>(_Anchor);

interface ConditionalAnchorProps extends AnchorProps {
	to?: ComponentProps<typeof PersistedLink>["to"];
	children?: ReactNode | undefined;
}

/**
 * Light wrapper around `<Anchor>`. It uses `<PersistedLink>` if the `to` prop is truthy
 */
export const ConditionalAnchor = forwardRef<
	HTMLAnchorElement,
	ConditionalAnchorProps
>(({ to, children, ...other }, ref) => {
	return (
		<Anchor
			{...((to !== undefined
				? {
						component: PersistedLink,
						to,
					}
				: undefined) as ComponentProps<typeof Anchor>)}
			ref={ref}
			{...other}
		>
			{children}
		</Anchor>
	);
});

ConditionalAnchor.displayName = "ConditionalAnchor";
