import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LoadingState } from "../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../common/components/atoms/missingDataState/MissingDataState.js";
import { ProvideLegacyLegalEntities } from "../common/context/LegacyLegalEntityContext";
import { ProvideLegalEntityMode } from "../common/context/LegalEntityModeContext";
import { useCurrentCompanyDomainExists } from "../common/company-domain/useCurrentCompanyDomain.js";
import { useLegalEntities } from "../common/legal-entities/useLegalEntities.js";
import { Sidebar } from "../features/sidebar/Sidebar";
import { AppFrame, MainFrame } from "./ProtectedRoutes";
import { PipeLineProgressLoader } from "../features/pipelineProgressLoader/PipelineProgressLoader.js";
import { CompanySidebarPart } from "../features/sidebar/CompanySidebarPart.js";

const CompanyDomainContent = ({
	sidebarOpen,
}: {
	sidebarOpen?: boolean | undefined;
}) => {
	const { isFetching, isError } = useLegalEntities();

	if (isFetching) {
		return <LoadingState />;
	}

	if (isError) {
		return <MissingDataState />;
	}

	return (
		<ProvideLegacyLegalEntities>
			<ProvideLegalEntityMode>
				<AppFrame>
					<Sidebar
						defaultOpen={sidebarOpen}
						renderFooter={() => <PipeLineProgressLoader />}
						renderContent={({ sidebarOpen }) => (
							<CompanySidebarPart sidebarOpen={sidebarOpen} />
						)}
					/>
					<MainFrame>
						<Suspense fallback={<LoadingState />}>
							<Outlet />
						</Suspense>
					</MainFrame>
				</AppFrame>
			</ProvideLegalEntityMode>
		</ProvideLegacyLegalEntities>
	);
};

export const CompanyRouteLayout = ({
	sidebarOpen,
}: {
	sidebarOpen?: boolean;
}) => {
	const companyDomainExists = useCurrentCompanyDomainExists();

	if (!companyDomainExists) {
		return <Navigate to=".." />;
	}

	return <CompanyDomainContent sidebarOpen={sidebarOpen} />;
};
