import { clsx } from "clsx";

export const classByVariant: Record<
	ButtonVariant,
	(options: { destructive: boolean; borderless: boolean }) => string
> = {
	primary: ({ destructive, borderless }) => {
		const baseClasses = "shadow-sm text-white";
		if (destructive) {
			return clsx(
				baseClasses,
				"bg-error-600 hover:bg-error-700 disabled:bg-error-200",
			);
		} else {
			return clsx(
				baseClasses,
				"bg-purple-500 hover:bg-purple-700 focus-visible:bg-purple-600 disabled:bg-purple-200",
				!borderless && "outline-purple-50",
			);
		}
	},

	secondary: ({ destructive, borderless }) => {
		if (destructive) {
			return [
				"shadow-sm text-error-700 disabled:text-error-300",
				"bg-error-50 [&:not(:disabled)]:hover:bg-error-100",
				"outline-error-100",
			].join(" ");
		} else {
			let classes = [
				"shadow-sm text-purple-600 disabled:text-purple-300",
				"bg-purple-50 [&:not(:disabled)]:hover:bg-purple-100 disabled:bg-purple-25",
				"outline-purple-50",
			].join(" ");

			if (!borderless) {
				classes += " outline-purple-50";
			}
			return classes;
		}
	},

	secondaryGray: ({ destructive, borderless }) => {
		if (destructive) {
			const classes = [
				"shadow-sm text-error-700 [&:not(:disabled)]:hover:text-error-700 disabled:text-error-300",
				"border border-error-300 disabled:border-error-200",
				"bg-white [&:not(:disabled)]:hover:bg-error-50",
				"outline-error-100",
			];
			if (!borderless) {
				classes.push("border");
			}

			return classes.join(" ");
		} else {
			const classes = [
				'shadow-sm text-grey-700 [&:not(:disabled):not([aria-pressed="true"])]:hover:text-grey-800 disabled:text-grey-300 aria-pressed:text-purple-500',
				"border-grey-300 disabled:border-grey-200 aria-pressed:border-transparent",
				'bg-white [&:not(:disabled):not([aria-pressed="true"])]:hover:bg-grey-50 aria-pressed:bg-purple-50',
				"outline-grey-100",
			];

			if (!borderless) {
				classes.push(" border outline-grey-100");
			}

			return classes.join(" ");
		}
	},
	ghost({ destructive }) {
		if (destructive) {
			return "text-error-500 [&:not(:disabled)]:hover:bg-grey-50 disabled:opacity-50";
		} else {
			return "text-purple-500 [&:not(:disabled)]:hover:bg-black/10 disabled:opacity-50";
		}
	},
};

export const buttonPropertiesBySize: Record<
	ButtonSize,
	(options: { isIconButton: boolean }) => {
		className: string;
		iconSize: number;
	}
> = {
	lg: ({ isIconButton }) => {
		return {
			iconSize: 20,
			className: clsx(
				isIconButton
					? "size-11"
					: "px-[18px] text-[16px] leading-[24px] h-[44px] gap-2.5",
			),
		};
	},
	md: ({ isIconButton }) => {
		return {
			iconSize: 20,
			className: clsx(
				isIconButton
					? "w-[40px] h-[40px]"
					: "px-[16px] text-[14px] leading-[20px] h-[40px] gap-2.5",
			),
		};
	},
	sm: ({ isIconButton }) => {
		return {
			iconSize: 20,
			className: clsx(
				isIconButton
					? "w-[36px] h-[36px]"
					: "px-[14px] text-[14px] leading-[20px] h-[36px] gap-2.5",
			),
		};
	},
	xs: ({ isIconButton }) => {
		return {
			iconSize: 20,
			className: clsx(
				isIconButton
					? "size-6"
					: "px-[4px] text-[8px] leading-[20px] h-[24px] gap-2.5",
			),
		};
	},
};

export type ButtonVariant = "primary" | "secondary" | "secondaryGray" | "ghost";
export type ButtonSize = "sm" | "md" | "lg" | "xs";

export const DEFAULT_BUTTON_VARIANT: ButtonVariant = "primary";
export const DEFAULT_BUTTON_SIZE: ButtonSize = "md";
