import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import { useContext, type ComponentProps, type ReactElement } from "react";
import type { NavLink, To } from "react-router-dom";
import { PersistedNavLink } from "../../common/persistentRouterFunctions";
import { LinkItem } from "./LinkItem";
import { SidebarContext } from "./SidebarContext";
import { Tooltip2 } from "../../common/components/atoms/tooltip2/Tooltip2.tsx";
import { TooltipTrigger } from "../../common/components/atoms/tooltip2/TooltipTrigger.tsx";
import { TooltipContent } from "../../common/components/atoms/tooltip2/TooltipContent.tsx";

interface NavItemProps extends ComponentProps<typeof NavLink> {
	icon: ReactElement<IconProps>;
	label: string;
	to: To;
}

export const NavItem = ({
	icon,
	to,
	label,
	className,
	...other
}: NavItemProps) => {
	const { open } = useContext(SidebarContext);
	const linkClassName = clsx("block", className);
	if (typeof to === "string" && to.startsWith("http")) {
		const item = (
			<a href={to} className={linkClassName} aria-label={label}>
				<LinkItem
					isActive={false}
					icon={icon}
					label={open ? label : undefined}
				/>
			</a>
		);
		if (open) {
			return item;
		} else {
			return (
				<Tooltip2 placement="right">
					<TooltipTrigger asChild>{item}</TooltipTrigger>
					<TooltipContent>{label}</TooltipContent>
				</Tooltip2>
			);
		}
	}

	const item = (
		<PersistedNavLink
			to={to}
			className={linkClassName}
			{...other}
			aria-label={!open ? label : undefined}
		>
			{({ isActive }) => {
				return (
					<LinkItem
						isActive={isActive}
						icon={icon}
						label={open ? label : undefined}
					/>
				);
			}}
		</PersistedNavLink>
	);

	if (open) {
		return item;
	} else {
		return (
			<Tooltip2 placement="right">
				<TooltipTrigger asChild>{item}</TooltipTrigger>
				<TooltipContent>{label}</TooltipContent>
			</Tooltip2>
		);
	}
};
