import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../../common/components/atoms/notifications/events.js";
import { invariant } from "../../../../common/utils/invariant.js";
import { usePersistedNavigate } from "../../../../common/persistentRouterFunctions.js";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useCallback, useState } from "react";
import { DialogTrigger } from "components/dialog/DialogTrigger.js";
import { Button } from "components/button";
import { Text } from "../../../../common/components/atoms/typography/Text.js";
import { Dialog } from "components/dialog/Dialog";
import { DialogActions } from "components/dialog/DialogActions";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { ConnectorButton } from "../ConnectorButton.js";
import { QueryResult } from "components/queryResult/QueryResult.js";
import { MissingDataState } from "components/missingDataState/MissingDataState.js";
import {
	useCreateMergeAccountingTokenMutation,
	useGetMergeAccountingMagicLinkUrlQuery,
} from "src/common/service/fusionHub/integrations/mergeAccountingApi.js";

export const AddMergeAccountingConnectorModal = ({
	logo,
	name,
	slug,
	children,
}: {
	logo: string;
	name: string;
	slug: string;
	children?: React.ReactNode;
}) => {
	const { t } = useTranslation();
	const { companyId } = useParams();
	invariant(companyId);

	const navigate = usePersistedNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const magicLinkQuery = useGetMergeAccountingMagicLinkUrlQuery(
		{ companyDomainId: companyId, system: slug, createMagicLinkUrl: false },
		{ skip: !isModalOpen },
	);
	const [createMergeAccountingToken] = useCreateMergeAccountingTokenMutation();

	const handleCreateToken = useCallback(
		async (publicToken: string) => {
			const result = await createMergeAccountingToken({
				companyDomainId: companyId,
				publicToken,
				system: slug,
			});

			if ("error" in result) {
				showErrorNotification({ message: t("Failed to add data source") });
			} else {
				showSuccessNotification({ message: t("Data source was added") });
				navigate("./..");
			}
		},
		[companyId, createMergeAccountingToken, navigate, slug, t],
	);

	const {
		open: openMergeModal,
		isReady,
		error: mergeLinkError,
	} = useMergeLink({
		linkToken: magicLinkQuery.data?.magicLink.linkToken ?? undefined,
		onSuccess: handleCreateToken,
	});

	return (
		<Dialog onOpenChange={setIsModalOpen} open={isModalOpen}>
			<DialogTrigger asChild>
				{children ?? <ConnectorButton logo={logo} label={name} />}
			</DialogTrigger>
			<DialogContent size="md">
				<QueryResult
					query={magicLinkQuery}
					render={() => {
						if (mergeLinkError) {
							return <MissingDataState />;
						}

						return (
							<>
								<DialogTitle
									icon={
										<img
											src={logo}
											alt={`${slug} logotype`}
											width={60}
											height={60}
										/>
									}
								>
									{t("Add {{name}} as a data source", { name })}
									<Text size="sm" color="text-grey-500" className="mt-2">
										{t(
											"In order to add a {{name}} data source a user with the correct permissions has to sign in and approve the integration.",
											{ name },
										)}
									</Text>
								</DialogTitle>
								<DialogActions withCancelButton>
									<Button
										variant="primary"
										iconPosition="right"
										disabled={!isReady}
										onClick={openMergeModal}
									>
										{t("Continue")}
									</Button>
								</DialogActions>
							</>
						);
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
