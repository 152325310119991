import { clsx } from "clsx";
import type { ReactNode, ComponentPropsWithoutRef, ReactElement } from "react";
import { Chip } from "../chip/Chip";
import { Text } from "../typography/Text";
import type { IconProps } from "@tabler/icons-react";
import { PersistedNavLink } from "../../../persistentRouterFunctions.js";

interface TabLinkProps
	extends Omit<ComponentPropsWithoutRef<typeof PersistedNavLink>, "children"> {
	count?: number;
	icon?: ReactElement<IconProps>;
	children?: ReactNode | undefined;
}

export const TabLink = ({
	className,
	children,
	count,
	icon,
	...props
}: TabLinkProps) => {
	return (
		<PersistedNavLink
			className={({ isActive }) => {
				return clsx(
					"group block cursor-pointer appearance-none border-b px-1 pb-4 pt-0.5",
					isActive
						? "border-b-purple-500"
						: "border-b-transparent hover:border-b-purple-500",
					className,
				);
			}}
			{...props}
		>
			{({ isActive }) => {
				return (
					<div
						className={clsx(
							"flex items-center whitespace-nowrap",
							isActive
								? "text-purple-500"
								: "text-grey-500 group-hover:text-purple-500",
						)}
					>
						{icon && <div className="mr-1">{icon}</div>}
						<Text span size="sm" weight="medium" color="inherit">
							{children}
						</Text>
						{count !== undefined ? (
							<Chip
								size="small"
								className="ml-2"
								color={isActive ? "purple" : "grey"}
							>
								{count}
							</Chip>
						) : null}
					</div>
				);
			}}
		</PersistedNavLink>
	);
};
