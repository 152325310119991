import {
	Fragment,
	cloneElement,
	forwardRef,
	isValidElement,
	type ReactNode,
	type HTMLProps,
} from "react";
import { useMenuContext } from "./use-menu-context";

type MenuTargetProps = {
	children: ReactNode;
} & HTMLProps<HTMLElement>;

const isValidMenuTarget = (
	node: React.ReactNode,
): node is React.ReactElement => {
	if (!isValidElement(node)) return false;
	if (typeof node !== "object") return false;

	const { type } = node;

	return type !== Fragment;
};

export const MenuTarget = forwardRef<HTMLElement, MenuTargetProps>(
	({ children }: MenuTargetProps, _ref) => {
		const { open, getReferenceProps, refs } = useMenuContext();

		if (!isValidMenuTarget(children)) return null;

		return cloneElement(children, {
			open,
			type: "button",
			ref: refs.setReference,
			...getReferenceProps(),
		});
	},
);

MenuTarget.displayName = "MenuTarget";
