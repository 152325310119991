import { clsx } from "clsx";
import type { ComponentPropsWithoutRef, ReactElement } from "react";
import { Chip } from "../chip/Chip";
import { Text } from "../typography/Text";
import classes from "./Tab.module.css";
import type { IconProps } from "@tabler/icons-react";

interface TabProps<T extends React.ElementType> {
	as?: T;
	selected?: boolean;
	count?: number;
	children?: string | undefined;
	icon?: ReactElement<IconProps>;
}

export const Tab = <T extends React.ElementType = "button">({
	as,
	className,
	children,
	selected,
	count,
	icon,
	...props
}: TabProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof TabProps<T>>) => {
	const Component = as ?? "button";

	return (
		<Component
			role="tab"
			className={clsx(classes.tab, "group", className)}
			type="button"
			{...props}
			aria-selected={selected}
		>
			<div
				className={clsx(
					"flex items-center whitespace-nowrap",
					selected
						? "text-purple-500"
						: "text-grey-500 group-hover:text-purple-500",
				)}
			>
				{icon && <div className="mr-1">{icon}</div>}
				<Text span size="sm" weight="medium" color="inherit">
					{children}
				</Text>
				{count !== undefined ? (
					<Chip
						size="small"
						className="ml-2"
						color={selected ? "purple" : "grey"}
					>
						{count}
					</Chip>
				) : null}
			</div>
		</Component>
	);
};
