import {
	useFloatingTree,
	useListItem,
	useMergeRefs,
	type UseRoleProps,
} from "@floating-ui/react";
import { IconCheck, IconChevronRight } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	forwardRef,
	type ButtonHTMLAttributes,
	type DetailedHTMLProps,
	type ReactNode,
	type KeyboardEvent,
	type MouseEvent,
} from "react";
import { useMenuContext } from "./use-menu-context";
import { useTranslation } from "react-i18next";
import { useMenuComponentContext } from "./useMenuComponentContext.js";

export type MenuButtonProps = {
	selected?: boolean | undefined;
} & DetailedHTMLProps<
	ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>;

const getMenuButtonRole = (role: UseRoleProps["role"]) => {
	if ((["listbox", "dialog"] as UseRoleProps["role"][]).includes(role))
		return "option";

	return "menuitem";
};

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
	function MenuButton({ className, selected, children, ...props }, ref) {
		const role = getMenuButtonRole(useMenuContext().role);
		return (
			<button
				{...props}
				ref={ref}
				type="button"
				role={role}
				tabIndex={0}
				className={clsx(
					className,
					props.onClick
						? "cursor-pointer outline-0 hover:enabled:bg-purple-25 focus:enabled:bg-purple-25"
						: "cursor-default",
					"cursor flex h-10 w-full grow justify-between px-2 py-1 text-left first-of-type:pt-2 last-of-type:pb-2 disabled:text-grey-300",
					selected && "bg-purple-50",
				)}
			>
				{children}

				{selected && (
					<IconCheck size={20} className="shrink-0 text-purple-500" />
				)}
			</button>
		);
	},
);

const getSubMenuRole = (role: UseRoleProps["role"]) => {
	if ((["listbox", "dialog"] as UseRoleProps["role"][]).includes(role))
		return "group";

	return "menuitem";
};

type SubMenuButtonProps = {
	label: string;
	displayLabel?: ReactNode;
	onMenuClick?:
		| ((event: React.MouseEvent<HTMLButtonElement>) => void)
		| undefined;
	onClick?:
		| ((
				event: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLSpanElement>,
		  ) => void)
		| undefined;
} & MenuButtonProps;

export const SubMenuButton = forwardRef<HTMLElement, SubMenuButtonProps>(
	function SubMenuButton(
		{
			label,
			disabled,
			className,
			displayLabel,
			onMenuClick,
			onClick,
			...props
		},
		ref,
	) {
		const { t } = useTranslation();
		const item = useListItem();
		const parent = useMenuComponentContext();
		const tabIndex = parent.activeIndex === item.index ? 0 : -1;
		const tree = useFloatingTree();
		const role = getSubMenuRole(useMenuContext().role);

		const onSubMenuClick = onMenuClick
			? (event: React.MouseEvent<HTMLButtonElement>) => {
					event.stopPropagation();
					onMenuClick(event);
					tree?.events.emit("click");
				}
			: undefined;

		return (
			<div
				className={clsx("flex items-center", !disabled && "hover:bg-purple-25")}
			>
				<MenuButton
					{...props}
					onClick={onSubMenuClick}
					disabled={disabled}
					tabIndex={tabIndex}
					className={className}
					role={role}
					ref={useMergeRefs([item.ref, ref])}
				>
					{displayLabel ?? label}
					<span
						{...props}
						tabIndex={-1}
						onClick={(e) => {
							e.stopPropagation();
							onClick?.(e);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								e.stopPropagation();
								onClick?.(e);
							}
						}}
						aria-label={`${t("Open submenu")}: ${label}`}
						role="button"
						className={clsx(
							"ml-2 flex rounded-[8px] border border-transparent text-purple-500 transition duration-200 focus:bg-white",
						)}
					>
						<IconChevronRight />
					</span>
				</MenuButton>
			</div>
		);
	},
);

SubMenuButton.displayName = "SubMenuButton";
