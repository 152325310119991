import { forwardRef, type ReactNode } from "react";
import { FloatingTree, useMergeRefs } from "@floating-ui/react";
import { useMenuContext } from "./use-menu-context";
import { MenuComponent } from "./MenuComponent";

type MenuProps = {
	children: ReactNode;
	className?: string;
	preMenuItems?: ReactNode;
};

export const Menu = forwardRef<HTMLElement, MenuProps>(
	({ children, className, preMenuItems }, forwardRef) => {
		const { refs, ...rest } = useMenuContext();

		return (
			<FloatingTree>
				<MenuComponent
					isNested={false}
					ref={useMergeRefs([refs.setFloating, forwardRef])}
					className={className}
					preMenuItems={preMenuItems}
					{...rest}
				>
					{children}
				</MenuComponent>
			</FloatingTree>
		);
	},
);

Menu.displayName = "Menu";
