import type { YearMonthPickerValue, YearMonth } from "./YearMonthPicker";
import { isLowerThanOrEqualTo } from "./yearMonthUtils";
import { invariant } from "src/common/utils/invariant";

export const validateMinMaxValue = ({
	value,
	min,
	max,
}: {
	min?: YearMonth | undefined;
	max?: YearMonth | undefined;
	value: YearMonthPickerValue;
}) => {
	invariant(
		min !== undefined && max !== undefined
			? isLowerThanOrEqualTo(min, max)
			: true,
		"`min` can't be higher than `max`",
	);

	invariant(
		value && "start" in value
			? isLowerThanOrEqualTo(value.start, value.end)
			: true,
	);
};
