import { useEffect, useState } from "react";
import { Button } from "../../common/components/atoms/button/Button";
import { Alert } from "../../common/components/atoms/alert/Alert";
import { Text } from "components/typography/Text.js";
import { useErrorBoundary } from "react-error-boundary";
import { IconHome, IconReload, IconSwitchVertical } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Collapse } from "components/collapse/Collapse.js";
import { isProduction } from "src/env.js";
import { ButtonLink } from "../../common/components/atoms/button/ButtonLink.js";
import * as Sentry from "@sentry/react";

interface AppErrorProps {
	error: Error;
}

export function AppError({ error }: AppErrorProps) {
	useEffect(() => {
		window.stop();
		Sentry.captureException(error);
	}, [error]);

	const { resetBoundary } = useErrorBoundary();
	const { t } = useTranslation();
	const [openStack, setOpenStack] = useState(false);

	return (
		<div className="my-6 flex flex-col items-center gap-4">
			<Text size="md" weight="bold">
				{t("An error occured")}
			</Text>
			<Alert color="error" title={error.message} />
			{error.stack && (
				<>
					{isProduction && (
						<Button
							variant="secondaryGray"
							onClick={() => {
								setOpenStack((prev) => !prev);
							}}
							icon={<IconSwitchVertical />}
						>
							Toggle stacktrace
						</Button>
					)}
					<div>
						<Collapse in={!isProduction || openStack}>
							<pre>{error.stack}</pre>
						</Collapse>
					</div>
				</>
			)}

			<div className="flex gap-4">
				<ButtonLink to="/" variant="secondaryGray" icon={<IconHome />}>
					{t("Start page")}
				</ButtonLink>
				<Button
					onClick={() => {
						window.location.reload();
					}}
					variant="secondaryGray"
					icon={<IconReload />}
				>
					{t("Reload")}
				</Button>
				<Button
					onClick={() => {
						resetBoundary();
					}}
					variant="primary"
				>
					{t("Try again")}
				</Button>
			</div>
		</div>
	);
}
