import type {
	PaginatedParams,
	Builder,
	PaginatedResponse,
} from "../../types.js";
import type { FiscalYear } from "./types.js";

export const buildFiscalYearEndpoints = (builder: Builder) => ({
	getFiscalYearsForLegalEntity: builder.query<
		PaginatedResponse<FiscalYear>,
		{
			companyDomainId: string;
			legalEntityId: string;
		} & PaginatedParams
	>({
		query: ({ companyDomainId, legalEntityId, take, skip }) => {
			return {
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/fiscal-year`,
				params: {
					take,
					skip,
				},
			};
		},
		providesTags: (result, error, arg) => {
			if (error) {
				return ["FiscalYear"];
			} else {
				return [
					"FiscalYear",
					{
						type: "FiscalYear",
						id: arg.legalEntityId,
					},
				];
			}
		},
	}),
});
