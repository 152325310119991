import { QueriesResult } from "components/queryResult/QueriesResult";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain";
import {
	useGetCompanyConfigDlmQuery,
	useGetDlmDataObjectTypesQuery,
	useGetDlmRawSystemsQuery,
} from "src/common/redux/api/exopenApi";
import { useGetAllIntegrationsQuery as useFusionHubGetIntegrationsQuery } from "src/common/service/fusionHub/fusionHubApi";
import {
	useGetIntegrationsQuery,
	useGetLegalEntitiesQuery,
} from "src/common/service/nexus/nexusApi";
import { ChangeDlmRawPrefixModalButton } from "./components/dlm/ChangeDlmRawPrefixModalButton";
import { AddDlmDestinationModalButton } from "./components/dlm/AddDlmDestinationModalButton";
import { TriggerDlmFinanceDataRunModalButton } from "./components/dlm/TriggerDlmFinanceDataRunModalButton";
import { TriggerDlmRawDataRunModalButton } from "./components/dlm/TriggerDlmRawDataRunModalButton";
import { ResetDatabaseModalButton } from "./components/dlm/ResetDatabaseModalButton";
import { AddMissingTablesModalButton } from "./components/dlm/AddMissingTablesModalButton";
import { ChangeDlmFinanceDestinationSchemaModalButton } from "./components/dlm/ChangeDlmFinanceDestinationSchemaModalButton";
import { DlmLegalEntityTable } from "./components/dlm/DlmLegalEntityTable";
import { LoadingState } from "components/loadingState/LoadingState";
import { DlmIntegrationsTable } from "./components/dlm/DlmIntegrationsTable";
import { DeleteDlmDestinationButton } from "./components/dlm/DeleteDlmDestinationButton";
import { useGetMergecrmIntegrationsQuery } from "src/common/service/fusionHub/integrations/mergeCrmApi";

export const DLMTools = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();

	const {
		isLoading: dlmConfigIsLoading,
		data: dlmConfig,
		refetch: refetchDlmConfig,
	} = useGetCompanyConfigDlmQuery({
		companyDomainId,
	});

	return (
		<QueriesResult
			queries={{
				availableRawSystems: useGetDlmRawSystemsQuery(),
				availableDataObjectTypes: useGetDlmDataObjectTypesQuery(),
				legalEntities: useGetLegalEntitiesQuery(companyDomainId),
				fusionHubIntegrations: useFusionHubGetIntegrationsQuery({
					companyDomainId,
				}),
				fusionHubMergecrmIntegrations: useGetMergecrmIntegrationsQuery({
					companyDomainId,
				}),
				nexusIntegrations: useGetIntegrationsQuery({
					companyDomainId,
				}),
			}}
			render={({
				availableRawSystems,
				availableDataObjectTypes,
				legalEntities,
				fusionHubIntegrations,
				fusionHubMergecrmIntegrations,
				nexusIntegrations,
			}) => {
				if (dlmConfigIsLoading) {
					return <LoadingState />;
				}

				const availableSystems = availableRawSystems.map((system) => {
					return { label: system, value: system };
				});

				const financeDataObjectTypes = availableDataObjectTypes
					.filter((dataObject) => dataObject.mart === "Finance")
					.map((dataObject) => {
						return { label: dataObject.type, value: dataObject.type };
					});

				const rawDataObjectTypes = availableDataObjectTypes
					.filter((dataObject) => dataObject.mart !== "Finance")
					.map((dataObject) => {
						return {
							label: dataObject.type,
							value: dataObject.type,
							system: dataObject.mart,
						};
					});

				return (
					<>
						<div className="pb-12 pt-6">
							<div className="flex flex-wrap gap-4">
								<AddDlmDestinationModalButton
									companyDomainId={companyDomainId}
									defaultFinanceMart={dlmConfig?.financeMartEnabled}
									defaultRawMart={dlmConfig?.rawMartEnabled}
									onAddDestinationSuccess={() => location.reload()}
								/>
								<DeleteDlmDestinationButton
									companyDomainId={companyDomainId}
									disabled={!dlmConfig}
								/>
								<TriggerDlmFinanceDataRunModalButton
									companyDomainId={companyDomainId}
									disabled={!dlmConfig}
									dataObjects={financeDataObjectTypes}
								/>
								<TriggerDlmRawDataRunModalButton
									companyDomainId={companyDomainId}
									disabled={!dlmConfig}
									dataObjects={rawDataObjectTypes}
								/>
								<ResetDatabaseModalButton
									companyDomainId={companyDomainId}
									availableRawSystems={availableSystems}
									disabled={!dlmConfig}
								/>
								<AddMissingTablesModalButton
									companyDomainId={companyDomainId}
									availableRawSystems={availableSystems}
									disabled={!dlmConfig}
								/>
								<ChangeDlmFinanceDestinationSchemaModalButton
									companyDomainId={companyDomainId}
									currentDestinationSchema={dlmConfig?.dbSchema}
									disabled={!dlmConfig}
									onSetDestinationSchemaSuccess={refetchDlmConfig}
								/>
								<ChangeDlmRawPrefixModalButton
									companyDomainId={companyDomainId}
									currentRawPrefix={dlmConfig?.rawDbSchemaPrefix}
									disabled={!dlmConfig}
									onSetRawPrefixSuccess={refetchDlmConfig}
								/>
							</div>
						</div>
						{dlmConfig && (
							<DlmLegalEntityTable
								companyDomainId={companyDomainId}
								legalEntityMartConfigs={dlmConfig.legalEntityMarts}
								legalEntities={legalEntities}
								onSetMartConfigSuccess={refetchDlmConfig}
								defaultFinanceMart={dlmConfig.financeMartEnabled}
								dataObjects={financeDataObjectTypes}
							/>
						)}
						{dlmConfig && (
							<div className="pb-12 pt-12">
								<DlmIntegrationsTable
									companyDomainId={companyDomainId}
									rawMartsConfigs={dlmConfig.rawIntegrations}
									onSetMartConfigSuccess={refetchDlmConfig}
									defaultRawMart={dlmConfig.rawMartEnabled}
									dataObjects={rawDataObjectTypes}
									legalEntities={legalEntities}
									fusionHubIntegrations={fusionHubIntegrations.items}
									fusionHubMergecrmIntegrations={
										fusionHubMergecrmIntegrations.items
									}
									nexusIntegrations={nexusIntegrations}
								/>
							</div>
						)}
					</>
				);
			}}
		/>
	);
};
