import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Button } from "../button/Button.tsx";
import type { YearMonthPickerValue, YearMonth } from "./YearMonthPicker.tsx";
import {
	isMonthSelected,
	getMinMaxRange,
	isDateOutsideValidRange,
} from "./yearMonthCalendarPopoverUtils.ts";
import {
	isBetweenYearMonth,
	isSameYearMonth,
	isYearMonthLowerThan,
} from "./yearMonthUtils.ts";
import { useTranslation } from "react-i18next";
import { type MutableRefObject, forwardRef, useEffect, useState } from "react";
import { clamp } from "../../../utils/number-utils/clamp.ts";
import { Text } from "../typography/Text.tsx";
import { useLocale } from "../../../../locales/useLocale.ts";

export const YearMonthCalendar = forwardRef<
	HTMLDivElement,
	{
		min?: YearMonth | undefined;
		max?: YearMonth | undefined;
		range?: boolean | undefined;
		value: YearMonthPickerValue;
		onSelect: (value: YearMonthPickerValue) => void;
		clearable?: boolean | undefined;
		focusRef?: MutableRefObject<HTMLButtonElement | null> | undefined;
	}
>(function YearMonthCalendar(
	{ onSelect, value, max, min, range, clearable = false, focusRef },
	ref,
) {
	const { t } = useTranslation();
	const [year, setYear] = useState(() => {
		const now = new Date();
		const year = clamp(
			value
				? "start" in value
					? value.start.year
					: value.year
				: now.getFullYear(),
			min?.year ?? Number.NEGATIVE_INFINITY,
			max?.year ?? Number.POSITIVE_INFINITY,
		);
		return year;
	});

	const selectPrevious = () => {
		setYear((year) => {
			return year - 1;
		});
	};

	const selectNext = () => {
		setYear((year) => {
			return year + 1;
		});
	};

	const { format, getMonth } = useLocale();
	const [startMonth, setStartMonth] = useState<YearMonth>();
	const [hovered, setHovered] = useState<YearMonth | undefined>();

	useEffect(() => {
		if (hovered && hovered.year !== year) {
			setHovered(undefined);
		}
	}, [hovered, year]);

	return (
		<div className="space-y-2 p-4" ref={ref}>
			<div className="flex items-center gap-2">
				<Button
					variant="secondaryGray"
					size="sm"
					onClick={selectPrevious}
					ariaLabel={t("Previous year")}
					disabled={min ? year === min.year : false}
					icon={<IconChevronLeft />}
				/>
				{min && max && max.year - min.year >= 5 && !range ? (
					<select
						value={year}
						onChange={(event) => {
							const year = Number(event.target.value);
							setYear(year);
							if (value) {
								onSelect({ year, month: (value as YearMonth).month });
							}
						}}
						className="grow rounded-sm text-center focus:outline-none focus-visible:outline-4 focus-visible:outline-purple-50"
					>
						{Array.from({ length: max.year - min.year + 1 }, (_, index) => {
							const year = min.year + index;
							return (
								<option value={year} key={year}>
									{year}
								</option>
							);
						})}
					</select>
				) : (
					<Text size="sm" weight="medium" className="grow text-center">
						{format(new Date(year, 0), {
							year: "numeric",
						})}
					</Text>
				)}
				<Button
					variant="secondaryGray"
					size="sm"
					onClick={selectNext}
					ariaLabel={t("Next year")}
					disabled={max ? year === max.year : false}
					icon={<IconChevronRight />}
				/>
			</div>
			<div className="grid grid-cols-4 grid-rows-3 gap-y-2">
				{Array.from({ length: 12 }).map((_, index) => {
					const isSelected = isMonthSelected({
						month: index,
						startMonth,
						value,
						year,
					});

					const yearMonth = { year, month: index };

					const { minRange, maxRange } = getMinMaxRange({
						startMonth,
						usesRange: range,
						hoveredItem: hovered,
						value,
					});

					const isInRange =
						minRange &&
						maxRange &&
						isBetweenYearMonth(yearMonth, minRange, maxRange);

					const getTypeOfSquare = () => {
						if (startMonth) {
							if (hovered === undefined) {
								if (startMonth.year === year && startMonth.month === index) {
									return "both";
								}
							} else {
								const isCurrentHovered = isSameYearMonth(hovered, yearMonth);
								const isCurrentStartMonth = isSameYearMonth(
									startMonth,
									yearMonth,
								);
								if (isCurrentHovered || isCurrentStartMonth) {
									if (isYearMonthLowerThan(startMonth, hovered)) {
										return isCurrentStartMonth ? "start" : "end";
									} else if (isYearMonthLowerThan(hovered, startMonth)) {
										return isCurrentStartMonth ? "end" : "start";
									} else {
										return "both";
									}
								}
							}
						} else if (value) {
							if ("start" in value) {
								const isStart = isSameYearMonth(value.start, yearMonth);
								const isEnd = isSameYearMonth(value.end, yearMonth);

								if (isStart && isEnd) {
									return "both";
								} else if (isStart) {
									return "start";
								} else if (isEnd) {
									return "end";
								}
							} else {
								if (isSameYearMonth(value, yearMonth)) {
									return "both";
								}
							}
						}

						return "none";
					};

					const type = getTypeOfSquare();

					return (
						<button
							key={index}
							className={clsx(
								"p-2 text-gray-900",
								type !== "none"
									? "bg-purple-600 text-white"
									: "hover:enabled:bg-purple-200",
								(type === "both" || type === "start") && "rounded-l-md",
								(type === "both" || type === "end") && "rounded-r-md",
								"disabled:cursor-not-allowed disabled:text-gray-400",
								isInRange && "bg-purple-100",
								type === "none" && !isInRange && "rounded-md",
							)}
							aria-pressed={isSelected}
							onClick={() => {
								if (range) {
									if (startMonth) {
										let start = startMonth;
										let end = yearMonth;
										if (isYearMonthLowerThan(yearMonth, startMonth)) {
											start = yearMonth;
											end = startMonth;
										}
										onSelect({
											start,
											end,
										});
									} else {
										setStartMonth(yearMonth);
									}
								} else {
									onSelect(yearMonth);
								}
							}}
							onMouseEnter={() => {
								setHovered({ year, month: index });
							}}
							type="button"
							aria-label={getMonth(index, "long")}
							disabled={isDateOutsideValidRange({
								min,
								max,
								year,
								month: index,
							})}
							ref={index === 0 ? focusRef : undefined}
						>
							{getMonth(index, "short")}
						</button>
					);
				})}
			</div>

			{value && clearable && (
				<div className="text-center">
					<button
						onClick={() => {
							onSelect(null);
						}}
					>
						<Text size="sm" color="text-purple-500">
							{t("Clear")}
						</Text>
					</button>
				</div>
			)}
		</div>
	);
});
