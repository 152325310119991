import { useState } from "react";

interface UseStepsOptions {
	initialStep: number;
}

export const useSteps = ({ initialStep }: UseStepsOptions) => {
	const [activeStep, setActiveStep] = useState(initialStep);

	const nextStep = () => {
		setActiveStep((prev) => prev + 1);
	};

	const prevStep = () => {
		setActiveStep((prev) => prev - 1);
	};

	const reset = () => {
		setActiveStep(initialStep);
	};

	const setStep = (step: number) => {
		setActiveStep(step);
	};

	return { nextStep, prevStep, reset, setStep, activeStep };
};
