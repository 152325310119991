import {
	Auth0Provider,
	type Auth0ProviderOptions,
	useAuth0,
} from "@auth0/auth0-react";
import { type ReactNode, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useCompanyDomains } from "./common/company-domain/useCompanyDomains.js";
import { LoadingState } from "./common/components/atoms/loadingState/LoadingState.js";
import { MissingDataState } from "./common/components/atoms/missingDataState/MissingDataState.js";
import { ProvideAccessToken } from "./common/context/useAuth0AccessToken";
import { ProvideUser } from "./common/context/useUser";
import { usePersistedNavigate } from "./common/persistentRouterFunctions";
import { DeferAuth0 } from "./auth0.js";
import { SentryIdentity } from "./sentry.js";
import { auth0Domain, auth0ClientId, auth0Audience } from "./env.js";
import { EmailVerification } from "./features/emailVerification/EmailVerification.js";

const auth0ProviderConfig: Auth0ProviderOptions = {
	domain: auth0Domain,
	clientId: auth0ClientId,
	cacheLocation: "localstorage",
	authorizationParams: {
		audience: auth0Audience,
		redirect_uri: window.location.origin,
	},
};

const AUTH0_REQUIRED_EMAIL_VERIFICATION_MSG =
	"Please verify your email before logging in.";

const AuthenticatedContent = () => {
	const { isLoading, isError } = useCompanyDomains();

	if (isLoading) {
		return <LoadingState />;
	}

	if (isError) {
		return <MissingDataState />;
	}

	return <Outlet />;
};

function isKnownError(error?: Error) {
	if (!error) {
		return false;
	}

	switch (error.message) {
		case AUTH0_REQUIRED_EMAIL_VERIFICATION_MSG:
			return true;
		default:
			return false;
	}
}

const CheckLoggedIn = ({ children }: { children: ReactNode }) => {
	const { error, isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

	const hasRedirected = useRef(false);

	useEffect(() => {
		if (
			isLoading ||
			isAuthenticated ||
			isKnownError(error) ||
			hasRedirected.current
		) {
			return;
		}

		hasRedirected.current = true;

		loginWithRedirect({
			appState: { returnTo: location.href.replace(location.origin, "") },
		});
	}, [error, isAuthenticated, isLoading, loginWithRedirect]);

	if (error) {
		if (error.message === AUTH0_REQUIRED_EMAIL_VERIFICATION_MSG) {
			return <EmailVerification />;
		}
		return <MissingDataState />;
	}

	if (isLoading || !isAuthenticated) {
		return <LoadingState />;
	}

	return children;
};

export const ProtectedApp = () => {
	const navigate = usePersistedNavigate();

	return (
		<Auth0Provider
			{...auth0ProviderConfig}
			onRedirectCallback={(appState) => {
				const to = appState?.returnTo ?? window.location.pathname;
				navigate(to);
			}}
		>
			<CheckLoggedIn>
				<DeferAuth0 />
				<SentryIdentity />
				<ProvideAccessToken>
					<ProvideUser>
						<AuthenticatedContent />
					</ProvideUser>
				</ProvideAccessToken>
			</CheckLoggedIn>
		</Auth0Provider>
	);
};
