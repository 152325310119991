import { PopoverTrigger } from "../../common/components/atoms/popoverMenu/PopoverTrigger.js";
import { PopoverContent } from "../../common/components/atoms/popoverMenu/PopoverContent.js";
import { Popover } from "../../common/components/atoms/popoverMenu/Popover.js";
import { Text } from "../../common/components/atoms/typography/Text.js";
import { IconArrowNarrowRight, IconSelector } from "@tabler/icons-react";
import { AddCompany } from "../companyAdministration/AddCompany.js";
import { useMemo, useState } from "react";
import { useLegalEntities } from "../../common/legal-entities/useLegalEntities.js";
import { useCurrentLegalEntity } from "../../common/legal-entities/useCurrentLegalEntity.js";
import { clsx } from "clsx";
import { useLegalEntityHierarchy } from "../../common/legal-entities/useLegalEntityHierarchy.js";
import { t } from "i18next";
import { LegalEntityMode } from "../../common/context/legalEntityMode.js";
import { isDefined } from "../../common/utils/filters/isDefined.js";
import { useLegalEntityMode } from "../../common/context/legalEntityContextUtils.js";

export const LegalEntitySelect = () => {
	const { legalEntities } = useLegalEntities();
	const { legalEntity: currentLegalEntity, changeLegalEntity } =
		useCurrentLegalEntity();
	const { legalEntityMode } = useLegalEntityMode();
	const [open, setOpen] = useState(false);
	const { hasAnyCorporateGroup, hierarchy } = useLegalEntityHierarchy();

	const legalEntityList = useMemo(() => {
		const sortedLegalEntites = (legalEntities ?? []).slice();
		sortedLegalEntites.sort((a, b) => a.name.localeCompare(b.name));
		const legalEntityList = [
			hasAnyCorporateGroup()
				? {
						legalEntityMasterKey: "-1",
						name: t("Concern ({{name}})", {
							name: hierarchy?.hierarchy?.legalEntity.name ?? "",
						}),
					}
				: undefined,
			...sortedLegalEntites,
		]
			.filter(isDefined)
			.map((legalEntity) => {
				return {
					id: legalEntity.legalEntityMasterKey,
					name: legalEntity.name,
				};
			})
			.filter((legalEntity) => {
				return (
					legalEntity.id !==
					(legalEntityMode === LegalEntityMode.Corporate
						? "-1"
						: currentLegalEntity?.legalEntityMasterKey)
				);
			});

		return legalEntityList;
	}, [
		currentLegalEntity?.legalEntityMasterKey,
		hasAnyCorporateGroup,
		hierarchy?.hierarchy?.legalEntity.name,
		legalEntities,
		legalEntityMode,
	]);

	const currentSelectedLabel =
		legalEntityMode === LegalEntityMode.Corporate && hierarchy?.hierarchy
			? t("Concern ({{name}})", {
					name: hierarchy.hierarchy.legalEntity.name,
				})
			: currentLegalEntity?.name;

	return (
		<>
			<AddCompany
				open={open}
				onClose={() => {
					setOpen(false);
				}}
			/>
			<Popover role="menu" withArrow={false}>
				<PopoverTrigger asChild>
					<button
						type="button"
						className="flex w-full items-center gap-2 rounded-lg bg-gray-100 py-2 pl-4 pr-2 text-left hover:bg-gray-200 aria-expanded:bg-gray-200"
					>
						<Text size="sm" className="grow">
							{currentSelectedLabel}
						</Text>
						<IconSelector className="text-purple-500" size={18} />
					</button>
				</PopoverTrigger>
				<PopoverContent className="w-96">
					{({ close }) => {
						return (
							<Popover.ContentContainer>
								<div
									className={clsx(
										"p-2 pl-4",
										legalEntityList.length > 0 && "border-b",
									)}
								>
									<Text className="grow" weight="medium" size="sm">
										{currentSelectedLabel}
									</Text>
								</div>
								{legalEntityList.length > 0 && (
									<div className="flex max-h-96 flex-col overflow-auto">
										{legalEntityList.map((legalEntity) => {
											return (
												<button
													key={legalEntity.id}
													className="group flex items-center gap-2 border-b px-3 py-2 text-left text-gray-700 last:border-b-0 hover:bg-gray-100"
													onClick={() => {
														changeLegalEntity({
															legalEntityMasterKey:
																legalEntity.id === "-1" && hierarchy?.hierarchy
																	? hierarchy.hierarchy.legalEntity
																			.legalEntityMasterKey
																	: legalEntity.id,
															mode:
																legalEntity.id === "-1"
																	? LegalEntityMode.Corporate
																	: LegalEntityMode.Company,
														});
														close();
													}}
												>
													<Text className="grow truncate" size="sm">
														{legalEntity.name}
													</Text>
													<IconArrowNarrowRight
														className="shrink-0 text-gray-500 opacity-0 group-hover:opacity-100"
														size={20}
													/>
												</button>
											);
										})}
									</div>
								)}
							</Popover.ContentContainer>
						);
					}}
				</PopoverContent>
			</Popover>
		</>
	);
};
