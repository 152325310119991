import { useMemo, useState } from "react";
import type { SortingArrowButtonProps } from "../../../features/planning/components/SortingArrowButton";

const useCycle = <T>(values: T[], defaultIndex?: number) => {
	const [index, setIndex] = useState(defaultIndex ?? 0);

	return [
		values[index],
		() => {
			setIndex(index < values.length - 1 ? index + 1 : 0);
		},
		(value: T) => {
			const index = values.indexOf(value);
			if (index !== -1) {
				setIndex(index);
			}
		},
	] as const;
};

const compareObjectsByKey = <T>(key: keyof T, ascending = true) => {
	return (objectA: T, objectB: T) => {
		const sortValue =
			objectA[key] > objectB[key] ? 1 : objectA[key] < objectB[key] ? -1 : 0;
		return ascending ? sortValue : -1 * sortValue;
	};
};

const compareObjectsBySortFn = <T>(
	ascending = true,
	customFn: (a: T, b: T) => number,
) => {
	return (objectA: T, objectB: T) => {
		const sortValue = customFn(objectA, objectB);
		return ascending ? sortValue : -1 * sortValue;
	};
};

export const stringSort = (
	a: string | undefined | null,
	b: string | undefined | null,
) => {
	return (a ?? "").toLowerCase().localeCompare((b ?? "").toLowerCase());
};

export const dateStringSort = (a: string | null, b: string | null) => {
	return (a ? new Date(a).getTime() : 0) - (b ? new Date(b).getTime() : 0);
};

export const makeDateStringSort = <T extends string>(key: T) => {
	return <
		U extends {
			[Property in T]: string | null;
		},
	>(
		a: U,
		b: U,
	) => {
		return dateStringSort(a[key], b[key]);
	};
};

export const makeStringSort = <T extends string>(key: T) => {
	return <
		U extends {
			[Property in T]: string;
		},
	>(
		a: U,
		b: U,
	) => {
		return stringSort(a[key], b[key]);
	};
};

export const makeNullableStringSort = <T extends string>(key: T) => {
	return <
		U extends {
			[Property in T]: string | null | undefined;
		},
	>(
		a: U,
		b: U,
	) => {
		const aVal = a[key];
		const bVal = b[key];

		if (typeof aVal === "string" && typeof bVal === "string") {
			return stringSort(aVal, bVal);
		}

		if (typeof aVal === "string" && typeof bVal !== "string") {
			return -1;
		}

		if (typeof aVal !== "string" && typeof bVal === "string") {
			return 1;
		}

		return 0;
	};
};

export const makeNumberSort = <T extends string>(key: T) => {
	return <
		U extends {
			[Property in T]: number;
		},
	>(
		a: U,
		b: U,
	) => {
		return a[key] - b[key];
	};
};

export const makeArrayLengthSort = <T extends string>(key: T) => {
	return <
		U extends {
			[Property in T]: unknown[];
		},
	>(
		a: U,
		b: U,
	) => {
		return a[key].length - b[key].length;
	};
};

export interface UseSortColumnProps {
	order: SortingArrowButtonProps["order"];
	onClick: SortingArrowButtonProps["onClick"];
	selected: SortingArrowButtonProps["selected"];
}

export interface UseSortProps<Keys extends string, T> {
	keys: Keys[];
	defaultKey?: Keys | undefined;
	defaultOrder: "asc" | "desc";
	rows: T[];
	columns: {
		[key in Keys]:
			| { key: keyof T; sort?: never }
			| { key?: never; sort: (a: T, b: T) => number };
	};
}

export type SortColumnRecord<Keys extends string> = Record<
	Keys,
	UseSortColumnProps
>;

interface SortState<Keys extends string, T> {
	sortKey: Keys | undefined;
	sortDirection: "asc" | "desc";
	items: T[];
	handleChangeSort: (key: Keys) => void;
	sortColumnProps: Record<Keys, UseSortColumnProps>;
}

export const useSort = <Keys extends string, T>({
	keys,
	defaultKey,
	defaultOrder,
	columns,
	rows,
}: UseSortProps<Keys, T>): SortState<Keys, T> => {
	const [sortDirection, cycle, setSortDirection] = useCycle<"asc" | "desc">(
		["desc", "asc"],
		defaultOrder === "desc" ? 0 : 1,
	);
	const [sortKey, setSortKey] = useState<Keys | undefined>(defaultKey);

	for (const key of Object.keys(columns)) {
		if (!keys.includes(key as any)) {
			throw new Error(`key ${key} is not in list ${keys}`);
		}
	}

	const sorted = useMemo(() => {
		if (!sortKey) {
			return rows;
		}
		const sortedData = [...rows];
		const column = columns[sortKey];
		sortedData.sort(
			column.sort
				? compareObjectsBySortFn(sortDirection === "asc", column.sort)
				: compareObjectsByKey(column.key, sortDirection === "asc"),
		);
		return sortedData;
	}, [rows, sortDirection, sortKey, columns]);

	const sortClickHandler = (key: Keys) => {
		if (sortKey !== key) {
			setSortDirection("desc");
			setSortKey(key);
		} else {
			cycle();
		}
	};

	const sortColumnProps = (
		Object.keys(columns) as Array<keyof typeof columns>
	).reduce<Record<keyof typeof columns, UseSortColumnProps>>(
		(acc, key) => {
			acc[key] = {
				onClick: () => {
					sortClickHandler(key);
				},
				order: sortKey === key ? sortDirection : "desc",
				selected: sortKey === key,
			};
			return acc;
		},
		{} as unknown as Record<keyof typeof columns, UseSortColumnProps>,
	);

	return {
		handleChangeSort: sortClickHandler,
		items: sorted,
		sortKey,
		sortDirection,
		sortColumnProps,
	};
};
