import { useEffect, useState } from "react";
import debounce from "lodash.debounce";

type WindowReszedCallback = (width: number) => void;

const subscribers: WindowReszedCallback[] = [];

const notifySubscribers = debounce(() => {
	subscribers.forEach((subscriber) => subscriber(window.innerWidth));
}, 100);

export const usePageWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		if (subscribers.length === 0) {
			window.addEventListener("resize", notifySubscribers);
		}

		subscribers.push(setWidth);

		return () => {
			subscribers.splice(subscribers.indexOf(setWidth), 1);
			if (subscribers.length === 0) {
				window.removeEventListener("resize", notifySubscribers);
			}
		};
	}, [setWidth]);

	return width;
};
