import { Button } from "../../../common/components/atoms/button/Button";
import { Tooltip } from "../../../common/components/atoms/tooltip/Tooltip";

export const TooltipPresentation = () => {
	return (
		<div className="flex gap-[60px] p-[60px]">
			{[true, false].map((dark) => {
				return (
					<div className="grid grid-cols-5 gap-4" key={`${dark}`}>
						<div />
						<Tooltip label="Tooltip" placement="top-start" dark={dark}>
							<Button variant="ghost">Top start</Button>
						</Tooltip>
						<Tooltip label="Tooltip" placement="top" dark={dark}>
							<Button variant="ghost">Top</Button>
						</Tooltip>
						<Tooltip label="Tooltip" placement="top-end" dark={dark}>
							<Button variant="ghost">Top end</Button>
						</Tooltip>
						<div />
						<Tooltip label="Tooltip" placement="left-start" dark={dark}>
							<Button variant="ghost">Left start</Button>
						</Tooltip>
						<div />
						<div />
						<div />
						<Tooltip label="Tooltip" placement="right-start" dark={dark}>
							<Button variant="ghost">Right start</Button>
						</Tooltip>
						<Tooltip label="Tooltip" placement="left" dark={dark}>
							<Button variant="ghost">Left</Button>
						</Tooltip>
						<div />
						<div />
						<div />
						<Tooltip label="Tooltip" placement="right" dark={dark}>
							<Button variant="ghost">Right</Button>
						</Tooltip>
						<Tooltip label="Tooltip" placement="left-end" dark={dark}>
							<Button variant="ghost">Left end</Button>
						</Tooltip>
						<div />
						<div />
						<div />
						<Tooltip label="Tooltip" placement="right-end" dark={dark}>
							<Button variant="ghost">Right end</Button>
						</Tooltip>
						<div />
						<Tooltip label="Tooltip" placement="bottom-start" dark={dark}>
							<Button variant="ghost">Bottom start</Button>
						</Tooltip>
						<Tooltip label="Tooltip" placement="bottom" dark={dark}>
							<Button variant="ghost">Bottom</Button>
						</Tooltip>
						<Tooltip label="Tooltip" placement="bottom-end" dark={dark}>
							<Button variant="ghost">Bottom end</Button>
						</Tooltip>
						<div />
					</div>
				);
			})}
		</div>
	);
};
