import { useTranslation } from "react-i18next";
import { useSetLegalEntityConfigurationDlmMutation } from "../../../../common/redux/api/exopenApi.ts";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../../../planning/components/PlanningTable.tsx";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { Button } from "../../../../common/components/atoms/button/Button.js";
import { TriggerDlmFinanceDataRunModalButton } from "./TriggerDlmFinanceDataRunModalButton.js";
import { SwitchField } from "components/checkbox/SwitchField.js";
import type { DlmLegalEntityMartConfig } from "src/common/redux/api/exopenApiTypes.js";
import isEqual from "lodash.isequal";
import { useState } from "react";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events.js";
import { Chip } from "components/chip/Chip.js";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.ts";
import { DlmMarts } from "./dlmMarts.ts";
import { ForceWriteParquetDlmButton } from "./ForceWriteParquetDlmButton.tsx";

interface Props {
	companyDomainId: string;
	legalEntityMartConfigs: DlmLegalEntityMartConfig[];
	legalEntities: LegalEntity[];
	defaultFinanceMart: boolean;
	dataObjects: { label: string; value: string }[];
	onSetMartConfigSuccess?: () => void;
}

export const DlmLegalEntityTable = ({
	companyDomainId,
	legalEntityMartConfigs,
	legalEntities,
	onSetMartConfigSuccess,
	defaultFinanceMart,
	dataObjects,
}: Props) => {
	const { t } = useTranslation();

	const [martConfigs, setMartConfigs] = useState<DlmLegalEntityMartConfig[]>(
		[],
	);

	const [
		setLegalEntityConfigurationDlm,
		{ isLoading: isSettingLegalEntityConfig },
	] = useSetLegalEntityConfigurationDlmMutation();

	const currentMartConfigs = legalEntities.map((le) => {
		const config = legalEntityMartConfigs.find(
			(leConf) => leConf.legalEntityId === le.id,
		);

		return {
			legalEntityId: le.id,
			financeMartEnabled: config?.financeMartEnabled ?? defaultFinanceMart,
		};
	});

	if (legalEntities.length > 0 && !martConfigs.length) {
		setMartConfigs([...currentMartConfigs]);
	}

	const handleMartSwitch = (mart: DlmMarts, legalEntityId: string) => {
		setMartConfigs([
			...martConfigs.map((conf) =>
				conf.legalEntityId !== legalEntityId
					? conf
					: {
							legalEntityId: conf.legalEntityId,
							financeMartEnabled:
								mart === DlmMarts.Finance
									? !conf.financeMartEnabled
									: conf.financeMartEnabled,
						},
			),
		]);
	};

	const getNonDefaultMartConfigs = () =>
		martConfigs.filter(
			(conf) => conf.financeMartEnabled !== defaultFinanceMart,
		);

	const martConfigHasChanged = () => {
		return !isEqual(legalEntityMartConfigs, getNonDefaultMartConfigs());
	};

	return (
		<>
			<PlanningTableContainer>
				<PlanningTable>
					<thead>
						<tr>
							<th>{t("Legal entity")}</th>
							<th />
							<th />
							<th />
							<th className="w-0" />
						</tr>
					</thead>
					<tbody>
						{legalEntities.map((legalEntity) => {
							const currentConfig = currentMartConfigs.find(
								(leConfig) => leConfig.legalEntityId === legalEntity.id,
							);
							const stateConfig = martConfigs.find(
								(leConfig) => leConfig.legalEntityId === legalEntity.id,
							);

							return (
								<tr key={legalEntity.id}>
									<td>
										{legalEntity.name}{" "}
										{currentConfig?.financeMartEnabled ===
											defaultFinanceMart && (
											<Chip size="small" color="primary" className="ml-4">
												{t("DEFAULT")}
											</Chip>
										)}
										{stateConfig?.financeMartEnabled !==
											currentConfig?.financeMartEnabled && (
											<Chip size="small" color="warning" className="ml-2">
												{t("UNSAVED")}
											</Chip>
										)}
									</td>
									<td className="w-1/5">
										<SwitchField
											label={DlmMarts.Finance}
											checked={
												stateConfig?.financeMartEnabled ?? defaultFinanceMart
											}
											onChange={() => {
												handleMartSwitch(DlmMarts.Finance, legalEntity.id);
											}}
										/>
									</td>
									<td className="w-1/12">
										<TriggerDlmFinanceDataRunModalButton
											companyDomainId={companyDomainId}
											legalEntityId={legalEntity.id}
											dataObjects={dataObjects}
										/>
									</td>
									<td className="w-1/12">
										<ForceWriteParquetDlmButton
											companyDomainId={companyDomainId}
											integrationOrLegalEntityId={legalEntity.id}
											mart={DlmMarts.Finance}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</PlanningTable>
			</PlanningTableContainer>
			<div className="mt-4">
				<Button
					isLoading={isSettingLegalEntityConfig}
					disabled={!martConfigHasChanged()}
					onClick={async () => {
						const result = await setLegalEntityConfigurationDlm({
							companyDomainId,
							legalEntityMartSettings: getNonDefaultMartConfigs(),
						});
						if ("error" in result) {
							showErrorNotification({
								message: t("Something failed..."),
							});
						} else {
							showSuccessNotification({
								message: t("Changes successfully implemented"),
							});

							if (onSetMartConfigSuccess) onSetMartConfigSuccess();
						}
					}}
					icon={<IconDeviceFloppy />}
				>
					{t("Save new legal entity configurations")}
				</Button>
			</div>
		</>
	);
};
