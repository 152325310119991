import { Temporal } from "@js-temporal/polyfill";
import type { YearMonth } from "./YearMonthPicker";

export const isLowerThanOrEqualTo = (a: YearMonth, b: YearMonth) => {
	return a.year < b.year || (a.year === b.year && a.month <= b.month);
};

export const isLargerThanOrEqualTo = (a: YearMonth, b: YearMonth) => {
	return a.year > b.year || (a.year === b.year && a.month >= b.month);
};

export const isYearMonthLowerThan = (a: YearMonth, b: YearMonth) => {
	return a.year < b.year || (a.year === b.year && a.month < b.month);
};

// Check if a is larger than b
export const isYearMonthLargerThan = (a: YearMonth, b: YearMonth) => {
	return a.year > b.year || (a.year === b.year && a.month > b.month);
};

export const dateToYearMonth = (date: Date): YearMonth => {
	return { year: date.getFullYear(), month: date.getMonth() };
};

export const dateFromYearMonth = (yearMonth: YearMonth) => {
	return new Date(yearMonth.year, yearMonth.month);
};

export const plainDateFromYearMonth = (yearMonth: YearMonth) => {
	return Temporal.PlainDate.from({
		year: yearMonth.year,
		month: yearMonth.month + 1,
		day: 1,
	});
};

export const isBetweenYearMonth = (
	value: YearMonth,
	min: YearMonth,
	max: YearMonth,
) => {
	return isLargerThanOrEqualTo(value, min) && isLowerThanOrEqualTo(value, max);
};

export const isDateBetweenYearMonth = (
	date: Date,
	min: YearMonth,
	max: YearMonth,
) => {
	const { year, month } = dateToYearMonth(date);
	const isHigherThanOrEqualToMin =
		year > min.year || (year === min.year && month >= min.month);
	const isLowerThanOrEqualToMax =
		year < max.year || (year === max.year && month <= max.month);
	return isHigherThanOrEqualToMin && isLowerThanOrEqualToMax;
};

export const minYearMonth = (a: YearMonth, b: YearMonth) => {
	if (a.year < b.year) {
		return a;
	} else if (b.year < a.year) {
		return b;
	} else {
		if (a.month < b.month) {
			return a;
		} else {
			return b;
		}
	}
};

export const maxYearMonth = (a: YearMonth, b: YearMonth) => {
	if (a.year > b.year) {
		return a;
	} else if (b.year > a.year) {
		return b;
	} else {
		if (a.month > b.month) {
			return a;
		} else {
			return b;
		}
	}
};

export const isSameYearMonth = (a: YearMonth, b: YearMonth) => {
	return a.year === b.year && a.month === b.month;
};

export const differenceInMonthsYearMonth = (a: YearMonth, b: YearMonth) => {
	if (a.year === b.year) {
		return b.month - a.month;
	} else {
		let diff = (b.year - a.year) * 12;
		diff -= a.month;
		diff += b.month;
		return diff;
	}
};

export const numberOfMonthsBetween = (a: YearMonth, b: YearMonth) => {
	return differenceInMonthsYearMonth(a, b) + 1;
};
