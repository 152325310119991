import { IconCalendar, IconX } from "@tabler/icons-react";
import { clsx } from "clsx";
import { type MutableRefObject, forwardRef, useEffect } from "react";
import { Text } from "../typography/Text.tsx";
import { PopoverContent } from "components/popoverMenu/PopoverContent.js";
import { PopoverTrigger } from "components/popoverMenu/PopoverTrigger.js";
import { Popover } from "components/popoverMenu/Popover.js";
import { useFormattedYearMonthValue } from "./useFormattedYearMonthValue.tsx";
import { YearMonthCalendar } from "./YearMonthCalendar.tsx";
import {
	isYearMonthLargerThan,
	isYearMonthLowerThan,
} from "./yearMonthUtils.ts";
import { validateMinMaxValue } from "./validateMinMaxValue.tsx";

export type YearMonthPickerValue =
	| {
			start: YearMonth;
			end: YearMonth;
	  }
	| YearMonth
	| null;

export type YearMonth = { year: number; month: number };

export type YearMonthPickerProps = {
	onChange: (value: YearMonthPickerValue) => void;
	min?: YearMonth | undefined;
	max?: YearMonth | undefined;
	range?: boolean | undefined;
	value: YearMonthPickerValue;
	clearable?: boolean | undefined;
	placeholder: string;
	variant?: "primary" | "ghost" | undefined;
	disabled?: boolean | undefined;
	ariaInvalid?: boolean | undefined;
	withIcon?: boolean | undefined;
} & (
	| {
			ariaLabelledby: string;
			ariaLabel?: never;
	  }
	| { ariaLabelledby?: never; ariaLabel: string }
);

export const YearMonthPicker = forwardRef<HTMLDivElement, YearMonthPickerProps>(
	function YearMonthCalendarPopover(
		{
			min,
			max,
			value,
			range = false,
			clearable = false,
			placeholder,
			ariaInvalid,
			ariaLabelledby,
			ariaLabel,
			disabled,
			variant = "primary",
			withIcon = true,
			onChange,
		},
		ref,
	) {
		useEffect(() => {
			validateMinMaxValue({ min, max, value });
		}, [max, min, value]);

		const yearMonthFormattedValue = useFormattedYearMonthValue(value);
		const showClearButton = clearable && value !== null;

		return (
			<Popover
				role="dialog"
				placement="bottom-start"
				withArrow={false}
				disabled={disabled}
			>
				<PopoverTrigger asChild>
					<div
						ref={ref}
						className={clsx(
							"group flex h-[40px] w-full cursor-pointer select-none items-center gap-2 border-gray-300 bg-white text-left transition-colors hover:bg-gray-50 focus:outline-none focus-visible:border-purple-400 focus-visible:ring-4 focus-visible:ring-purple-100 aria-disabled:cursor-not-allowed aria-disabled:bg-gray-50 aria-[invalid=true]:border-red-300 focus-visible:aria-[invalid=true]:ring-red-100",
							variant === "primary"
								? "rounded-lg border shadow-sm"
								: "ring-inset",
							withIcon ? "pl-3" : "pl-4",
							showClearButton ? "pr-2" : "pr-4",
						)}
						aria-labelledby={ariaLabelledby}
						aria-label={ariaLabel}
						aria-disabled={disabled}
						aria-invalid={ariaInvalid}
						tabIndex={disabled ? -1 : 0}
					>
						{withIcon && (
							<IconCalendar
								size={18}
								className="shrink-0 text-purple-500 group-aria-invalid:text-red-500"
							/>
						)}
						<Text
							size="sm"
							className="grow truncate"
							color="group-aria-invalid:text-red-500 group-aria-disabled:text-gray-500"
						>
							{yearMonthFormattedValue ?? placeholder}
						</Text>
						{showClearButton && (
							<button
								onClick={(event) => {
									onChange(null);
									event.stopPropagation();
								}}
								className="rounded-md p-1 hover:bg-purple-50"
							>
								<IconX
									size={18}
									className="shrink-0 text-purple-500 group-aria-invalid:text-red-500"
								/>
							</button>
						)}
					</div>
				</PopoverTrigger>
				<PopoverContent>
					{({ close, initialElementRef }) => {
						return (
							<Popover.ContentContainer>
								<YearMonthCalendar
									clearable={clearable}
									range={range}
									onSelect={(value) => {
										if (value) {
											const isRangeValue = "start" in value;
											const start = isRangeValue ? value.start : value;
											const end = isRangeValue ? value.end : value;
											if (min && isYearMonthLowerThan(start, min)) return;
											if (max && isYearMonthLargerThan(end, max)) return;
										}
										onChange(value);
										close();
									}}
									value={value}
									max={max}
									min={min}
									focusRef={
										initialElementRef as MutableRefObject<HTMLButtonElement | null>
									}
								/>
							</Popover.ContentContainer>
						);
					}}
				</PopoverContent>
			</Popover>
		);
	},
);
