import type { PaginatedResponse, Builder } from "../../types.js";
import { loadAllPages } from "../../utils/loadAllPages.js";
import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes.js";
import type {
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery.js";
import type { Integration } from "./types.js";

export const buildIntegrationEndpoints = (builder: Builder) => ({
	getIntegrations: builder.query<
		Integration[],
		{
			companyDomainId: string;
		}
	>({
		queryFn({ companyDomainId }, api, extraOptions, baseQuery) {
			return loadAllPages(({ take, skip }) => {
				return baseQuery({
					url: `/v1/company-domain/${companyDomainId}/integration`,
					params: {
						take,
						skip,
					},
				}) as Promise<
					QueryReturnValue<
						PaginatedResponse<Integration>,
						FetchBaseQueryError,
						FetchBaseQueryMeta
					>
				>;
			});
		},
		providesTags: (result, error, arg) => [
			{ type: "Integration", id: arg.companyDomainId },
		],
	}),

	addIntegrationToLegalEntity: builder.mutation<
		Integration,
		{
			companyDomainId: string;
			legalEntityId: string;
			integrationId: string;
		}
	>({
		query: ({ companyDomainId, legalEntityId, integrationId }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/integration`,
			method: "POST",
			body: {
				integrationId,
			},
		}),
		invalidatesTags: (result, error, args) => [
			{ type: "Integration", id: args.companyDomainId },
		],
	}),
});
