import { useTranslation } from "react-i18next";
import { IconCopy } from "@tabler/icons-react";
import { Input } from "components/input/Input";
import { Button } from "components/button";
import { showSuccessNotification } from "components/notifications/events";
import { Display } from "components/typography/Display";
import { Text } from "components/typography/Text";

export const AddFortnoxConnectorExternalRedirect = () => {
	const { t } = useTranslation();

	const url = window.location.href.replace("external", "redirect");

	return (
		<main className="min-h-screen grow overflow-x-hidden">
			<div className="mx-auto mt-20 max-w-[400px]">
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					className="mx-auto mb-6"
				>
					<path
						className="fill-primary-400"
						d="M26.3171 14.8625L24.2727 17.5636L16.9767 6.95563H8.11418L19.9091 23.5222L15.8858 28.9767C14.7527 30.6517 13.3736 32.1463 11.7949 33.4101C10.3222 34.56 8.32145 35.1818 6 35.1818V41.9999C14.7993 41.9999 18.6109 37.621 21.7396 33.1374L32.1818 19.0233C33.312 17.3466 34.6915 15.8523 36.2727 14.592C37.7454 13.44 39.6785 12.8182 41.9999 12.8182V6C33.2007 6 29.448 10.3789 26.3171 14.8625Z"
					/>
					<path
						className="fill-primary-400"
						d="M41.9998 41.0466L31.2674 26.0989C30.5678 26.3714 29.9115 26.744 29.319 27.2051C27.9898 28.2607 26.8051 29.4865 25.7954 30.8509L32.8623 41.0466H41.9998Z"
					/>
				</svg>

				<Display
					className="mb-3 text-center text-grey-900"
					size="sm"
					weight="bold"
				>
					{t("Add data source")}
				</Display>
				<Text
					className="mb-8 text-center"
					color="text-grey-500"
					size="md"
					weight="regular"
				>
					{t(
						"Copy the link below and send to your contact with an Exopen account",
					)}
				</Text>
				<div className="rounded-[12px] bg-white px-10 py-8 shadow-[0px_4px_8px_-2px_rgba(16,24,40,0.1),_0px_2px_4px_-2px_rgba(16,24,40,0.06)]">
					<div className="flex items-center">
						<Input ariaLabel="redirectUrl" defaultValue={url} disabled />
						<Button
							onClick={async () => {
								await navigator.clipboard.writeText(url);
								showSuccessNotification({
									message: t("The URL was copied to clipboard"),
								});
							}}
							style={{ marginLeft: "10px" }}
							icon={<IconCopy />}
							variant="secondaryGray"
						>
							{t("Copy URL")}
						</Button>
					</div>
				</div>
			</div>
		</main>
	);
};
