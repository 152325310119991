import { clsx } from "clsx";
import { type ComponentPropsWithoutRef, forwardRef } from "react";
import { Text } from "../../../common/components/atoms/typography/Text";

interface ConnectorButtonProps extends ComponentPropsWithoutRef<"button"> {
	label: string;
	description?: string;
	logo: string;
}

export const ConnectorButton = forwardRef<
	HTMLButtonElement,
	ConnectorButtonProps
>(function ConnectorButton(
	{ description, label, logo, className, ...others },
	ref,
) {
	return (
		<button
			className={clsx(
				"flex h-full w-full items-center gap-4 rounded-[8px] border border-grey-200 bg-white p-4 text-left hover:border-purple-300",
				className,
			)}
			{...others}
			ref={ref}
		>
			<div className="shrink-0">
				<img src={logo} alt={`${label} logotype`} width={40} height={40} />
			</div>
			<div className="grow">
				<Text size="md" color="text-grey-700" weight="medium">
					{label}
				</Text>
				{description !== undefined && (
					<Text size="sm" color="text-grey-500" weight="regular">
						{description}
					</Text>
				)}
			</div>
		</button>
	);
});
