import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import AuthRoute from "src/routes/AuthRoute";
import { AppError } from "../appError/AppError";
import { ChangeLog } from "./change-log/ChangeLog";

const PlanPageOutlet = () => (
	<>
		<ChangeLog />
		<Outlet />
	</>
);

export const PlanPageWrapper = () => (
	<ErrorBoundary FallbackComponent={AppError}>
		<AuthRoute Component={PlanPageOutlet} />
	</ErrorBoundary>
);
