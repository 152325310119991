import { IconLanguage } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { RadioField } from "../../common/components/atoms/checkbox/RadioField";
import { Divider } from "../../common/components/atoms/divider/Divider";
import { Popover } from "../../common/components/atoms/popoverMenu/Popover.tsx";
import { PopoverTrigger } from "../../common/components/atoms/popoverMenu/PopoverTrigger.tsx";
import { PopoverContent } from "../../common/components/atoms/popoverMenu/PopoverContent.tsx";
import { TooltipTrigger } from "../../common/components/atoms/tooltip2/TooltipTrigger.tsx";
import { TooltipContent } from "../../common/components/atoms/tooltip2/TooltipContent.tsx";
import { Tooltip2 } from "../../common/components/atoms/tooltip2/Tooltip2.tsx";

const languages = {
	en: {
		nativeName: "English",
	},
	sv: {
		nativeName: "Svenska",
	},
};

export const SwitchLanguage = () => {
	const { t, i18n } = useTranslation();

	return (
		<Tooltip2>
			<TooltipTrigger asChild>
				{(referenceProps) => {
					return (
						<Popover role="dialog">
							<PopoverTrigger asChild>
								<button
									{...referenceProps}
									type="button"
									className="text-grey-500"
									aria-label={t("Switch language")}
								>
									<IconLanguage size={20} />
								</button>
							</PopoverTrigger>
							<PopoverContent>
								<Popover.ContentContainer className="p-4">
									<div className="mb-2">
										<Divider label={t("Language")} />
									</div>
									{Object.entries(languages).map(
										([language, { nativeName }]) => {
											return (
												<div key={language}>
													<RadioField
														label={nativeName}
														name="user_language"
														labelProps={{ className: "mb-2" }}
														checked={i18n.language === language}
														onChange={(event) => {
															if (event.target.checked) {
																void i18n.changeLanguage(language);
															}
														}}
													/>
												</div>
											);
										},
									)}
								</Popover.ContentContainer>
							</PopoverContent>
						</Popover>
					);
				}}
			</TooltipTrigger>
			<TooltipContent>{t("Switch language")}</TooltipContent>
		</Tooltip2>
	);
};
