import {
	IconAbacus,
	IconABOff,
	IconAddressBook,
	IconArrowDown,
	IconArrowUp,
	IconDatabase,
	IconYoga,
} from "@tabler/icons-react";
import { useState } from "react";
import { Alert } from "../../common/components/atoms/alert/Alert";
import { Chip } from "../../common/components/atoms/chip/Chip";
import { Stepper } from "../../common/components/atoms/stepper/Stepper";
import { Tab } from "../../common/components/atoms/tabs/Tab";
import { Tabs } from "../../common/components/atoms/tabs/Tabs";
import { Text } from "../../common/components/atoms/typography/Text";
import { Input } from "../../common/components/atoms/input/Input";
import { TooltipPresentation } from "./ui-components/TooltipPresentation";
import { FeaturedIconPresentation } from "./ui-components/FeaturedIconPresentation";
import { SelectPresentation } from "./ui-components/SelectPresentation";
import { ButtonPresentation } from "./ui-components/ButtonPresentation";
import { RadioCheckboxPresentation } from "./ui-components/RadioCheckboxPresentation";
import { YearMonthPickerPresentation } from "./ui-components/YearMonthPickerPresentation";
import { FloatingMenuPresentation } from "./ui-components/PopoverMenuPresentation";
import { MenuPresentation } from "./ui-components/MenuPresentation";
import { Select } from "../../common/components/atoms/select/Select.tsx";

export const UIComponents = () => {
	const [tab, setTab] = useState(0);

	return (
		<div className="bg-white p-4">
			<Tabs selectedIndex={tab} onSelectTab={setTab} className="mb-4">
				<Tab>Featured icon</Tab>
				<Tab>Tooltip</Tab>
				<Tab>Input</Tab>
				<Tab>Text</Tab>
				<Tab>Alert</Tab>
				<Tab>Stepper</Tab>
				<Tab>Button</Tab>
				<Tab>Select</Tab>
				<Tab>Chip</Tab>
				<Tab>Select</Tab>
				<Tab>Radio/Checkbox/Switch</Tab>
				<Tab>YearMonthPickers</Tab>
				<Tab>PopoverMenu</Tab>
				<Tab>Menu</Tab>
			</Tabs>

			{tab === 0 && <FeaturedIconPresentation />}
			{tab === 1 && <TooltipPresentation />}
			{tab === 2 && (
				<div className="flex flex-col gap-4">
					<Input placeholder="Placeholder" />
					<Input iconLeft={<IconABOff />} iconRight={<IconAbacus />} />
					<Input hint="This is a hint!" />
					<Input
						hint="This is an error with no provided right icon!"
						aria-invalid={true}
					/>
					<Input
						hint="This is an error with a right icon!"
						aria-invalid={true}
						iconRight={<IconAbacus />}
					/>
					<Input disabled placeholder="I'm disabled" />
					<Input
						placeholder="Everything"
						hint="This is a hint"
						iconLeft={<IconAddressBook />}
						iconRight={<IconDatabase />}
						outsideLeft="A text to the left"
						outsideRight="A text to the right"
					/>
				</div>
			)}
			{tab === 3 && (
				<div className="flex gap-4">
					<div>
						<Text size="xs" weight="regular">
							Extra small text
						</Text>
						<Text size="sm" weight="regular">
							Small text
						</Text>
						<Text size="md" weight="regular">
							Medium text
						</Text>
						<Text size="lg" weight="regular">
							Large text
						</Text>
						<Text size="xl" weight="regular">
							Extra large text
						</Text>
					</div>
					<div>
						<Text size="xs" weight="medium">
							Extra small text
						</Text>
						<Text size="sm" weight="medium">
							Small text
						</Text>
						<Text size="md" weight="medium">
							Medium text
						</Text>
						<Text size="lg" weight="medium">
							Large text
						</Text>
						<Text size="xl" weight="medium">
							Extra large text
						</Text>
					</div>
					<div>
						<Text size="xs" weight="semibold">
							Extra small text
						</Text>
						<Text size="sm" weight="semibold">
							Small text
						</Text>
						<Text size="md" weight="semibold">
							Medium text
						</Text>
						<Text size="lg" weight="semibold">
							Large text
						</Text>
						<Text size="xl" weight="semibold">
							Extra large text
						</Text>
					</div>
					<div>
						<Text size="xs" weight="bold">
							Extra small text
						</Text>
						<Text size="sm" weight="bold">
							Small text
						</Text>
						<Text size="md" weight="bold">
							Medium text
						</Text>
						<Text size="lg" weight="bold">
							Large text
						</Text>
						<Text size="xl" weight="bold">
							Extra large text
						</Text>
					</div>
				</div>
			)}
			{tab === 4 && (
				<div className="flex flex-col gap-4">
					<Alert color="success" title="An alert" />
					<Alert color="info" title="An alert" />
					<Alert color="error" title="An alert" />
					<Alert color="warning" title="An alert" />
				</div>
			)}
			{tab === 5 && (
				<div className="flex flex-col gap-10">
					<Stepper step={0} size="sm">
						<Stepper.Step label="Step 1" />
						<Stepper.Step label="Step 2" />
						<Stepper.Step label="Step 3" />
					</Stepper>
					<Stepper step={1}>
						<Stepper.Step label="Step 1" />
						<Stepper.Step label="Step 2" />
						<Stepper.Step label="Step 3" />
					</Stepper>
					<Stepper step={2} size="lg">
						<Stepper.Step label="Step 1" />
						<Stepper.Step label="Step 2" />
						<Stepper.Step label="Step 3" />
					</Stepper>
				</div>
			)}
			{tab === 6 && <ButtonPresentation />}
			{tab === 7 && (
				<>
					<Select
						ariaLabel="Företagsdomän"
						value={null}
						placeholder="Välj företagsdomän"
						withFilter
						options={[
							{ value: "a", label: "A" },
							{ value: "b", label: "B" },
							{ value: "c", label: "C" },
						]}
						onChange={() => {}}
					/>
					<Select
						ariaLabel="Företagsdomän"
						value={null}
						placeholder="Välj företagsdomän"
						withFilter
						options={[
							{ value: "a", label: "A" },
							{ value: "b", label: "B" },
							{ value: "c", label: "C" },
						]}
						onChange={() => {}}
						disabled
					/>
				</>
			)}
			{tab === 8 && (
				<div className="flex flex-wrap gap-2">
					<div className="flex flex-col gap-2">
						<div>
							<Chip size="small" color="purple">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="medium" color="purple">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="large" color="purple">
								Label
							</Chip>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<div>
							<Chip size="small" color="blue-light">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="medium" color="blue-light">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="large" color="blue-light">
								Label
							</Chip>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<div>
							<Chip size="small" color="error">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="medium" color="error">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="large" color="error">
								Label
							</Chip>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<div>
							<Chip size="small" color="grey">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="medium" color="grey">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="large" color="grey">
								Label
							</Chip>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<div>
							<Chip size="small" color="primary">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="medium" color="primary">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="large" color="primary">
								Label
							</Chip>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<div>
							<Chip size="small" color="warning">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="medium" color="warning">
								Label
							</Chip>
						</div>
						<div>
							<Chip size="large" color="warning">
								Label
							</Chip>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<Chip size="small" color="grey" leftIcon={<IconArrowUp />}>
							Label
						</Chip>
						<Chip size="medium" color="grey" leftIcon={<IconArrowDown />}>
							Label
						</Chip>
						<Chip size="large" color="grey" leftIcon={<IconYoga />}>
							Label
						</Chip>
					</div>
					<div className="flex flex-col gap-2">
						<Chip size="small" color="grey" leftIcon={<IconArrowUp />}>
							Label
						</Chip>
						<Chip size="medium" color="grey" leftIcon={<IconArrowDown />}>
							Label
						</Chip>
						<Chip size="large" color="grey" leftIcon={<IconYoga />}>
							Label
						</Chip>
					</div>
				</div>
			)}
			{tab === 9 && <SelectPresentation />}
			{tab === 10 && <RadioCheckboxPresentation />}
			{tab === 11 && <YearMonthPickerPresentation />}
			{tab === 12 && <FloatingMenuPresentation />}
			{tab === 13 && <MenuPresentation />}
		</div>
	);
};
