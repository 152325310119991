import { Checkbox } from "../../../common/components/atoms/checkbox/Checkbox";
import { CheckboxField } from "../../../common/components/atoms/checkbox/CheckboxField";
import { Radio } from "../../../common/components/atoms/checkbox/Radio";
import { RadioField } from "../../../common/components/atoms/checkbox/RadioField";
import { Switch } from "../../../common/components/atoms/checkbox/Switch";
import { SwitchField } from "../../../common/components/atoms/checkbox/SwitchField";

export const RadioCheckboxPresentation = () => {
	return (
		<div>
			<div>
				<Radio />
			</div>
			<div>
				<Radio checked />
			</div>
			<div>
				<Radio disabled />
			</div>
			<div>
				<Radio disabled checked />
			</div>
			<div>
				<RadioField label="label" />
			</div>
			<div>
				<RadioField label="checked" checked />
			</div>
			<div>
				<RadioField label="disabled" disabled />
			</div>
			<div>
				<RadioField label="disabled checked" disabled checked />
			</div>
			<div>
				<Checkbox />
			</div>
			<div>
				<Checkbox checked />
			</div>
			<div>
				<Checkbox indeterminate />
			</div>
			<div>
				<Checkbox disabled />
			</div>
			<div>
				<Checkbox disabled checked />
			</div>
			<div>
				<Checkbox disabled indeterminate />
			</div>
			<div>
				<CheckboxField label="label" />
			</div>
			<div>
				<CheckboxField label="checked" checked />
			</div>
			<div>
				<CheckboxField label="indeterminate" indeterminate />
			</div>
			<div>
				<CheckboxField label="disabled" disabled />
			</div>
			<div>
				<CheckboxField label="disabled checked" disabled checked />
			</div>
			<div>
				<CheckboxField label="disabled indeterminate" disabled indeterminate />
			</div>
			<div>
				<Switch />
			</div>
			<div>
				<Switch checked />
			</div>
			<div>
				<Switch disabled />
			</div>
			<div>
				<Switch disabled checked />
			</div>
			<div>
				<SwitchField label="label" />
			</div>
			<div>
				<SwitchField label="checked" checked />
			</div>
			<div>
				<SwitchField label="disabled" disabled />
			</div>
			<div>
				<SwitchField label="disabled checked" disabled checked />
			</div>
		</div>
	);
};
