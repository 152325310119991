import { Button } from "components/button/Button";
import { Input } from "components/input/Input";
import { Label } from "components/label/Label";
import { CircleLoader } from "components/loadingState/CircleLoader";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Text } from "components/typography/Text";
import { useListAvailableCompaniesForBusinessCentralQuery } from "src/common/redux/api/exopenApi.js";
import { skipToken } from "@reduxjs/toolkit/dist/query/index.js";

export type Credentials = {
	tenantId: string;
	clientId: string;
	clientSecret: string;
	environment: string;
};

type Props = {
	credentials?: Credentials | undefined;
	onSubmit: (credentials: Credentials) => void;
};

export const CredentialsForm = ({ credentials, onSubmit }: Props) => {
	const { t } = useTranslation();

	const form = useForm<Credentials>({
		defaultValues: {
			environment: "production",
			...credentials,
		},
	});

	const [clientId, clientSecret, tenantId, environment] = form.watch([
		"clientId",
		"clientSecret",
		"tenantId",
		"environment",
	]);

	const {
		data: companies,
		isFetching: isFetchingCompanies,
		isError: isErrorCompanies,
	} = useListAvailableCompaniesForBusinessCentralQuery(
		clientId && clientSecret && tenantId && environment
			? { clientId, clientSecret, tenantId, environment }
			: skipToken,
	);

	return (
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<Label>{t("Enter your tenant ID")}</Label>
			<Input
				{...form.register("tenantId", {
					required: t("This field is required"),
				})}
				containerProps={{ className: "mb-4" }}
				aria-invalid={form.formState.errors.tenantId !== undefined}
				hint={form.formState.errors.tenantId?.message}
			/>

			<Label>{t("Enter your client ID")}</Label>
			<Input
				{...form.register("clientId", {
					required: t("This field is required"),
				})}
				containerProps={{ className: "mb-4" }}
				aria-invalid={form.formState.errors.clientId !== undefined}
				hint={form.formState.errors.clientId?.message}
			/>

			<Label>{t("Enter your client secret")}</Label>
			<Input
				{...form.register("clientSecret", {
					required: t("This field is required"),
				})}
				containerProps={{ className: "mb-4" }}
				aria-invalid={form.formState.errors.clientSecret !== undefined}
				hint={form.formState.errors.clientSecret?.message}
			/>

			<Label>{t("Environment")}</Label>
			<Input
				{...form.register("environment", {
					required: t("This field is required"),
				})}
				containerProps={{ className: "mb-4" }}
				aria-invalid={form.formState.errors.environment !== undefined}
				hint={form.formState.errors.environment?.message}
			/>

			{isFetchingCompanies ? (
				<div className="flex items-center gap-4">
					<CircleLoader size="sm" />
					<Text size="sm">{t("Validating credentials")}</Text>
				</div>
			) : isErrorCompanies ? (
				<Text size="sm" color="text-error-500" className="mb-4">
					{t(
						"Failed to validate credentials. Please make sure the tenant ID, client ID and client secret are correct",
					)}
				</Text>
			) : null}

			<Button
				variant="primary"
				isLoading={isFetchingCompanies || form.formState.isSubmitting}
				disabled={!companies || form.formState.isSubmitting}
				type="submit"
				className="float-right"
			>
				{t("Next")}
			</Button>
		</form>
	);
};
