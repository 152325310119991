import { IconTrash } from "@tabler/icons-react";
import { Button } from "components/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "components/dialog/ConfirmDialog";
import { useDeleteCompanyDestinationDlmMutation } from "src/common/redux/api/exopenApi";
import { showErrorNotification } from "components/notifications/events";

interface Props {
	companyDomainId: string;
	disabled: boolean;
}

export const DeleteDlmDestinationButton = ({
	companyDomainId,
	disabled,
}: Props) => {
	const { t } = useTranslation();

	const [showDeleteDestinationModal, setShowDeleteDestinationModal] =
		useState(false);

	const [deleteCompanyDestinationDlm, { isLoading: isDeletingDestination }] =
		useDeleteCompanyDestinationDlmMutation();

	const onConfirmDelete = async () => {
		const result = await deleteCompanyDestinationDlm({ companyDomainId });
		if ("error" in result) {
			showErrorNotification({
				message: t("Something failed..."),
			});
		} else {
			location.reload();
		}
	};

	return (
		<>
			<Button
				onClick={() => setShowDeleteDestinationModal(true)}
				icon={<IconTrash />}
				disabled={disabled}
				destructive
			>
				{t("Delete destination")}
			</Button>
			<ConfirmDialog
				destructive
				open={showDeleteDestinationModal}
				onClose={() => setShowDeleteDestinationModal(false)}
				onConfirm={onConfirmDelete}
				isLoading={isDeletingDestination}
				title={t("Delete destination")}
				confirmButtonLabel={t("Delete destination")}
				description={t("Are you sure you want to delete this destination?")}
			/>
		</>
	);
};
