import { IconChevronRight } from "@tabler/icons-react";
import { clsx } from "clsx";
import type { HTMLAttributes, ReactNode } from "react";
import { BreadcrumbsDescription } from "./BreadcrumbsDescription";
import { Popover } from "../popoverMenu/Popover";
import type { BreadCrumbLink } from "../../layout/BreadcrumbsLayout";
import { PersistedLink } from "../../../persistentRouterFunctions";
import { Text } from "../typography/Text";
import { Chip } from "../chip/Chip";
import { Anchor } from "../anchor/Anchor";

const makeElement = (
	{ chipProps, icon, name, path, content }: BreadCrumbLink,
	isLastCrumb: boolean,
	key?: string,
) => {
	let chip: JSX.Element | null = null;
	if (chipProps) {
		const { className: chipClassName, ...restChipProps } = chipProps;
		chip = <Chip className={clsx("ml-2", chipClassName)} {...restChipProps} />;
	}
	const children = (
		<div className="flex items-center">
			{icon && (
				<div
					className={clsx(
						name && "mr-2",
						isLastCrumb ? "text-primary-600" : "text-grey-500",
						!isLastCrumb && path && "hover:text-purple-600",
					)}
				>
					{icon}
				</div>
			)}
			{name && (
				<div className="flex flex-col">
					<Text
						size="lg"
						weight="medium"
						color={clsx(
							isLastCrumb ? "text-primary-600" : "text-grey-500",
							!isLastCrumb && path && "hover:text-purple-600",
						)}
						className="break-all"
					>
						{name}
					</Text>
				</div>
			)}

			{chip}
			{content && <div className="ml-2">{content}</div>}
		</div>
	);

	if (!isLastCrumb && path) {
		return (
			<PersistedLink to={path} key={key}>
				{children}
			</PersistedLink>
		);
	} else {
		return (
			<Text size="lg" weight="medium" component="h2" key={key}>
				{children}
			</Text>
		);
	}
};

interface BreadcrumbsProps extends HTMLAttributes<HTMLElement> {
	maxItems: number;
	description?: string | undefined;
	breadcrumbs: BreadCrumbLink[];
}

export const Breadcrumbs = ({
	maxItems,
	description,
	breadcrumbs,
}: BreadcrumbsProps) => {
	const [firstCrumb, ...restOfCrumbs] = breadcrumbs;

	const remainingCrumbs = restOfCrumbs.slice(restOfCrumbs.length - maxItems);
	const fold = restOfCrumbs.length > maxItems;
	const foldedCrumbs = restOfCrumbs.slice(0, -maxItems);

	const remainingItems = remainingCrumbs.map((crumb, index, list) => {
		return makeElement(crumb, list.length - 1 === index, `${index}`);
	});

	const breadcrumbsWithSeparator: ReactNode[] = [];
	remainingItems.forEach((item, index) => {
		breadcrumbsWithSeparator.push(item);
		if (index < remainingItems.length - 1) {
			breadcrumbsWithSeparator.push(
				<IconChevronRight
					size={16}
					className="shrink-0 text-grey-300"
					key={`index-${index}`}
				/>,
			);
		}
	});

	return (
		<nav
			className={clsx(
				"flex min-w-0 grow gap-x-2",
				!description && "items-center",
			)}
			aria-label="Breadcrumbs"
		>
			<div className="flex h-[28px] items-center gap-x-2">
				{makeElement(firstCrumb, breadcrumbs.length === 1)}
			</div>
			<div className="min-w-0">
				<div className="flex grow flex-wrap items-center gap-x-2">
					{breadcrumbs.length > 1 && (
						<IconChevronRight size={16} className="shrink-0 text-grey-300" />
					)}
					{fold && (
						<>
							<Popover role="menu" placement="bottom-start">
								<Popover.Trigger>
									<Text size="lg" weight="medium">
										...
									</Text>
								</Popover.Trigger>
								<Popover.Content>
									<Popover.ContentContainer className="flex flex-col overflow-hidden">
										{foldedCrumbs.map((crumb, index) => {
											if (crumb.path) {
												return (
													<Anchor
														component={PersistedLink}
														to={crumb.path}
														key={index}
														className="px-2 py-1"
													>
														{crumb.name}
													</Anchor>
												);
											}
											return (
												<Text className="px-2 py-1" key={index}>
													{crumb.name}
												</Text>
											);
										})}
									</Popover.ContentContainer>
								</Popover.Content>
							</Popover>
							<IconChevronRight size={16} className="shrink-0 text-grey-300" />
						</>
					)}
					{breadcrumbsWithSeparator}
				</div>
				<BreadcrumbsDescription>{description}</BreadcrumbsDescription>
			</div>
		</nav>
	);
};
