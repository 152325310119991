import { Fragment, useEffect, useMemo } from "react";
import {
	useGetCompanyMartsQuery,
	useSetActiveMartsMutation,
} from "../../common/redux/api/exopenApi";
import { useParams } from "react-router-dom";
import { invariant } from "../../common/utils/invariant";
import { SwitchField } from "../../common/components/atoms/checkbox/SwitchField";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../common/components/atoms/button/Button";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { useTranslation } from "react-i18next";
import type { CompanyMart } from "../../common/redux/api/exopenApiTypes";
import {
	PlanningTable2,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";

interface Inputs {
	martIds: number[];
}

const isToggableMart = (mart: CompanyMart) => mart.version === 1;

export const MartManagement = () => {
	const { t } = useTranslation();

	const { companyId } = useParams();
	invariant(companyId);

	const { data: companyMarts, isLoading: isLoadingCompanyMarts } =
		useGetCompanyMartsQuery(companyId);

	const martsByVersion: Record<number, CompanyMart[]> = {};
	for (const mart of (companyMarts ?? []).filter(isToggableMart)) {
		if (!Array.isArray(martsByVersion[mart.version])) {
			martsByVersion[mart.version] = [];
		}
		martsByVersion[mart.version].push(mart);
	}

	for (const list of Object.values(martsByVersion)) {
		list.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
	}

	const companyMartIds = useMemo(
		() =>
			new Set(
				(companyMarts ?? [])
					.filter(({ enabled }) => enabled)
					.map((mart) => mart.martId),
			),
		[companyMarts],
	);

	const [setActiveMarts] = useSetActiveMartsMutation();

	const {
		reset,
		handleSubmit,
		control,
		formState: { isSubmitting, isDirty },
	} = useForm<Inputs>({
		defaultValues: { martIds: Array.from(companyMartIds) },
	});

	useEffect(() => {
		if (companyMarts) {
			reset({ martIds: Array.from(companyMartIds) });
		}
	}, [companyMartIds, companyMarts, reset]);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		const result = await setActiveMarts({ companyId, martIds: data.martIds });
		if ("error" in result) {
			showErrorNotification({ message: t("The changes couldn't be saved") });
		} else {
			showSuccessNotification({ message: t("The changes are saved") });
		}
	};

	if (isLoadingCompanyMarts) {
		return <LoadingState />;
	}

	if (!companyMarts) {
		return <MissingDataState />;
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<PlanningTableContainer className="mb-4">
				<PlanningTable2>
					<thead>
						<tr>
							<PlanningTable2.Th>Version</PlanningTable2.Th>
							<PlanningTable2.Th>{t("Name")}</PlanningTable2.Th>
						</tr>
					</thead>
					<tbody>
						<Controller
							control={control}
							name="martIds"
							render={({ field: { onChange, value, ...field } }) => {
								return (
									<>
										{Object.entries(martsByVersion).map(([version, marts]) => {
											const toReturn = (
												<Fragment key={version}>
													{marts.map((martConfig, index) => (
														<tr key={martConfig.martId}>
															{index === 0 && (
																<PlanningTable2.Td
																	noDefaultPadding
																	className="w-0 border-r text-center"
																	rowSpan={marts.length}
																>
																	{version}
																</PlanningTable2.Td>
															)}
															<PlanningTable2.Td
																noDefaultPadding
																className="p-1"
															>
																<SwitchField
																	label={martConfig.name}
																	checked={value.includes(martConfig.martId)}
																	{...field}
																	onChange={(event) => {
																		if (event.target.checked) {
																			onChange([...value, martConfig.martId]);
																		} else {
																			onChange(
																				value.filter((martId) => {
																					return martId !== martConfig.martId;
																				}),
																			);
																		}
																	}}
																/>
															</PlanningTable2.Td>
														</tr>
													))}
												</Fragment>
											);

											return toReturn;
										})}
									</>
								);
							}}
						/>
					</tbody>
				</PlanningTable2>
			</PlanningTableContainer>
			<div className="text-right">
				<Button
					variant="primary"
					type="submit"
					disabled={!isDirty || isSubmitting}
					isLoading={isSubmitting}
				>
					{t("Save")}
				</Button>
			</div>
		</form>
	);
};
