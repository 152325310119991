export const TAGS = [
	"Integrations",
	"SyncStatus",
	"MergeAccountingTokens",
	"MergeAccountingMagicLink",
	"SieFile",
	"VitecCredential",
	"VitecIntegrationConfig",
] as const;

export type FusionhubIntegration = {
	id: number;
	type: string;
	loadStatus: "INITIAL" | "LOADING" | "FAILED" | "READY";
	companyDomainId: string | null;
	createdAt: string;
	updatedAt: string;
};

export type ErLegalEntity = {
	id: string;
	name: string;
	currencyBase: string;
};

export type ErSyncStatus =
	| {
			status: "FINISHED";
			finishedAt: Date;
	  }
	| {
			status: "FAILED";
			failedAt: Date;
	  }
	| {
			status: "IN_PROGRESS";
			startedAt: Date;
	  };

export type ErNote = {
	sv: string;
	en: string;
};

export type ErDocumentation = Record<
	string,
	{
		title: ErNote;
		notes: ErNote[];
		sources: Record<string, { notes: ErNote[] }>;
	}
>;

export type PaginatedResponse<T> = {
	items: T[];
	page: {
		cursor: string | null;
	};
};

export type PaginatedParams = {
	take?: number;
	cursor?: string;
};

export type MergeSystem = {
	name: string;
	slug: string;
	logo: string;
};
