import { Virtuoso } from "react-virtuoso";
import { Children, memo, type ReactNode } from "react";

export const VirtualChildren = memo(function VirtualChildren({
	children,
	nodeHeight,
}: {
	children: ReactNode;
	nodeHeight: number;
}) {
	const nodeArray = Children.toArray(children);
	return (
		<Virtuoso
			className="overflow-y-auto"
			data={nodeArray}
			style={{ height: `${nodeHeight * nodeArray.length}px` }}
			itemContent={(_, item) => item}
			defaultItemHeight={nodeHeight}
		/>
	);
});
