import { isValidElement } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { LoadingState } from "../common/components/atoms/loadingState/LoadingState";
import { useUser } from "../common/context/userContextUtils";
import { invariant } from "../common/utils/invariant";
import type { Permission } from "../features/usersAndGroups/AddAccessGroupModal";

interface AuthRouteProps {
	Component?: any;
	requiredRoles?: string[];
	requiredPermissions?: Permission[];
}

const AuthRoute = ({
	Component = Outlet,
	requiredRoles = [],
	requiredPermissions,
}: AuthRouteProps): JSX.Element => {
	const {
		user,
		userHasRequiredRole,
		isFetchingUserInfo,
		userHasRequiredPermissions,
	} = useUser();
	const { companyId } = useParams();
	invariant(companyId);
	let hasAccess = true;
	if (
		requiredRoles.length > 0 &&
		!userHasRequiredRole(companyId, requiredRoles)
	) {
		if (requiredPermissions !== undefined && requiredPermissions.length > 0) {
			if (!userHasRequiredPermissions(requiredPermissions)) {
				hasAccess = false;
			}
		} else {
			hasAccess = false;
		}
	}

	if (hasAccess) {
		if (isValidElement(Component)) {
			return Component;
		}
		return <Component />;
	}
	if (user && !isFetchingUserInfo) {
		return <Navigate to="/" />;
	}
	return <LoadingState />;
};

export default AuthRoute;
