import {
	fetchBaseQuery,
	type FetchBaseQueryMeta,
	type BaseQueryFn,
	type FetchArgs,
	type FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { getAccessTokenSilently, logout } from "../../../auth0";

const makeBaseQuery = (
	fetchBaseQueryOptions: Parameters<typeof fetchBaseQuery>[0],
) =>
	fetchBaseQuery({
		...fetchBaseQueryOptions,
		prepareHeaders: async (...args) => {
			const [headers] = args;
			if (headers.has("skip")) {
				headers.delete("skip");
				return headers;
			}

			fetchBaseQueryOptions?.prepareHeaders?.(...args);

			try {
				const token = await getAccessTokenSilently();
				if (token) {
					headers.set("authorization", `Bearer ${token}`);
				}
			} catch (e) {
				logout({ logoutParams: { returnTo: window.location.origin } });
			}

			return headers;
		},
	});

export const makeBaseQueryWithUnauthentication = (
	fetchBaseQueryOptions: Parameters<typeof fetchBaseQuery>[0],
): BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError,
	{},
	FetchBaseQueryMeta
> => {
	const baseQuery = makeBaseQuery(fetchBaseQueryOptions);

	return async (args, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions);
		if (result.error && result.error.status === 401) {
			logout();
		}
		return result;
	};
};
